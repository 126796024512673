.careTeamTable {
  & [id*='menuItem'] {
    & > div {
      width: calc(20% - 2rem);
      padding-right: 2rem;
    }
    & > div:first-child {
      width: calc(40% - 2rem);
    }
    & > i {
      margin-left: 17%;
    }
  }
}
.conditionsTable {
  & [id*='menuItem'] {
    & > div {
      width: calc(20% - 2rem);
      padding-right: 2rem;
    }
    & > div:first-child {
      width: calc(60% - 2rem);
    }
  }
}
.activityLogTable {
  & [id*='menuItem'] {
    & > div {
      flex: 1;
    }
  }
  & [id*='dataItem'] {
    &:first-child {
      font-weight: unset;
    }
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
.activityLogTable {
  margin-top: 20px;
}

}
.labsTable {
  & [id*='menuItem'] {
    & > div {
      width: calc(20% - 2rem);
      padding-right: 2rem;
    }
    & > div:nth-child(3) {
      padding-right: 0;
    }
    & > div:first-child {
      width: calc(60% - 2rem);
    }
    & > div:last-child {
      width: 50px;
      margin-left: -50px;
      padding-right: 0;
      overflow: visible;
      & > div {
        position: relative;
        left: 40px;
      }
    }
  }
}
.medicationsTable {
  & [id*='menuItem'] {
    & > div:last-of-type {
      width: 11%;
    }
    & > i {
      margin-left: 6%;
    }
  }
}
.altSelector {
  display: flex;
  justify-content: flex-end;
  position: relative;
  padding-right: 1rem;
  height: 24px;
  color: #b0bec5;
  cursor: pointer;
  top: -15px;
  & i {
    outline: none;
  }
}
.module {
  & i.module {
    color: #5bc5d0;
  }
}
.list {
  & i.list {
    color: #5bc5d0;
  }
}
.table {
  border: 1px solid #cfd8dc;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  padding-top: 2px;
  padding-bottom: 6px;
  & > div:last-child > div {
    border-bottom: 0;
  }
}
.chevron {
  outline: none;
  transition: all 0.2s;
  margin-left: -24px;
  height: 24px;
  color: #b0bec5;
  cursor: pointer;
}
.rotateChevron {
  transform: rotate(180deg);
}
.expansionPanel {
  transition: max-height 0.1s linear;
  overflow: hidden;
  max-height: 0;
  height: 100%;
  margin-right: 1rem;
  margin-left: 1rem;
}
.open {
  max-height: 300px;
  padding-bottom: 0.5rem;
}
.selected {
  background-color: #fafafb;
  border-bottom: 1px solid #cfd8dc !important;
  padding-right: 2rem !important;
  padding-left: 2rem !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
  border-top: 1px solid #cfd8dc;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.pageSelectionWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}
.pageNumberText {
  width: 60px !important;
}
.pageNumber {
  height: 28px;
  width: 30px;
  border: 1px solid #cfd8dc;
  border-radius: 6px;
  margin: 6px;
  color: #cfd8dc;
  font-size: 13px;
  font-weight: 500;
  align-items: center;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}
.pageSelectionWrapperWithLast {
  @extend .pageSelectionWrapper;
}
.currentPage {
  color: #5bc5d0;
  border: 1px solid #5bc5d0;
}
.ellipis {
  @extend .pageNumber;
  border: none !important;
  margin-right: 0;
  margin-left: 0;
  white-space: pre;
  font-size: 25px;
  color: #cfd8dc;
}
.menuItemWrapper {
  display: flex;
  flex-direction: column;
  outline: none;
}
.filterSvg {
  margin-left: 1rem;
  margin-top: 6px;
  cursor: pointer;
}
.head {
  float: left;
  margin-right: 0.4rem;
}
.headerMenuItem {
  border-top: 0 !important;
  padding-top: 15px !important;
  padding-bottom: 20px !important;
}
.menuItem {
  display: flex;
  padding-right: 1rem;
  padding-left: 1rem;
  outline: none;
  padding-top: 9px;
  padding-bottom: 9px;
  border-top: 1px solid #cfd8dc;
  width: calc(100% - 4rem);
  margin-left: 1rem;
  margin-right: 1rem;
  & svg {
    margin-right: 0;
  }
}
.heading {
  outline: none;
  height: 17px;
  color:#5BC5D0;
  font-size: 14px;
  font-weight: 600;
  width: 154px;
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .menuItem {
  padding-left: .5rem;
  }
  .heading {
    outline: none;
    height: 22px;
    color:#5BC5D0;
    font-size: 10px;
    font-weight: 600;
    margin-left: .8rem;
    margin-right: 0rem;
    margin-bottom: .8rem;
  }
  .head {
    float: left;
    margin-right: 0;
  }
}
.data {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 12px;
}
.dataItem {
  &:first-child {
    color: #455a64;
    font-weight: bold;
  }
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none;
  color: #455a64;
  font-size: 12px;
  width: 154px;
}
.actionsWrapper {
  border: 1px solid #cfd8dc !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  margin-top: 0;
  height: 20px;
  width: 42px;
}
.actionsWrapperSelected {
  border: 1px solid #5bc5d0 !important;
  color: #5bc5d0;
}
.actionsButton {
  justify-content: center;
  display: flex;
  width: 42px;
}
.actionItem {
  justify-content: center;
  display: flex;
}
.actionSchemaWrapper {
  position: absolute;
  width: 200px;
  margin-top: 5px;
  background-color: white;
  display: flex;
  flex-direction: column;
  z-index: 999;
  border: 1px solid #cfd8dc;
  border-radius: 6px;
  margin-left: -140px;
  padding: 1rem;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  padding-left: 20px;
  & div h6 {
    height: 13px;
    color: #37474f;
    font-family: 'Metropolis';
    font-size: 13px;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 15px;
  }
  & a {
    text-decoration: none;
    margin-bottom: 14px;
    font-weight: 400;
    height: 14px;
    font-size: 14px;
    color: #b0bec5;
    font-family: 'Metropolis', sans-serif;
    &:hover {
      color: #5bc5d0;
    }
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
.actionSchemaWrapper{
  padding: 0.5rem;
}
}
.loadMoreWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.horizontalDivider {
  height: 1;
  width: 350px;
  background-color: #cfd8dc;
}
.loadMore {
  color: #5bc5d0;
  width: 140px;
  cursor: pointer;
  border-radius: 6px;
  border: 1px solid rgba(31, 155, 222, 0.4);
  margin: 30px auto;
  display: block;
  padding: 8px;
  text-transform: uppercase;
  box-shadow: 1px 1px 4px rgba(31, 155, 222, 0.4);
  text-align: center;
  border-color: #5bc5d0;
  outline: none;
}
.altViewWrapper {
  display: flex;
  flex-wrap: wrap;
}
.one {
  & > div {
    width: 100%;
  }
}
.two {
  & > div {
    width: 40%;
    &:first-child {
      width: 60%;
    }
  }
}
.three {
  & > div {
    width: 20%;
    &:first-child {
      width: 60%;
    }
  }
}
.four {
  & > div {
    width: 20%;
    &:first-child {
      width: 40%;
    }
  }
}
.twoWithActions {
  & > div {
    width: 10%;
    &:first-child {
      width: 90%;
    }
  }
}
.threeWithActions {
  & > div {
    width: 30%;
    &:first-child {
      width: 55%;
    }
    &:nth-child(3) {
      width: 15%;
    }
  }
}
.fourWithActions {
  & > div {
    width: 25%;
    &:first-child {
      width: 35%;
    }
    &:nth-child(4) {
      width: 15%;
    }
  }
}
