.addAppointmentEmpty {
  color: #cfd8dc;
  margin: 0px, 5px;
  font-size: 20px;
}
.availableHoursDatePickerIcon {
  color: #5bc5d0;
  margin-left: 5px;
  font-size: 25px;
}
.profileSettingsCamera {
  position: absolute;
  top: 75px;
  margin-right: -40%;
  padding: 4%;
  border-radius: 50%;
  background-color: #5bc5d0;
  color: #ffffff;
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
  &:hover {
    background-color: #3697a0;
  }
}
.deleteMessageIcon {
  font-size: 60px;
  color: #fff;
}
.guestPayPrintIcon {
  font-size: 36px;
  margin: 0 auto;
  padding-bottom: 25px;
  color: #37474f;
  cursor: pointer;
}
.prefix {
  height: 17px;
  width: 39px !important;
  color: #5bc5d0;
  font-family: 'Metropolis';
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-transform: capitalize;
  align-self: center;
  margin-right: 10px;
}
.creditCardDetails {
  color: #5bc5d0;
  cursor: pointer;
}
.apolloErrorIcon {
  font-size: 72px;
  color: #5bc5d0;
  display: flex;
  margin: 0 auto;
}
