@import '../variables';

.primary {
  color: $main-color;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  margin: 0;
}

.placeholder {
  color: $gray-4;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
}

.subGroupTitle {
  color: $gray-4;
  font-size: 14px;
  margin-top: 39px;
  font-weight: 500;
  line-height: 17px;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.groupTitle {
  color: $gray-9;
  font-size: 26px;
  font-weight: 600;
  line-height: 32px;
  margin: 0 0 20px;
  & > i {
    color: $main-color;
    font-weight: 700;
    vertical-align: top;
  }
}

.groupDescription {
  color: $gray-5;
  font-size: 19px;
  line-height: 23px;
  margin: 0;
  width: 100%;
}

$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .groupTitle {
    color: $gray-9;
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
    margin: 0 0 20px;
    & > i {
      color: $main-color;
      font-weight: 700;
      vertical-align: top;
    }
  }
  
  .groupDescription {
    color: $gray-5;
    font-size: 15px;
    line-height: 21px;
    margin: 0;
    width: 100%;
  }
}
.sectionTitle {
  color: $gray-3;
  font-family: $Metropolis;
  font-size: 42px;
  font-weight: 300;
  letter-spacing: -2px;
  line-height: 50px;
  margin: 0 0 16px;
  & > i {
    color: $main-color;
    font-weight: 700;
    vertical-align: top;
  }
}

.error {
  color: $destructive;
  font-size: 14px;
  line-height: 16px;
  margin: 0;
  padding: 4px 0;
}