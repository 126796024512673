@import '../../styleguide/variables';

.wrapper {
  display: flex;
  margin: -40px 0 -120px 0;
}
.leftSide {
  display: flex;
  height: 100% !important;
  flex: 1;
  & div[class*='providerInfoWrapper'] {
    height: 100%;
  }
  & button[class*='nextStepBtn'] {
    margin: 0 0 0 4.6rem;
  }
  & h5 {
    color: $main-color !important;
  }
  margin-top: 40px;
}

.footer {
  display: none;
}

$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .wrapper {
    display: flex;
    margin: 50px 0 -120px 6px;
    flex-wrap: wrap;
   }
   .leftSide {
    display: none;
    & button[class*='nextStepBtn'] {
      margin-top: 1rem !important;
      margin-left: 72%;
    }
  }
}