.disableClicks {
  pointer-events: none;
}
.practiceHeader {
  margin-bottom: 40px;
}
.guestPracticeHeaderContainer {
  text-align: center;
  margin-bottom: 10px;
}
.guestPracticeHeader {
  line-height: 45px !important;
  padding: 0px 15px;
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
.guestPracticeHeader {
  font-size: 16px;
}
}
.guestMobileNextWrapper {
  min-height: calc(100vh - 160px);
  text-align: center;
  & div[id*='thankYouFeedbackContainer'] {
    width: 100%;
    margin: 0 auto;
    padding: 0px;
  }
  & div[id*='surveysSatisfiedSocialNetworks'] {
    display: inline-block;
    text-align: center;
    margin: 10px;
    padding: 0px;
  }
  & div[id*='surveysSatisfiedSocialNetworksIcons'] {
    margin: 10px 8px;
    padding: 0px;
    display: inline-block;
  }
  & div[id*='surveyWhyNotSatisfiedContainer'] {
    & > div:nth-child(2) {
      width: 90%;
      margin: 30px 0px;
      border-radius: 10px;
    }
  }
}

//
.VertexScreen{
  display: flex;
  padding: 120px 0 20px 0px;
  width: 1260px;
  min-height: calc(100vh - 283px);
  flex-direction: row;
  justify-content: flex-start;
  margin: 0 auto;
  height: calc(100vh - 206px);
  gap: 143px;
  textarea[class*='textarea']{
    border: 1px solid #dddddd;
    border-radius: 10px;
    padding: 1rem;
  }
}
div[id*='timeCard_Group'] {
  display: flex;
  flex-direction: row;
  gap: 42px;
  margin: 1rem auto;
}

 [class*='disabled'] {
  cursor: not-allowed;
  pointer-events: none !important;
}
input[id='Week_CheckBoxs'], input[id='times_of_Day'], input[id='days_of_week']{
  &[disabled]{
    cursor: not-allowed;
    opacity: 0.6 !important
  }
}
div[class*='checkBoxContainer'] {
  display: flex;
  flex-direction: row;
  text-transform: capitalize;
  justify-content: start;
  border-bottom: none;
  font-size: 15px;
  color: #597583;
  font-weight: 500;
  font-family: 'Metropolis';
  align-items: center;
  padding: 0px;
  position: relative;
  height: 32px;
  width: 100%;

  & input[type=checkbox]:after {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    margin-top: -2px;
    margin-right: 5px;
    border: 2px solid #c9d2d7;
    border-radius: 2px;
    transition: 240ms;
  }

}

h6 {
  font-family: "Metropolis", sans-serif;
  color: #78909c;
  font-size: 15px;
  text-transform: initial;
  /* font-weight: 500; */
  margin: 0;
}
div[id*='Week_Border']{
  margin-top: -12px;
}
div[id*='availableHoursAvailableHours']{
  margin-top: 20px;
  margin-bottom: 29px;

}
button[id*='RequestAppointmentButton']{
    border: 1px solid #22cc4d;
    font-size: 14px;
    font-family: 'Metropolis';
    font-weight: 800;
    text-align: center;
    color: black;
    background-color: #22cc4d;
    text-transform: capitalize;
    padding: 0 15px;
    font-size: 17px;
    font-weight: 400;
    color: #4b5a64;
    width: 30%;
    margin-left: 33%;
    &[disabled]{
      background-color: #c9d2d7;
      border: 1px solid #c9d2d7;
      cursor: not-allowed;
    }
}
div[id*='first_Availble_Time']{
  width: 32%;
}
label[for='first_Availble_Time']{
  &>div{
    margin-bottom: 1rem;
  }
}
label[for='first_Availble_Time']::after{
  content: "_________________________OR____________________________";
  font-family: "Metropolis", sans-serif;
  font-size: 21px;
  font-weight: 500;
  color: #607d8b;
  margin: 0 auto;
  line-height: 23px;
  margin: 1rem 0;
}
input[id*='first_Availble_Time']{
  &[disabled] {
    cursor: not-allowed ;
    opacity: 0.6; /* You can also add opacity to make it visually disabled */
  }
}
button[id*='btnApprovedVertexAppointment']{
  color: #22cc4d;
  font-size: 14px;
    font-family: 'Metropolis';
    font-weight: 1000;
}
[class*='timeCard_Group_DIsabled'], [class*='week_DIsabled'] {
  cursor: not-allowed;
  pointer-events: none !important;
  opacity: 0.2 !important
  ;
}

$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .VertexScreen{
    padding: 75px 1rem 0;
    width: 90%;
    flex-direction: column;
    textarea[class*='textarea']{
      width: 95%;
      border: 1px solid #dddddd;
      border-radius: 10px;
      padding: .5rem;
    }
  }
  label[for='first_Availble_Time']::after{
    content: "_____________OR_____________";
  }
  label[for='first_Availble_Time']{
    margin: 0 auto;
  }
  div[id*='timeCard_Group'] {
    flex-direction: column;
  }
  button[id*='RequestAppointmentButton']{
    width: 70%;
    margin: 0 auto;
    font-size: 16px;
  }
  div[id*='availableHoursAvailableHours']{
    h4{
      text-align: center;
    }
  }
}