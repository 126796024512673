.guestLayoutWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  animation: fadein 0.5s forwards;
}
.guestMobileWrapper {
  min-height: 0px;
  & div[id*='makePaymentsPrimaryHeader'] {
    display: none;
  }
}
