.expander {
  display: flex;
  flex-direction: row;
  flex: 1;
  box-shadow: inset 0 -1px 0 0 #cfd8dc;
  padding-bottom: 15px;
}
.wrapper {
  width: 100%;
  &:not(:first-of-type) {
    padding-top: 15px;
  }
}
.creditCardDetails {
  cursor: pointer;
}
.manageProfilePracticeExpanderChevron {
  cursor: pointer;
}
.isNotExpanded {
  & div[id*='creditCardDetails']:last-of-type {
    display: none;
  }
  & div[id*='_manageProfilePracticeExpanderClose'] {
    display: none;
  }
  & div[class*='radioButtons'] {
    & > button:last-child {
      display: none;
    }
  }
}
.isExpanded {
  & div[id*='creditCardDetails']:nth-of-type(2) {
    display: none;
  }
  & div[id*='_manageProfilePracticeExpanderOpen'] {
    display: none;
  }
  & div[class*='radioButtons'] {
    & > button:first-child {
      display: none;
    }
  }
}
.notExpanded {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 0.4s linear;
  background-color: #f9fafa;
  & input,
  .Select-control,
  .Select-placeholder {
    background-color: #f9fafa !important;
  }
}
.expanded {
  max-height: 2000px;
  opacity: 100;
  overflow: unset;
  animation: fadein 0.4s;
  transition: all 0.4s;
}
[id*='expanderContentGroup'] h6 {
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  font-family: 'Metropolis';
  color: #37474f;
  align-self: center;
  margin: 0;
}
[id*='expanderContentGroup'] h5 {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  font-family: 'Metropolis';
  color: #37474f;
  margin: 0 0 0 7px;
}
#profileSettingsRightContent #demographics [id*='expander'] > div {
  padding-bottom: 2px;
}
#appointmentCheckInScreen #detailsInfoCheckInSection [id*='expander'] > div {
  padding-bottom: 0;
}
#appointmentCheckInScreen #infoCheckPage #referringPhysicianCardWrapper {
  width: unset;
}
#appointmentCheckInScreen #infoCheckPage #referringPhysicianCardWrapper > div {
  width: 410px;
}
#profileSettingsRightContent #demographics #referringPhysicianCardWrapper {
  width: unset;
}
#profileSettingsRightContent
  #demographics
  #referringPhysicianCardWrapper
  > div {
  width: 374px;
}
#profileSettingsRightContent #demographics [id*='expanderContentGroup'] {
  padding: 22px 22px 0 22px;
}
#appointmentCheckInScreen [id*='expanderContentGroup'] {
  padding: 22px 22px 0 22px;
}
#profileSettingsRightContent
  #demographics
  [id*='expanderContentGroup']
  [id*='expanderGroupComponent'] {
  justify-content: space-between;
}
#profileSettingsRightContent
  #demographics
  [id*='profileSettingsAdditionalInfo']
  [class*='leftSideWrapper'] {
  padding-right: 4rem;
}
#profileSettingsRightContent
  #demographics
  [id*='expanderContentGroup']
  [id*='expanderGroupComponent']
  [class*='select-'] {
  width: 120px;
}
#profileSettingsRightContent
  #demographics
  [id*='expanderContentGroup']
  [id*='expanderGroupComponent']
  [id*='emergencyContactStateinputDivWrapper'][class*='emergencyContactState'] {
  width: 87px;
  margin-top: 6px;
  margin-bottom: 9px;
}
#profileSettingsRightContent
  #demographics
  [id*='expanderContentGroup']
  [id*='expanderGroupComponent']
  [id*='emergencyContactStateinputDivWrapper'][class*='emergencyContactState']
  div:nth-child(2)
  > div {
  width: 87px;
}
#manageProfilePracticeExpanderFooterSection
  [id*='manageProfilePracticeExpendedMergeExpander']
  [class*='expanded'] {
  position: absolute;
  margin-top: 15px;
}
#manageProfilePracticeExpanderFooterSection
  [id*='manageProfilePracticeExpendedMergeExpander'] {
  grid-row: 1;
}
#manageProfilePracticeExpanderFooterSection
  [id*='manageProfilePracticeExpandedMergePopupExpanded'] {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  width: 310px;
  border: 1px solid #b0bec5;
  border-radius: 6px;
}
#manageProfilePracticeExpanderFooterSection
  button[id='manageProfilePracticeExpandedMergePopupApplyButton'] {
  width: 280px;
  margin-top: 0;
}
#manageProfilePracticeExpanderFooterSection select {
  width: 100%;
  background-color: #fff !important;
  height: 34px;
  border: 0;
  overflow: hidden;
  cursor: pointer;
  border-bottom: 1px solid #dddddd !important;
  border-radius: 0;
  color: #b0bec5;
  font-size: 16px;
  font-family: 'Metropolis';
}
#manageProfilePracticeExpanderFooterSection label {
  color: #455a64;
  font-size: 11px;
  font-family: 'Metropolis';
  font-weight: 700;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
#manageProfilePracticeExpanderFooterSection option {
  font-size: 16px;
  font-family: 'Metropolis';
  background: #fff;
  font-weight: 400;
  color: #455a64;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#manageProfilePracticeExpanderFooterSection span[class='required'] {
  font-size: 14px;
  margin-left: 5px;
  color: rgb(255, 51, 51);
  position: absolute;
  line-height: 20px;
}
