@import '../styleguide/variables';

.wrapper {
  [class*='Select-arrow-zone'] {
    display: none;
  }

  [class*='Select-input'] {
    display: block;
    height: initial;
    margin: 0;
    padding: 0;

    input {
      &::placeholder { color: #90A4AE };
      &[disabled] { color: $main-color };
      background-color: transparent;
      border-bottom: 1px solid #CFD8DC;
      border: none;
      box-shadow: none;
      box-sizing: border-box;
      color: #5BC5D0;
      display: block;
      font-size: 16px;
      font-weight: 300;
      height: initial !important;
      line-height: 20px;
      margin: 0;
      padding: 8px 0;
      width: 100%;
      max-width: 400px;
    }
  }

  [class*='Select-value'] {
    line-height: 20px !important;
    padding: 8px 0 !important;
    min-height: 35px;
  }

  [class*='Select-value-label'] {
    color: #5BC5D0 !important;
    font-size: 16px;
    line-height: 20px;
    padding: 0;
    vertical-align: middle;
  }

  [class*='Select'][class*='is-disabled'] [class*='Select-value-label'] {
    color: $main-color !important;
  }

  [class*='Select-placeholder'] {
    color: #90A4AE;
    font-family: Metropolis;
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
    padding-left: 0px;
    padding: 8px 0;
    vertical-align: middle;
  }

  [class*='Select-control'] {
    background-color: transparent !important;
    border-bottom: 1px solid #CFD8DC !important;
    border-radius: 0;
    border: none;
    box-shadow: none !important;
    cursor: pointer;
    outline: none !important;
    overflow: hidden;
    padding: 0;
  }
  
  [class*='Select-multi-value-wrapper'] {
    line-height: 20px;
    position: relative;
  }

  [class*='Select-menu-outer'] {
    box-shadow: 0 1px 4px $box_shadow_gray;
    background-color: $white;
    border-radius: 6px;
    border: 0;
    margin-top: 7px;
    z-index: 2;
    --webkit-overflow-scrolling: touch;
  }

  [class*='Select-option'] {
    font-size: 16px;
    line-height: 17px;
    background: none;
    font-weight: 400;
    color: $gray-8;
    box-sizing: content-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    & [class*='is-selected'],
    & [class*='is-focused'] {
      background-color: $gray-0;
      &:last-child {
        border-radius: 0 0 6px 6px;
      }
      &:first-child {
        border-radius: 6px 6px 0 0;
      }
    }
    &:hover:not([class*='is-disabled']) {
      background-color: $gray-1;
      outline: none;
    }
    & [class*='is-disabled'] {
      opacity: 0.5;
    }
    & [class*='Select-highlighted'] {
      background-color: transparent;
      font-weight: bold;
    }
  }

  &.bordered {
    [class*='Select-control'] {
      border: 1px solid #CFD8DC !important;
      border-radius: 6px;
      padding: 8px;
    }
  } 
}

