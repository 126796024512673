.guestGetStartedWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  animation: fadein 0.5s forwards;
}
.guestContentPage {
  margin: 0px auto;
  padding-top: 0px;
  width: 100%;
}
