.headingWrapper {
  display: flex;
  align-items: center;
  & i {
    font-weight: bold;
    height: 9px;
    width: 10px;
    color: #5bc5d0;
  }
}

.medsAllergyPage {
  & div[class*="divider"] {
    margin-bottom: 0px !important;
  }
  & div[class*="formWrapper"] {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}

.notifications {
  grid-row: 3; 
}

.personal {
  grid-row: 3; 
}

.notifications {
  grid-row: 3; 
}

.demographics {
  grid-row: 3; 
}

.documents {
  grid-row: 3; 
}

.components {
  margin-top: 3rem;
}

.payment {
  grid-row: 3; 
}
.authentication {
  grid-row: 3;
  & div[id='passwordContainer']{
    &>div{
      width: 90%;
    }
    & button[id='eyeIcon'], button[id='eyeIcon2']{
      border-bottom: 1px solid #c8d2d7;
      border-radius: 0;
      width: 10%;
      margin-top: 14.2px;
      &>img{
        filter: grayscale(100%);
        padding-top: 0;
        width: 18px;
      }
    }
  }
}

.photo {
  grid-row: 3; 
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
.payment {
  margin-left: 3%;
  margin-bottom: 235px;
}
.authentication {
  margin-left: 3%;
  margin-bottom: 130px;
}
}