.iconButton {
  cursor: pointer;
  display: inline-flex;
  & div {
    margin-right: 5px;
  }
}
.wrapper {
  display: flex;
  flex-direction: column;
}
.calendarWrapper {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  opacity: 0;
  animation: fadeIn 2.5s linear forwards;
  position: absolute;
  right: 50%;
  top: 18%;
  border-radius: 6px;
  z-index: 1;
}

$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .calendarWrapper {
    right: 8vw;
    top: 40vh;
  }
}

div[class*="datePickerHidden"] {
  visibility: hidden;
}

.buttonsWrapper {
  text-align: center;
  padding: 20px 0px;
  border-top: 1px solid #cfd8dc;
  & button {
    margin: 0px 10px;
    width: 130px;
    height: 40px;
    font-size: 16px;
    font-weight: 400;
    font-family: "Metropolis";
    border-radius: 6px;
    cursor: pointer;
  }
  & .nextThreeBtn {
    color: #5bc5d0;
    border: 2px solid #5bc5d0;
    background: #fff;
  }
  & .applyBtn {
    color: #fff;
    border: 2px solid #5bd064;
    background: #5bd064;
  }
}
div[class*="focusRegion"] {
  border-bottom: 1px solid #cfd8dc;
}
div[class*="CalendarMonthGrid"] {
  width: 100% !important; // borderless
  left: 0px !important; // borderless
}
div[class*="CalendarMonth"] {
  padding: 0px !important; // borderless
}
div[class*="CalendarMonthGrid__horizontal"] {
  left: 0px !important; // borderless
}
div[class*="CalendarMonth_caption"] {
  position: relative; // borderless
  top: 12px; // borderless
  font-size: 22px;
  padding-top: 10px;
  padding-bottom: 46px;
}
div[class*="DayPicker_transitionContainer"] {
  min-height: 340px;
}
div[class*="DayPicker_weekHeader"] {
  width: 327px !important; // borderless
  margin: 0 auto; // borderless
  background: #2b6369;
  color: #fff;
  text-transform: uppercase;
  font-weight: 300;
  text-align: center;
  padding: 0px !important; // borderless
  & ul {
    padding: 3px 0px;
  }
  & li {
    width: 46px !important;
    & :first-child,
    & :last-child {
      color: #cfd8dc;
    }
  }
}
table {
  position: relative;
  z-index: 1;
  width: 100%; // borderless
  top: 60px; //borderless
}
td[class*="CalendarDay"] {
  border: none !important;
  height: 45px !important; //borderless
}
td[class*="CalendarDay__outside"] {
  color: #cfd8dc;
}

td[class*="CalendarDay__blocked_out_of_range"] {
  cursor: not-allowed;
}
td[class*="CalendarDay__selected_span"] {
  background: #e1f4f6;
  color: #000;
}
%pseudoElementifiedBordersBase {
  content: "";
  display: block;
  position: absolute;
  width: 55%;
  z-index: -1;
  height: 46px;
  background: #e1f4f6;
  bottom: 0;
}

%calendarDaySelectedStartOrEndBase {
  color: #fff;
  background: #5bc5d0;
  border: none !important;
  position: relative;
  border-radius: 30px;
}
td[class*="CalendarDay__selected_start"] {
  @extend %calendarDaySelectedStartOrEndBase;
  &::after {
    @extend %pseudoElementifiedBordersBase;
    left: 23px;
  }
}
td[class*="CalendarDay__selected_end"] {
  @extend %calendarDaySelectedStartOrEndBase;
  &::before {
    @extend %pseudoElementifiedBordersBase;
    right: 23px;
  }
}
div[class*="DayPickerNavigation"] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  cursor: pointer;
  &:nth-child(1) {
    justify-self: start;
    margin: 10px 0px 0px 10px;
    & img {
      margin-top: 12px;
    }
  }
  &:nth-child(2) {
    justify-self: end;
    margin-top: 20px;
    & img {
      margin-top: 1px;
    }
  }
}

@keyframes fadeIn {
  100% {
    opacity: 1;
  }
}
