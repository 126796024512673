.title {
  width: 100%;
  text-transform: uppercase;
  text-align: left;
  font-size: 10px;
  color: #546e7a;
  font-family: 'Metropolis', sans-serif;
  font-weight: 800;
  letter-spacing: 0.06em;
}
.errorMessage {
  position: relative;
  color: rgb(255, 51, 51);
  text-align: left;
  top: -10px;
  font-weight: 300;
}
.required {
  font-size: 20px;
  font-weight: 800;
  width: 100%;
  margin-left: 5px;
  color: rgb(255, 51, 51);
  text-align: left;
}
.textarea {
  resize: none;
  width: 100%;
  border: none;
  border-bottom: 1px solid #dddddd;
  color: #5bc5d0;
  font-weight: 400;
  font-size: 16px;
  font-family: 'Metropolis';
  &::-webkit-input-placeholder {
    opacity: 1;
    --webkit-transition: opacity 0.5s;
    transition: opacity 0.5s;
  }
  &::moz-placeholder {
    opacity: 1;
    --moz--transition: opacity 0.5s;
    transition: opacity 0.5s;
  }
  &:ms-input-placeholder {
    opacity: 1;
    --ms-transition: opacity 0.5s;
    transition: opacity 0.5s;
  }
  &::placeholder {
    opacity: 1;
    transition: opacity 0.5s;
  }
  &:focus::-webkit-input-placeholder {
    opacity: 0;
  }
  &:focus::-moz-placeholder {
    opacity: 0;
  }
  &:focus::-ms-input-placeholder {
    opacity: 0;
  }
  &:focus::-placeholder {
    opacity: 0;
  }
}
.supportingText {
  color: #a6a6a6;
}
.supportingTextGreen {
  color: #4caf50;
}
.noScrollBar {
  overflow-y: hidden;
}
