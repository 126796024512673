.wrapper {
  display: flex;
  cursor: pointer;
  & div {
    & span {
      margin: 0px 5px;
    }
  }
}
.title {
  width: 100%;
  text-transform: uppercase;
  text-align: left;
  font-size: 10px;
  color: #546e7a;
  font-family: 'Metropolis', sans-serif;
  font-weight: 800;
  letter-spacing: 0.06em;
}
.errorMessage {
  position: relative;
  color: rgb(255, 51, 51);
  text-align: left;
  top: 0px;
  font-weight: 300;
}
.required {
  font-size: 20px;
  font-weight: 800;
  width: 100%;
  margin-left: 5px;
  color: rgb(255, 51, 51);
  text-align: left;
}
.blue {
  color: #5bc5d0;
}
.gold {
  color: #f3a536;
}
.supportingText {
  color: #a6a6a6;
}
.supportingTextGreen {
  color: #4caf50;
}
.questionTitle {
  font-size: 19px;
  font-family: 'Metropolis';
  color: #37474f;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.halfIconWrapper {
  display: inline-block;
}
