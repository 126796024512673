@import '../../styleguide/variables';

.schedulerWrapper{
  padding: 40px 120px;
  margin-bottom: 4.5rem;
  min-height: calc(100vh - 4.5rem - 206px);
}
.schedulerContainer{
  display: flex;
  justify-content: space-between;
  gap: 24px;
}
@media screen and (max-width: 920px) {
  .schedulerContainer {
    flex-direction: column;
  }
}
.scheduler{
  margin-top: 20px;
  padding: 24px;
  border: 2px solid $gray-2;
  border-radius: 12px;
}
.schedulerQuestions{
  flex: 6;
  height: fit-content;
}
.schedulerAnswers{
  flex: 4;
  overflow-y: auto;
}
.tally{
  margin-top: 25px;
  margin-bottom: 18px;
}
.tally h6{
  color: $gray-6;
}
.tallyQuestion{
  line-height: 24px;
  font-weight: 600;
}
.tallyAnswer{
  margin: 0 0 12px 12px;
  font-weight: 400;
}
.buttonWrapper{
  margin-top: 24px;
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  align-items: flex-end
}
.buttonWrapper > :first-child{
  margin-right: auto;
}