@import '../../styleguide/variables';

@keyframes pulsateFwd {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.dangerouslySetInnerHTML {
  margin: 0px;
  font-family: 'Metropolis', sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
}

.receivedMessage {
  width: 700px;
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  margin-top: 10px;
  & h4 {
    margin: 2px 0px 5px;
    height: 15px;
    color: $gray-5;
    font-family: 'Metropolis', sans-serif;
    font-size: 12px !important;
    line-height: 15px;
    & span {
      font-weight: 600;
      text-transform: capitalize;
    }
  }
  & [class*='dangerouslySetInnerHTML'] {
    color: $gray-9;
    overflow-wrap: break-word;
    & a {
      color: $main-color;
      text-decoration: none;
      display: inline-block;
    }
    & a:hover {
      color: $main-color_lighter;
      text-decoration: underline;
    }
    & a:focus {
      color: $main-color_darker;
      text-decoration: none;
    }
  }
  & img[class*='providerMessageAvatar'] {
    margin: 0;
  }
  & [class*='avatarImage'] {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    & [class*='initialsLabel'] {
      color: $white;
      font-size: 12px;
      line-height: 14px;
      padding: 0;
      letter-spacing: 0.1px;
    }
    &[alt*='avatar'] {
      margin: 0;
    }
  }
  & [class*='messageDetails'] {
    display: flex;
    flex-direction: column;
  }
}

.sentMessage {
  @extend .receivedMessage;
  flex-direction: row-reverse;
  align-self: flex-end;
  & [class*='dangerouslySetInnerHTML'] {
    color: $white;
    overflow-wrap: break-word;
    & a {
      color: $white;
      text-decoration: underline;
      display: inline-block;
    }
    & a:hover {
      color: $white;
      text-decoration: underline;
    }
    & a:focus {
      color: $white;
      text-decoration: underline;
    }
  }
  & [class*='messageDetails'] {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  & [class*='avatarImage'] {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    & [class*='initialsLabel'] {
      color: $white;
      font-size: 12px;
      line-height: 14px;
      padding: 0;
      letter-spacing: 0.1px;
    }
    &[alt*='avatar'] {
      margin: 0;
    }
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
.receivedMessage {
  width: 103%;
}
}
.receivedBubble {
  display: flex;
  flex-direction: column;
  width: 271px;
  border-radius: 6px;
  background: $gray-1;
  padding: 12px 22px;
  text-align: left;
  position: relative;
  box-shadow: 0 1px 3px 0 $gray-3;
  margin-top: 10px;
}

.sentBubble {
  display: flex;
  flex-direction: column;
  width: 271px;
  border-radius: 6px;
  background: $main-color;
  padding: 12px 22px;
  text-align: left;
  position: relative;
  box-shadow: 0 1px 3px 0 $gray-3;
  &:before {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-radius: 4px;
    border-right: 11px solid transparent;
    border-top: 15px solid $main-color;
    box-shadow: 0 1px 3px 0 $gray-3;
    transform: skewX(-20deg);
    right: -2px;
    top: 0;
    z-index: -1;
  }
  &:after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-radius: 4px;
    border-right: 11px solid transparent;
    border-top: 15px solid $main-color;
    transform: skewX(-20deg);
    right: -2px;
    top: 0;
  }
}

.receiveAttachment {
  display: flex;
  flex-direction: column;
  width: 271px;
  border-radius: 6px;
  background: $main-color;
  padding: 12px 22px;
  margin-top: 10px;
  text-align: left;
  box-shadow: 0 1px 3px 0 $gray-3;
  position: relative;
  &:before {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-radius: 4px;
    border-right: 11px solid transparent;
    border-top: 15px solid $main-color;
    box-shadow: 0 1px 3px 0 $gray-3;
    transform: skewX(-20deg);
    right: -2px;
    top: 0;
    z-index: -1;
  }
  &:after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-radius: 4px;
    border-right: 11px solid transparent;
    border-top: 15px solid $main-color;
    transform: skewX(-20deg);
    right: -2px;
    top: 0;
  }
}
.sentAttachmentBubble {
  @extend .sentBubble;
  background: $highlight;
  width: 293px;
  padding-left: 0;
  cursor: pointer;
  &:before {
    border-top: 15px solid $highlight;
  }
  &:after {
    border-top: 15px solid $highlight;
  }
}
.receivedAttachment {
  @extend .receivedBubble;
  background: $highlight;
  width: 293px;
  padding-left: 0;
  cursor: pointer;
}
.noTab {
  &:before {
    content: '';
    display: none !important;
  }
  &:after {
    content: '';
    display: none !important;
  }
}
.messageDetails {
  margin-left: 8px;
  margin-right: 8px;
  &[class*='sending'] {
    & p {
      animation: pulsateFwd 1s ease-in-out infinite both;
      color: $gray-2;
      margin: 0px 15px 0px 0px;
      align-self: center;
      font-size: 16px;
      font-weight: 300;
    }
    & span {
      color: $gray-2;
    }
    & h4 {
      color: $gray-2;
    }
  }
  & div[class*='sending'] {
    background-color: $gray-1;
    height: 100%;
    & [class*='dangerouslySetInnerHTML'] {
      color: $gray-2;
      overflow-wrap: break-word;
    }
    &:before {
      border-top: 15px solid $gray-1;
    }
    &:after {
      border-top: 15px solid $gray-1;
    }
  }
  &[class*='failedDetails'] {
    & h4 {
      color: $destructive;
    }
  }
}

.sentDetails {
  display: flex;
  flex-direction: row;
}

.sending {
  opacity: 1;
}

.sent {
  transition: all 0.3s ease;
  &:before {
    transition: all 0.3s ease;
  }
  &:after {
    transition: all 0.3s ease;
  }
}
.attachmentDetails {
  display: flex;
}
.attachmentFileType {
  background: white;
  height: 60px;
  border-radius: 6px;
  color: $highlight;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 7px;
  margin-right: 7px;
  & div {
    height: 14px;
    width: 60px;
    text-align: center;
    font-family: 'Metropolis', sans-serif;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -1px;
    line-height: 17px;
  }
}
.attachmentDescription {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2px;
  & p {
    color: $white;
    font-family: 'Metropolis', sans-serif;
    font-size: 16px;
    letter-spacing: -0.32px;
    line-height: 20px;
    margin: 0;
    word-break: break-all;
  }
}
.failedDetails {
  & span {
    color: $destructive;
  }
  & h4 {
    color: $destructive;
  }
  & [class*='sentBubble'] {
    background-color: $destructive;
    transition: all 0.3s ease;
    &:before {
      transition: all 0.3s ease;
      border-top: 15px solid $destructive;
    }
    &:after {
      transition: all 0.3s ease;
      border-top: 15px solid $destructive;
    }
  }
}

.sendingLoaderWrapper {
  display: grid;
  align-self: center;
  margin-right: 25px;
  background-color: $white;
}
.attachmentParent {
  display: block;
}
