.wrapper { flex: 2; }
.headingWrapper {
  display: flex;
  & > div {
    flex: 1;
    height: 44px;
    color: white;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  & > div:nth-of-type(1) { background-color: #455A64; border-radius: 6px 0 0 6px; }
  & > div:nth-of-type(2) { background-color: #607D8B; }
  & > div:nth-of-type(3) { background-color: #90A4AE; }
  & > div:nth-of-type(4) { background-color: #B0BEC5; }
  & > div:nth-of-type(5) { background-color: #CFD8DC; border-radius: 0 6px 6px 0; }
}
.headingSection { display: flex; flex-direction: column; }
.arrow {
  margin-top: 25px;
  position: absolute;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 7px solid #455A64;
}
