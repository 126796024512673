@import '../../styleguide/variables';

.messagesSection {
  opacity: 1;
  padding-top: 16px;
}
.readMarker {
  display: inline-block;
  height: 10px;
  width: 10px !important;
  border-radius: 50%;
  border: 2px solid white;
  background-color: $destructive;
  transform: translate(-5px, 8px);
  position: absolute;
  opacity: 1 !important;
}
.subjectHeader {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  z-index: 1;
  height: 70px;
  width: 620px;
  border-radius: 10px;
  background-color: $gray-8;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
  padding: 17px 40px;
  & div {
    margin: 0;
  }
  & div[id*='subjectInputWrapper'] {
    width: 476px;
    height: 32px;
    & span[class*='input_required'] {
      left: 15px;
    }
  }
  & [class*='inputWrapper'] {
    width: 476px;
    height: 32px;
  }
  & input[id*='subjectInput'] {
    background-color: $gray-8;
    color: $white;
    font-family: 'Metropolis', sans-serif;
    font-size: 23.8px;
    letter-spacing: -1.48px;
    margin: 0;
    width: 477px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    height: 23.8px;
    border-bottom: 1px solid $gray-5;
    padding-bottom: 3px;
  }
  & h3 {
    color: $white;
    font-family: 'Metropolis', sans-serif;
    font-size: 23.8px;
    font-weight: 400;
    width: 620px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    line-height: 23.8px;
  }
  & input[id*='subjectInput']::placeholder {
    color: $gray-4;
    font-size: 23.8px;
    height: 28px;
  }
  & [class*='details'] > h6 {
    font-family: 'Metropolis', sans-serif;
    font-weight: 500;
  }
  & [class*="errorMessage"] {
    top: 0px;
    color: #ffffff;
    font-weight: bold;
    img {
      width: 16px;
      margin-right: 5px;
    }
  }
}

@media (min-width: 1024px) and (min-height: 768px) {
.providerNameMsg{
display: none;
}
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .messagesSection{
    padding-top: 40px;

    & div[id*='providerListContainer'] {
      margin-top: 100px;
    }
  }
  .providerNameMsg{
    font-family: "Metropolis", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    color: #455a64;
    margin-left: 3%;
    margin-bottom: 2%;
    text-align: left;
    letter-spacing: 0.08px;
  }
  .subjectHeader {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    z-index: 1;
    height: 70px;
    width: 75%;
    margin-left: 2%;
    border-radius: 10px;
    background-color: #455a64;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
    padding: 17px 40px;

    & div[id*='subjectInputWrapper'] {
      width: 100%;
      height: 32px;
    }
    & input[id*='subjectInput'] {
      background-color:  #455a64 !important;
      color: #ffffff;
      font-family: 'Metropolis', sans-serif;
      font-size: 16.8px;
      letter-spacing: -1.48px;
      margin: 0;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      height: 23.8px;
      border-bottom: 1px solid $gray-5;
      padding-bottom: 3px;
    }
    & [class*='inputWrapper'] {
      width: 134%;
      height: 32px;
    }
    & div[class*='errorMessage'] {
      color: #ffffff;
      font-weight: bold;

      img {
        width: 16px;
        margin-right: 5px;
      }
    }
  }
}
.subjectHeaderTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 15px;
}

.inactive {
  opacity: 0.4;
  pointer-events: none;
  color: $gray-4;
}

.messageReady {
  & a[role*='button'] {
    pointer-events: visible;
  }
  & img[id*='sendMessage'] {
    content: url('https://assets.gobreeze.com/active-messages-send.svg');
    cursor: pointer;
  }
}

.messageConversation {
  margin-top: 10px;
  width: 700px;
  height: 448px;
  padding: 90px 0px 16px;
  overflow: auto;
}

.deleteMessageBtn {
  opacity: 1;
}

.writeMessage {
  display: flex;
  flex-direction: row;
  width: 700px;
  border-bottom: 1px solid $gray-2;
  border-top: 1px solid $gray-2;
  justify-content: space-between;
  & div {
    width: 89%;
    margin-bottom: 0;
    justify-content: center;
    opacity: 0.9;
  }
  & [class*='title'] {
    display: none;
  }
  & textarea {
    resize: none;
    color: $gray-9;
    max-width: 603px;
    line-height: 23px;
    height: 33px;
    margin-top: 11px;
    margin-bottom: 1px;
    border-bottom: none;
    font-size: 15px;
  }
  & a {
    pointer-events: none;
  }
  & img {
    padding: 3px 10px 0px 0px;
    align-self: flex-start;
  }
  & textarea::placeholder {
    color: $gray-4;
    font-size: 15px;
    height: 28px;
    font-weight: 300;
    font-family: 'Metropolis', sans-serif;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
.writeMessage{
  width: 103%; 
  & textarea {
    resize: none;
    color: $gray-9;
    line-height: 23px;
    width: 94%;
    height: 28px;
    margin-top: 11px;
    margin-bottom: 1px;
    border-bottom: none;
    font-size: 13px;
  }
}
.messageConversation {
width: 100%;
height: 330px;
  }
}
.newMessageText {
  border-bottom: none;
  border: none;
  cursor: auto;
  color: $gray-9;
  width: 89%;
  font-size: 15px;
  font-family: 'Metropolis', sans-serif;
  font-weight: 300;
}

.subjectHelper {
  position: absolute;
  margin: 95px 0px 0px 252px;
  border-radius: 6px;
  color: $white;
  font-size: 14px;
  text-align: center;
  line-height: 39px;
  font-family: 'Metropolis', sans-serif;
  background-color: $destructive;
  height: 39px;
  width: 196px;
  box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, 0.15);
  &:before {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-radius: 4px;
    border: 7px solid $destructive;
    box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, 0.15);
    transform: rotate(45deg);
    left: 88px;
    top: -5px;
    z-index: -1;
  }
  &:after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-radius: 4px;
    border: 7px solid $destructive;
    transform: rotate(45deg);
    left: 88px;
    top: -5px;
  }
}

@keyframes vibrate {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translate(-2px, 2px);
  }
  40% {
    transform: translate(-2px, -2px);
  }
  60% {
    transform: translate(2px, 2px);
  }
  80% {
    transform: translate(2px, -2px);
  }
  100% {
    transform: translate(0);
  }
}
.vibrate {
  animation: vibrate 0.3s linear 2 both;
}
.addAttachment {
  width: unset !important;
  & img {
    cursor: pointer;
  }
}

.emergencyWarning {
  margin-top: 10px;
  text-align: center;
  & div {
    color: $gray-5;
    font-size: 13px;
    font-weight: 300;
    line-height: 1.5;
  }
}

.nextConversationLoaderWrapper {
  margin-top: 84px;
  display: grid;
  min-height: 477px;
  justify-content: center;
  align-items: center;
  & img {
    width: 163.57px;
    height: auto;
    color: #eceff1;
    margin: 0 auto 15px;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
.emergencyWarning {
  margin-top: 10px;
  text-align: initial;
  padding-left: 7%;
}
}