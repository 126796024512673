@import '../../styleguide/variables';
.wrapper {
  display: flex;
  margin-right: 16.5px;
}
.emptyBox {
  box-sizing: border-box;
    width: 340px;
    height: 150px;
    background-color: rgba(236, 239, 241, 0.2);
    border: 1px dashed #cfd8dc;
    border-radius: 10px;
    margin: 0 auto;
    text-align: center;
    float: left;
    margin-bottom: 10px;
    margin-top: 22px;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & h3{
      margin-top: 0;
    }
    & img{
      height: 70px;
      width: 70px;
    }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .emptyBox{
    box-sizing: border-box;
    width: 365px;
    height: 150px;
    background-color: rgba(236, 239, 241, 0.2);
    border: 1px dashed #cfd8dc;
    border-radius: 10px;
    margin-left: 10%;
    text-align: center;
    float: left;
    margin-bottom: 10px;
    margin-top: 22px;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & h3{
      margin-top: 0;
    }
  }
}
.iconContainer {
  display: flex;
}
.apptExtended {
  height: 330px;
}
.paymentSmall {
  @extend .paymentSection;
  height: 200px;
}
.paymentExtended {
  @extend .paymentSection;
  height: 240px;
}
.paymentSection {
  width: 340px;
  border-radius: 10px;
}
.emptyMessage {
  font-family: 'Metropolis', sans-serif;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: #607d8b;
  margin-top: 25px;
}
.emptyLink {
  color: #5bc5d0;
  font-family: 'Metropolis', sans-serif;
  font-size: 11px;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
.payments {
  height: 200px;
  border-radius: 10px;
  width: 301px;
}
.history {
  height: 260px;
  border-radius: 10px;
  width: 301px;
}
.addAppointment {
  margin-top: 7px;
  height: 472px;
  width: 341px;
  padding-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  & h3 {
    margin-top: 7px;
  }
}
.addAppointmentNoVisit {
  @extend .addAppointment;
  margin-top: 18px;
  padding-top: 173px;
  & h4 {
    width: 176px;
  }
}
.formHistoryNoForms {
  @extend .addAppointment;
  padding-top: 105px;
  & h4 {
    width: 280px;
  }
  width: 750px;
  height: 462px;
  margin-top: 6px;
}
.formHistoryNoProviderSelected {
  @extend .addAppointment;
  padding-top: 105px;
  & h3 {
    color: #ced7dc;
    width: 280px;
  }
  width: 750px;
  height: 462px;
  margin-top: 6px;
}
.surveysNoSurvey {
  @extend .formHistoryNoForms;
  & h3 {
    margin-top: 30px;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
.formHistoryNoProviderSelected{
  width: 100%;
}
.formHistoryNoForms{
  width: 330px;
}
.surveysNoSurvey{
  width: 100%;
  margin-left: 5px;
}

}
.surveysGuestComplete {
  display: flex;
  flex-direction: column;
  background: #60ce69;
  border: 1px solid #60ce69;
  border-radius: 8px;
  padding: 40px;
  width: 700px;
  min-height: 400px;
  justify-content: center;
  & h3 {
    color: #fff;
  }
}
.addAppointmentNoBtn {
  @extend .addAppointment;
  padding-top: 205px;
  margin-top: 19px;
  & h3 {
    font-size: 16px;
    color: #607d8b;
    font-weight: 700;
  }
}
.emptySubMessage {
  color: #90a4ae;
  font-size: 13px;
  font-family: 'Metropolis';
  width: 238px;
}
.backToAppointments {
  text-transform: uppercase;
  height: 38px;
  width: 240px;
  border: 1px solid #5bc5d0;
  border-radius: 6px;
  text-decoration: none !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 15px;
  font-size: 13px;
  font-weight: 500;
}
