.confirm {
  & div[id*='comfirmationTopBar'] {
    height: 52px;
    box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.3);
    margin: 0 55px;
    margin-bottom: 10px;
  }
  & div[id*='confirmationInvalidGroup'] {
    align-items: center;
    & img[id*='mainLogo'] {
      margin: auto 0;
      min-height: 44px;
    }
    & img[id*='breezeEnvelopeLogo'] {
      margin-bottom: 40px;
      height: 230px;
      width: 192px;
    }
    & [id*='confirmationInvalidHeader'] {
      font-size: 24px;
      font-weight: 500;
      letter-spacing: -1.5px;
      text-align: center;
      margin: 0 1vw;
      & h4 {
        color: #4b5a64;
      }
    }
    & [id*='confirmationInvalidTextTop'] {
      height: 68px;
      width: 100vw;
      text-align: center;
      line-height: 17px;
      margin: 0 1vw;
      margin-top: 5px;
    }
    & h5 {
      color: #78909c;
      font-size: 14px;
      font-family: 'Metropolis' sans-serif;
      font-weight: 300;
      margin: auto;
    }
  }
}
