%delegateProfileBase {
  display: grid;
  grid-template-columns: auto;
  min-height: 100vh;
  animation: fadein 0.5s forwards;
  & h2[id*="delegateScreenHeading"] {
    height: 36px;
    width: 694px;
    color: #FFFFFF;
    letter-spacing: normal;
    font-family: "Metropolis";
    font-size: 30px;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    margin: 0 auto;
  }
}
.delegateProfileWrapper {
  @extend %delegateProfileBase;
}
%delegateProfileContentBase {
  margin: 0 auto;
  padding-top: 0;
  width: 100%;
  background: url("https://assets.gobreeze.com/BG.svg") no-repeat;
  background-size: cover;
}
.delegateProfileContent {
  @extend %delegateProfileContentBase;
}
.delegateProfileMobileContent {
  @extend %delegateProfileContentBase;
  height: 1500px;
}
.delegateProfileWrapperMobile {
  @extend %delegateProfileBase
}
.delegateProfileScreenTop {
  background-color: red;
}