.avatar {
  width: 58px;
  height: 58px;
  border-radius: 50%;
  text-align: center;
}
.wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  & img {
    margin: 0 10px;
    width: 58px;
    height: 58px;
  }
  & div[class*="navAvatar"] {
    margin-bottom: 0px;
  }
}
.initials {
  color: #ffffff;
  font-family: "Metropolis";
  font-size: 19px;
  letter-spacing: -1px;
  font-weight: 500;
  background-color: #546e7a;
  left: calc(50% - 29px);
  margin-bottom: 8px;
}
.initialsLabel {
  margin-top: 0px;
  padding-top: 13px;
}

.profileSettingsAvatar {
  position: unset;
  height: 86px !important;
  width: 86px !important;
  margin-bottom: 16px;
  background-color: #455a64;
  & p {
    padding-top: 27px;
    font-size: 30px;
    font-weight: 500;
    line-height: 36px;
  }
}
.avatarImage {
  background-color: transparent;
}

.makePaymentsAvatar {
  position: relative;
  top: -14px;
  z-index: -1;
}
%modalAvatarBase {
  margin: -30px 0px auto;
  position: absolute;
}
.paymentsModalAvatar {
  @extend %modalAvatarBase;
}
.monthlyModalAvatar {
  @extend %modalAvatarBase;
  transform: translateX(-30px);
}

.appointmentPaymentAvatar {
  font-weight: 400;
  top: -6px;
}

.navAvatar {
  width: 35px !important;
  height: 35px !important;
  margin-left: 0 !important;
  cursor: pointer;
  align-self: center;
  font-size: 12px;
  transition: background-color 0.4s;
  &:hover {
    background-color: #5bc5d0;
  }
}

.retailAvatar {
  margin: 0 10px;
  font-weight: 400;
  & img {
    width: 58px;
    height: 58px;
  }
}

.providerAvatar {
  margin: 0 10px;
  font-weight: 400;
  & img {
    width: 58px;
    height: 58px;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .providerAvatar {
    background-color: white;
    margin: 0 10px;
    font-weight: 400;
    & img {
      width: 58px;
      height: 58px;
    }
  }
}
.providerMessageAvatar {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  & [class*="initialsLabel"] {
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    padding: 0px;
    margin: 0px;
    letter-spacing: 0.1px;
  }
  &[alt*="avatar"] {
    margin: 0px;
  }
}

.userAvatar {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  & [class*="initialsLabel"] {
    color: #fff;
    font-size: 12px;
    line-height: 14px;
    padding: 0px;
    letter-spacing: 0.1px;
    margin: 0px;
  }
  &[alt*="avatar"] {
    margin: 0px;
  }
}
.activeProfile {
  & div[class*="navAvatar"],
  img {
    width: 50px !important;
    height: 50px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & [class*="initialsLabel"] {
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    padding: 0px;
    margin: 0px;
    letter-spacing: 0.1px;
  }
}
.inactiveProfile {
  & div[class*="navAvatar"],
  img {
    width: 28px !important;
    height: 28px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & [class*="initialsLabel"] {
    font-weight: 400;
    letter-spacing: 0.1px;
    padding-top: 13px;
  }
}
.careTeamProviderImage{
  width: 48px !important;
  height: 48px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
}
.historyAppointmentImage{
  width: 32px !important;
  height: 32px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px !important;
  font-size: 13px;
}
