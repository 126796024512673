.errorContainer {
  height: 100vh;
  background-color: #263238;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  & h1 {
    color: #9fe5ed;
    font-family: 'Metropolis';
    font-size: 25px;
    font-weight: 400;
    text-transform: none;
  }
  & h3 {
    color: #90a4ae;
    font-family: 'Metropolis';
    font-size: 14px;
    font-weight: 400;
    width: 412px;
    line-height: 21px;
  }
  & h3:first-letter {
    text-transform: capitalize;
  }
  & img#tony {
    width: 305px;
    border: 2px solid white;
    margin-top: 15px;
  }
  & img#breezeErrorLogo {
    min-width: 305px;
    height: 72px;
    margin: 50px 0px;
  }
}

.errorDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.errorPageRetryBtn {
  box-shadow: none;
  background-image: none;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  color: #fff;
  font-size: 13px;
  font-family: 'Metropolis';
  font-weight: 400;
  text-transform: uppercase;
  background-color: #5bc5d0;
  width: 264px;
  height: 40px;
  line-height: 40px;
  margin-top: 30px;
}
