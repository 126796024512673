@import '../styleguide/variables';


.layoutWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    animation: fadeIn 0.5s forwards;
  }

  @keyframes fadeIn {
    from { opacity: 0 };
    to { opacity: 1 };
  }

  footer {
    color: $gray-1;
    background-color: $main-color;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 75px;
    line-height: 75px;
    padding: 0 45px;
    width: 100%;
  }
.timer{
  align-items: center;
  display: flex;
  background: #5bc5d0;
}
.timerP{
  background: #5bc5d0;
  padding: 0px;
  margin: 0rem;
  width: 8rem;
  color: white;
}