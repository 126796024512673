.dashboardContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto;
  min-height: calc(100vh - 206px);
  padding: 100px 20px 40px 98px;
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .dashboardContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 auto;
    min-height: calc(100vh - 206px);
    padding: 100px 25px 40px 25px;
  }
}