.wrapper {
  transition: all 0.5s ease-out;

  & div[class*='SingleDatePicker_picker'] {
    top: 40px;
    left: -275px !important;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    // height: 433px;
    border: 1px solid #cfd8dc;
    border-radius: 6px;
    overflow: hidden;
    z-index: 2;
  }

  & svg[class*="DateInput_fang DateInput_fang_1"] {
    display: none;
  }

  & div[class*='DayPicker_focusRegion'] {
    padding: 1px 0px;
    width: 327px;
  }
  & div[class='DayPicker_weekHeader'] {
    top: 68px;
  }
  & div[class*='Daypicker'] {
    width: 300px !important;
    box-shadow: unset;
    -webkit-box-shadow: unset !important;
    border-bottom: 1px solid #cfd8dc;
    border-radius: 0;
  }

  & div[class*='DayPickerNavigation_button__horizontal'] {
    top: unset !important;
    padding-top: 15px;
    & svg[id='previousMonth'] {
      position: absolute;
      left: 22px;
      top: 10px;
    }
    & svg[id='nextMonth'] {
      position: absolute;
      right: 32px;
      top: 10px;
    }
  }

  & div[class*='SingleDatePickerInput'] {
    width: 0px;
    border-top: none;
    border-left: none;
    border-right: none;

    & div:nth-child(1) {
      display: block;
      & input {
        display: none;
      }
      & p {
        display: none;
      }
    }

    & button:last-child {
      margin: 0px;
      padding: 10px 10px 11px;
      border-bottom: 1px solid #c8d2d7;
      & div {
        display: none;
      }
      & svg {
        margin-right: 0px;
      }
    }

    & div[class*='DateInput'] {
      & input {
        width: 0%;
        padding: 2px;
        border: none;
        display: none;
        cursor: pointer;
        font-size: 16px;
        box-shadow: none;
        color: #5bc5d0;
        font-weight: 400;
        line-height: 1.846;
        font-family: 'Metropolis';
        -webkit-font-smoothing: antialiased;
        &[disabled] {
          color: #cfd8dc;
        }
      }
    }

    & div[class*='SingleDatePickerInput_calendarIcon'] {
      margin: 0px;
    }

    & div[class*='DateInput__block'] {
      width: 90%;
    }
  }

  & div[class*='SingleDatePickerInput__withBorder'] {
    border: none !important;
  }

  & div[class*='CalendarMonth_caption'] {
    color: #546e7a;
    font-family: 'Metropolis';
    font-size: 20px;
    font-weight: 300;
    line-height: 20px;
    padding-bottom: 14px;
    padding-top: 15px;
    text-transform: capitalize;
  }
  & div[class*='DayPickerNavigation_button'] {
    border: 0 !important;
    & svg {
      height: 20px;
      width: 18px;
      color: #546e7a;
    }
  }
  & div[class*='CalendarDay__outside'] {
    height: 18.5px;
    color: #cfd8dc;
    font-family: 'Metropolis';
    font-size: 12px;
    line-height: 18px;
  }
  & div[class*='DayPicker_transitionContainer'] {
    width: 327px !important;
    height: 310px;
  }
  & ul[class*='DayPicker_weekHeader_ul'] {
    top: 57px;
    height: 24px;
    & li[class*='DayPicker_weekHeader_li'] {
      color: #607d8b;
      font-family: 'Metropolis';
      line-height: 18px;
      text-transform: uppercase;
      &:after {
        color: #607d8b;
        font-family: 'Metropolis';
        font-size: smaller;
        line-height: 18px;
        text-transform: uppercase;
      }
    }
  }
  & td {
    border: 0.5px solid #ffffff;
    background-color: rgba(236, 239, 241, 0.4);
  }
  & td[class*='CalendarDay'] {
    background-color: #fff;
  }
  & td[class*='CalendarDay__selected'] {
    background-color: #5bc5d0;
  }
  & tr [class*='CalendarMonth_table'] {
    height: 40px;
  }

  .activeCalendarWrapper {
    z-index: 100;
  }

  .buttonsWrapper {
    position: absolute;
    z-index: 999;
    width: 277px;
    margin-left: 26px;
    margin-top: 395px;
    height: 76px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-left: 21px;
    padding-right: 21px;
    // width: 276px;
    & button {
      flex: 1;
      text-transform: uppercase;
      height: 40px;
      font-family: 'Metropolis';
      font-size: 13px;
      font-weight: 500;
      box-shadow: none;
      background-image: none;
      border-radius: 6px;
      border: none;
      cursor: pointer;
      &:first-child {
        margin-right: 10px;
        height: 42px;
        color: #5bc5d0;
        border-color: #5bc5d0;
        box-shadow: 1px 1px 4px rgba(31, 155, 222, 0.4);
        border: 1px solid rgba(31, 155, 222, 0.4);
        width: 100%;
        display: block;
        background-color: #ffffff;
        padding: 8px;
        margin: 10px 0;
        cursor: pointer;
        outline: none;
      }
      &:last-child {
        margin-left: 10px;
        height: 42px;
        color: #fff;
        background-color: #5bc5d0;
        &:hover {
          background-color: #43bdc9;
        }
        cursor: pointer;
      }
    }
  }
  .pickADateWrapper {
    position: absolute;
    z-index: 999;
    width: 300;
    margin-left: 26px;
    border-bottom: 1px solid #cfd8dc;
  }
  .pickADate {
    margin-top: 70px;
    text-align: center;
    height: 13px;
    color: #37474f;
    font-family: 'Metropolis';
    font-size: 13px;
    font-weight: bold;
    line-height: 16px;
    margin-bottom: 12px;
    text-transform: uppercase;
  }
  & div[class*='datePickerSection'] {
    display: flex;
    margin-left: 5px;
    cursor: pointer;
  }

  $breakpoint-mobile: 567px;
  @media (max-width: $breakpoint-mobile) {
    .errorMessage {
      top: -6px;
    }

    & div[class*='SingleDatePickerInput'] {  
      & div[class*='DateInput__block'] {
        width: 85%;
      }
    }

    & div[class*='SingleDatePicker_picker'] {
      left: -275px !important;
    }
  }
}

.infoPanelButtonsWrapper {
  text-align: center;
  padding: 20px 0px;
  border-top: 1px solid #cfd8dc;
  & button {
    margin: 0px 10px;
    width: 130px;
    height: 40px;
    font-size: 16px;
    font-weight: 400;
    font-family: "Metropolis";
    border-radius: 6px;
    cursor: pointer;
  }
  & .saveBtn {
    color: #fff;
    border: 2px solid #5bc5d0;
    background: #5bc5d0;
  }
  & .cancelBtn {
    color: #000;
    border: 1px solid #000;
    background: #fff;
    margin-left: 0;
  }
}
