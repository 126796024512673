.parentWrapper {
  display: block;
}

.childWrapper {
  margin-left: 20px;
}

.checkBoxContainer {
  & input[type="checkbox"]:checked + div div {
    color: #5bc5d0;
  }
  & input[type="checkbox"]:checked + div div[class*="supportingText"] {
    color: #4caf50;
  }
  cursor: pointer;
  & input[type="checkbox"] {
    margin-left: 15px;
    position: relative;
    border: none;
    margin-top: 0px;
    margin-bottom: -4px;
    appearance: none;
    cursor: pointer;
  }
  & input[type="checkbox"]:after {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    margin-top: -2px;
    margin-right: 5px;
    border: 2px solid #c9d2d7;
    border-radius: 2px;
    transition: 240ms;
  }
  & input[type="checkbox"]:checked:before {
    content: "";
    position: absolute;
    top: 0px;
    left: 7px;
    display: table;
    width: 6px;
    height: 12px;
    border: 2px solid #fff;
    border-top-width: 0px;
    border-left-width: 0px;
    transform: rotate(45deg);
  }
  & input[type="checkbox"]:checked:after {
    border-color: #5bc5d0;
    background-color: #5bc5d0;
  }

  & input[type="checkbox"]:disabled:after {
    border-color: #c9d2d7;
    background-color: #c9d2d7;
  }

  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  text-transform: capitalize;
  font-size: 15px;
  color: #597583;
  font-weight: 500;
  font-family: "Metropolis";
  align-items: center;
  padding: 9px 1px 10px 0px;
  position: relative;
  height: 32px;
}
