.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto;
  min-height: calc(100vh - 206px);
  padding: 120px 0px;
  width: 1080px;

  & div[id*='footerForAppointmentCheckIn'] {
    display: none;
  }
}
.grid {
  display: grid;
}
.withPadding {
  margin-bottom: 4.5rem;
  min-height: calc(100vh - 4.5rem - 206px);
}
.zeroPadding {
  min-height: 100vh;
  padding: 0px;
}
.animate {
  animation: fadeIn 0.5s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media screen and (max-width: 1439px) {
  .container {
    padding: 0px;
  }
}
@media screen and (max-width: 460px) {
  .container {
    padding: 0px;
    max-width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .container {
    padding: 0px;
    max-width: 100%;
  }
}
@media screen and (max-width: 1024px){
  .container {
    padding: 0px;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile) {
  div[id*='medsAndAllergiesContainer'] {
    min-height: 82vh;
  }
  div[id*='footerForAppointmentCheckIn'] {
    color: #ECEFF1!important;
    display: flex!important;
    flex-direction: row;
    align-content: flex-end;
    justify-content: center;
    align-items: center!important;
    background-color: #fff;
    line-height: 0px;
    padding: 0;
    gap: 0px;
    width: 100%;
    z-index: 11;
    position: sticky;
    height: 74px;
    bottom: 0;
    & div[id*='Button'] {
      // padding-bottom: 10px;
      background-color: white;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    & div[id*='Button'] button{ 
      width: 98% !important;
    }
  }
}