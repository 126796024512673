.root {
  width: 1080px;
  margin: 0px auto;
  display: flex;
  padding: 40px 0px;
  min-height: calc(100vh - 206px);
  flex-direction: column;
  justify-content: flex-start;
  & div[class*='leftSideWrapper'] {
    position: sticky;
    margin-top: 50px;
    top: 100px;
    height: 100%;
  }
}
.signed {
  pointer-events: none;
  & a {
    pointer-events: auto;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
.root{
  width: 100%;
  & div[class*='leftSideWrapper'] {
    position: static;
  }
}
}
