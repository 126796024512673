.login {
  text-align: center;
  display: flex;
  width: 100vw;
  min-height: 100vh;
}
.rightSide {
  display: flex;
  flex: 1;
  background-color: #ffffff;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100vh;
}
.bg0 {
  background-position: center;
  background-size: 80%;
}

.checkmarkContainer {
  display: flex;
  padding: 20px;
  flex-direction: column;
  min-height: 300px;
  justify-content: center;
  & h6 {
    font-size: 16px;
    width: 100%;
    animation: 4s fadein;
  }
}
.customLogo {
  grid-row: 1;
  align-self: end;
  & img {
    max-width: 500px;
    max-height: 100px;
  }
}
.emailConfirmedLogo {
  margin-top: calc((100vh / 2 - 285px));
  & img {
    max-width: 500px;
  }
}
.caret {
  display: inline-block;
  width: 0px;
  height: 0px;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.patientPortalRightSide {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100vh;
  & h1 {
    font-size: 36px;
    color: #37474f;
    font-weight: 500;
    line-height: 40px;
    margin-bottom: 25px;
    text-transform: unset;
  }
  & h3 {
    color: #546e7a;
    font-size: 16px;
    font-family: 'Metropolis', sans-serif;
    line-height: 24px;
  }
  & h4 {
    text-align: left;
  }
  & h6 {
    color: #546e7a;
    font-size: 12px;
    line-height: 19px;
    text-align: center;
    margin-top: 35px;
    margin-bottom: 35px;
    width: 475px;
  }
  & div[id*='whiteLabelPortalContainer'] {
    display: flex;
    padding: 0px;
    margin: 0px 5%;
    & div[id*='whiteLabelPortalLeft'] {
      width: 40%;
      padding-right: 28px;
      margin-top: 4%;
      align-self: center;
      & div[id*='whiteLabelPortalBreezeLogo'] {
        margin-bottom: 30px;
        width: 180px;
        display: flex;
        margin-left: auto;
      }
      & div[id*='whiteLabelPortalText'] h4 {
        text-align: right;
        margin: 10px 0px;
        line-height: 30px;
        color: #37474f;
        font-weight: 500;
        padding-left: 13%;
      }
      & div[id*='whiteLabelPortalText2'] h6 {
        text-align: right;
        margin-bottom: 20px;
        margin-top: 0px;
        line-height: 30px;
        font-size: 16px;
        padding-left: 25%;
        font-weight: 300;
        color: #37474f;
        line-height: 1.5;
      }
    }
    & div[id*='whiteLabelPortalRight'] {
      width: 55%;
      margin-top: 5%;
    }
    & div[id*='patientPortalAppleAppStoreLogo'] img {
      height: 43px;
      width: 145px;
      margin-right: 30px;
    }
    & div[id*='patientPortalGoogleAppStoreLogo'] img {
      height: 43px;
      width: 145px;
    }
  }

  & div[id*='whiteLabelPortalAppleAppStoreLogo'] img {
    margin-bottom: 15px;
    width: 160px;
    display: flex;
    margin-left: auto;
  }
  & div[id*='whiteLabelPortalGoogleAppStoreLogo'] img {
    margin-bottom: 15px;
    width: 160px;
    display: flex;
    margin-left: auto;
  }
  & img[id*='whiteLabelPortalPhoneImage'] {
    height: 80vh;
  }
  & div[id*='whiteLabelBottomText'] {
    position: absolute;
    bottom: 20px;
    right: 30px;
    & a {
      padding: 0px 10px;
      text-decoration: none;
      color: #000;
      font-size: 12px;
      font-weight: 600;
    }
  }
}

@media (max-width: 1185px) {
  .rightSide {
    display: none;
  }
  .patientPortalRightSide {
    display: none;
  }
}
.loadComplete {
  & [class*='circleLoader'] {
    -webkit-animation: none;
    animation: none;
    border-color: #5cb85c;
    transition: border 500ms ease-out;
  }
  & [class*='draw'] {
    display: block;
  }
}
.loadIncomplete {
  & [class*='circleLoader'] {
    -webkit-animation: none;
    animation: none;
    border-color: #e82929;
    transition: border 500ms ease-out;
  }
  & [class*='cross'] {
    display: block;
  }
}
.loginFormWrapper {
  position: relative;
  & div[id*='passwordContainer']{
    & button[id='eyeIcon']{
      margin-top: 14.7px;
      border-bottom: 1px solid #c8d2d7;
      border-radius: 0;
      width: 10%;
      &>img{
        filter: grayscale(100%) !important;
        padding-top: 0;
        width: 18px;
      }
    }
    &>div{
      width: 90%
    }
  }
}
.loginFailureText {
  color: #e22b41;
  font-weight: 400;
  position: relative;
  font-family: 'Metropolis';
  font-size: 12px;
  margin: auto;
  height: 23px;
  width: 360px;
  top: 170px;
  bottom: 0;
  text-align: left;
}
div[id*='whiteLabelHeaderGroup'] {
  justify-items: center;
}
.continueOnWebBtn {
  color: #5bc5d0;
  font-weight: 500;
  font-family: 'Metropolis';
  text-transform: capitalize;
  background-color: transparent;
  transition: color 0.5s;
  font-size: 17px;
  font-weight: 500;
  padding-right: 10px;
  text-decoration-line: underline;
  padding-left: 20px;
  font-family: 'Metropolis';
  text-transform: capitalize;
  background-color: transparent;
  transition: color 0.5s;
  cursor: pointer;
}