.input {
  &[disabled] { color: #90A4AE };
  &::placeholder { color: #90A4AE };
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #CFD8DC;
  box-shadow: none;
  box-sizing: border-box;
  color: #5BC5D0;
  display: block;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  width: 100%;
  padding: 8px 0;
  height: initial !important;
}