.profile {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
}

div[class*='first'] {
  div[class*='inactiveProfile'],
  div[class*='inactiveProfile'] + div {
    display: none;
  }
}

div[class*='second'] {
  div[class*='activeProfile'],
  div[class*='activeProfile'] + div {
    display: none;
  }
  div[class*='inactiveProfile'],
  div[class*='inactiveProfile'] + div {
    display: flex;
  }
  div[class*='wrapper'] {
    div[class*='navAvatar'],
    img {
      width: 28px !important;
      height: 28px !important;
    }
  }
}
