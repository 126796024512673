.wrapper {
  align-items: center;
  background-color: transparent;
  border-bottom: 1px solid #CFD8DC;
  color: #37474F;
  display: flex;
  padding: 8px 0;
}

.action {
  flex-grow: 1;
  flex-basis: 0;
  padding: 0 0 0 16px;
}

.text {
  color: #37474F;
  flex-basis: 0;
  flex-grow: 2;
  font-size: 16px;
  line-height: 20px;
}
.red{
  flex-basis: 0;
  flex-grow: 2;
  font-size: 16px;
  line-height: 20px;
}
.preview {
  flex-grow: 2;
  flex-basis: 0;
}

.previewImage {
  & > img {
    border: 1px solid #5BC5D0;
    border-radius: 5px;
    box-shadow: 0 1px 2px 0 #CFD8DC;
    display: block;
    height: 60px;
    margin-right: 1rem;
    width: 100px;
  }

}

$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{

 .action {
  flex-grow: 1;
  flex-basis: 0;
  padding: 0 0 0 6px;
 }
  .previewImage {
    & > img {
      border: 1px solid #5BC5D0;
      border-radius: 5px;
      box-shadow: 0 1px 2px 0 #CFD8DC;
      display: block;
      height: 55px;
      margin-right: 1rem;
      width: 63px;
    }
  
  }
}
.previewText {
  color: #37474F;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  margin: 0 0 2px;
  text-transform: uppercase;
}

.fileInput { display: none }
