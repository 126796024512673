@import '../../styleguide/variables';

.changeLanguage {
  position: absolute;
  height: 240px;
  width: 160px;
  top: 5px;
  border-radius: 5px;
}
.dropDown {
  opacity: 0;
  transition: opacity 0.2s;
  & [class*='active'] {
    background-color: #5bc5d0;
    border-radius: 5px;
    color: white;
  }
}
.hamburgerMenu {
  flex: 1;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  div:hover {
    background-color: #5bc5d0;
    border-radius: 5px;
    a {
      color: white;
    }
  }
  & a {
    padding: 0;
    padding-left: 10px;
    list-style: none;
    font-weight: 500;
    text-decoration: none;
    font-size: 14px;
    color: #455a64;
    font-family: 'Metropolis', sans-serif;
    &.active {
      border-left: 2px solid #5bc5d0;
      color: #5bc5d0;
    }
  }
  opacity: 100;
  padding: 10px 10px 0 10px !important;
}
.userMenu {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 53px;
  right: 80px;
  z-index: 10;
  padding: 10px;
  text-align: center;
  background-color: #fff;
  width: 100px;
  border-radius: 6px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.4);
  & div[class*='group'] {
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
    margin: 0px;
  }
  & div[id*='supportAndSettings'] {
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
  }
  & div[class*='vertical'] {
    padding: 10px 0px;
  }
  & div[class*='vertical']:last-of-type {
    padding-left: 10px;
    border-bottom: none;
  }
  & div[class*='avatar'] {
    margin-right: 10px;
    margin: 5px 0px;
  }
  & a,
  button {
    padding: 5px 0px;
    list-style: none;
    font-weight: 500;
    text-decoration: none;
    font-size: 14px;
    height: 20px;
    color: #90a4ae;
    font-family: 'Metropolis', sans-serif;
    &:hover {
      color: #5bc5d0;
    }
  }
  cursor: pointer;
  & button {
    border: none;
    text-transform: capitalize;
    text-align: center;
  }
  opacity: 100;
  animation: fadeIn 0.7s;
}
$breakpoint-tablet: 567px;
@media (max-width: $breakpoint-tablet) {
  .userMenu {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 53px;
    right: 80px;
    z-index: 10;
    padding: 10px;
    text-align: center;
    background-color: #fff;
    width: 100px;
    border-radius: 6px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.4);
    top: 83%;
    right: 56%;
}
}
.languageMenu {
  @extend .userMenu;
  right: 12px;
  width: 80px;
  height: 60px;
  padding: 7px 10px;
  & button {
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.4);
    border-radius: 0;
    padding: 0;
    height: 30px;
  }
  & button:last-of-type {
    border-bottom: none;
  }
}
$breakpoint-tablet: 567px;
@media (max-width: $breakpoint-tablet) {
  .languageMenu {
    position: absolute;
    display: flex;
  right: 12px;
  width: 80px;
  height: 60px;
  padding: 7px 10px;
  opacity: 100;
  animation: fadeIn 0.7s;
  border-radius: 6px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.4);
  & button {
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.4);
    border-radius: 0;
    padding: 0;
    height: 30px;
  }
  & button:last-of-type {
    border-bottom: none;
  }
}
}
.loginLanguageMenu {
  @extend .languageMenu;
  position: relative;
  top: 0px;
  right: 0px;
  margin: 0 auto;
  & button {
    text-align: center;
  }
}
.visible {
  opacity: 100;
}
.profilesWrapper {
  max-height: 500px;
  height: auto;
  overflow-y: auto;
}
.practiceSelectDropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 38px;
  left: 127px;
  border: none;
  z-index: 12;
  width: 366px;
  opacity: 1;
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
  box-shadow: -2px 4px 12px rgb(0 0 0 / 20%);
}
$breakpoint-tablet: 567px;
@media (max-width: $breakpoint-tablet) {
  .practiceSelectDropdown {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 38px;
    left: 3%;
    border: none;
    z-index: 12;
    width: 100%;
    opacity: 1;
    border-radius: 18px;
    border-bottom-right-radius: 18px;
    box-shadow: -2px 4px 12px rgba(0, 0, 0, 0.2);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
