%timeBubbleBase {
  cursor: pointer;
  height: 42px;
  width: 158px;
  border: 1px solid #90a4ae;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  margin-right: 20px;
  margin-bottom: 20px;
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  %timeBubbleBase {
    cursor: pointer;
    height: 42px;
    width: 90px;
    border: 1px solid #90a4ae;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    margin-right: 20px;
    margin-bottom: 20px;
  }
}
.wrapper {
  display: flex;
  flex-direction: column;
}
.heading {
  display: flex;
  & h6 {
    height: 13px;
    color: #5bc5d0;
    font-family: "Metropolis";
    font-size: 11px;
    font-weight: 600;
    line-height: 13px;
  }
  & h3 {
    height: 42px;
    color: #b0bec5;
    font-family: "Metropolis";
    font-size: 38px;
    font-weight: 300;
    line-height: 50px;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .heading {
    display: flex;
    & h3 {
      height: 54px;
      color: #b0bec5;
      font-family: "Metropolis";
      font-size: 17px;
      font-weight: 700;
      line-height: 54px;
    }
  }
}

.dateTimeLabel {
  display: inline-block;
  height: 22px;
  color: #546e7a;
  font-family: "Metropolis";
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .dateTimeLabel {
    display: inline-block;
    height: 22px;
    color: #546e7a;
    font-family: "Metropolis";
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
  }
}
.dateTimeSection {
  margin-top: 1rem;
}
.timeSection {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.5rem;
}
.timeSectionHidden {
  opacity: 0;
  transition: opacity 0.7s ease-out;
  display: none;
}
.timeBubble {
  @extend %timeBubbleBase;
  & p {
    height: 21px;
    width: 100%;
    text-align: center;
    color: #90a4ae;
    font-family: "Metropolis";
    font-size: 17px;
    font-weight: 600;
    line-height: 21px;
  }
}
.selectedTimeBubble {
  width: 154px;
  height: 38px;
  @extend %timeBubbleBase;
  border: 3px solid #5bc5d0;
  box-shadow: 0 2px 4px 0 rgba(55, 71, 79, 0.3);
  & p {
    height: 21;
    width: 88px;
    color: #5bc5d0;
    font-family: "Metropolis";
    font-size: 17px;
    font-weight: 600;
    line-height: 21px;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .timeBubble {
    @extend %timeBubbleBase;
    & p {
      height: 21px;
      width: 100%;
      text-align: center;
      color: #90a4ae;
      font-family: "Metropolis";
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
    }
  }
  .selectedTimeBubble {
    width: 90px;
    height: 38px;
    @extend %timeBubbleBase;
    border: 3px solid #5bc5d0;
    box-shadow: 0 2px 4px 0 rgba(55, 71, 79, 0.3);
    & p {
      height: 21;
      width: 80px;
      color: #5bc5d0;
      font-family: "Metropolis";
      font-size: 15px;
      font-weight: 600;
      line-height: 21px;
    }
  }
}
.noAvailableHours {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  & a {
    margin: 15px auto;
    text-transform: none;
  }
}
.chevron {
  outline: none;
  transition: all 0.2s;
  height: 24px;
  color: #b0bec5;
  cursor: pointer;
  margin-left: 10px;
}
.rotateChevron {
  transform: rotate(180deg);
}
.hidden {
  display: none;
  animation: fadeOut 0.7s ease-out;
}
