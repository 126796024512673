@import '../../styleguide/variables';

.footerLink {
  color: #eceff1;
  font-family: 'Metropolis', sans-serif;
  font-size: 12px;
  font-weight: 400;
  height: 13px;
  line-height: 13px;
  padding: 10px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
.makePaymentsCheckBalance {
  color: $main-color;
  text-transform: capitalize;
  text-align: center;
  margin: 12px 0px;
  text-decoration: none;
}
.formHistory {
  justify-content: space-between;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 38px;
  font-weight: 500;
  font-family: 'Metropolis', sans-serif;
  text-decoration: none;
  width: 100%;
  align-items: center;
  padding: 9px 1px 10px 0px;
  color: $gray-4;
  position: relative;
  height: 32px;
  &:hover {
    color: $main-color;
  }
}
.contain {
  width: 285px;
  height: 40px;
  text-overflow: ellipsis;
  overflow: hidden;
  $white-space: nowrap;
}
.info {
  border-bottom: 1px solid lightgray;
  justify-content: space-between;
  font-size: 13px;
  display: flex;
  min-height: 45px;
  text-transform: capitalize;
  line-height: 32px;
  align-items: center;
  text-decoration: none;
  color: $gray-4;
  font-weight: 500;
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .footerLink{
    display: none;
  }
  .formHistory {
    justify-content: space-between;
    text-transform: capitalize;
    font-size: 12px;
    line-height: 38px;
    font-weight: 500;
    font-family: 'Metropolis', sans-serif;
    text-decoration: none;
    width: 100%;
    align-items: center;
    padding: 9px 1px 10px 0px;
    color: $gray-4;
    position: relative;
    height: 32px;
    &:hover {
      color: $main-color;
    }
  }
.info{
  font-size: 10px;
  display: flex;
  min-height: 45px;
  line-height: 19px;
}
}
.btnEmailUs {
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Metropolis', sans-serif;
  font-size: 13px;
  font-weight: 500;
  box-shadow: none;
  background-image: none;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  color: #fff;
  background-color: $main-color;
  &:hover {
    background-color: $main-color_darker;
  }
  max-width: 350px;
  height: 48px;
}
.profileSettingsNavLink {
  color: $gray-4;
  font-size: 19px;
  font-weight: 300;
  line-height: 23px;
  font-family: 'Metropolis', sans-serif;
  text-decoration: none;
  margin-bottom: 6px;
  padding-left: 11px;
  &[class*='active'] {
    border-left: 2px solid $main-color;
    color: $main-color;
    padding-left: 9px;
    font-weight: 500;
    text-wrap: nowrap;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
.profileSettingsNavLink{
  display: none;
}
}
.goBackButton {
  height: 50px;
  color: #5bc5d0;
  font-family: 'Metropolis', sans-serif;
  font-size: 21px;
  font-weight: 600;
  line-height: 50px;
  text-align: left;
  text-decoration: none;
}
.goBackButton:hover {
  text-decoration: underline;
}
.changeProviderVisitTypeLocation {
  height: 13px;
  color: $main-color;
  font-family: 'Metropolis', sans-serif;
  font-size: 11px;
  font-weight: 600;
  line-height: 13px;
  text-decoration: none;
  text-align: center;
}
.loginLink {
  color: $main-color;
  font-size: 11px;
  line-height: 13px;
  text-decoration: none;
}
.educationLinkButton {
  color: $main-color;
  border-color: $main-color;
  box-shadow: 1px 1px 4px rgba(31, 155, 222, 0.4);
  border: 1px solid rgba(31, 155, 222, 0.4);
  padding: 6px 18px;
  margin: 10px 0;
  cursor: pointer;
  outline: none;
  margin-left: 30%;
  border-radius: 6px;
  text-decoration: none;
  font-weight: 600;
  text-align: center;
}
.signInAsPractice {
  color: $main-color;
  font-size: 11px;
  font-weight: 400;
  align-self: center;
  margin-top: -1px;
  font-family: 'Metropolis', sans-serif;
  line-height: 13px;
  background: none;
  text-decoration: none;
  &::first-letter {
    text-transform: capitalize;
  }
  &:hover {
    text-decoration: underline;
  }
}
.guestAppointmentSuccessfulBreeze {
  display: inherit;
  text-align: center;
  margin-top: 30px;
  font-size: 14px;
  color: #fff;
  text-transform: capitalize;
  font-weight: 300;
  cursor: pointer;
  text-decoration: none;
}
.dontHaveBreezeYetLink {
  color: $white;
  position: relative;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 20px;
  padding: 5px 30px;
  top: 60px;
  display: flex;
  margin: 0px 28px;
  text-align: center;
  justify-content: space-around;
  text-decoration: none;
  font-family: 'Metropolis', sans-serif;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.4px;
}
.guestRegisterTermsAndConditionsBtn {
  color: $main-color;
  text-transform: capitalize;
  font-size: 12px;
  text-decoration: none;
  margin-left: 3px;
}
.surveysSatisfiedSocialNetworksLink {
  color: black;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  padding-top: 19px;
}

$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile){
  .goBackButton {
    font-size: 16px;
  }
}