.title {
  width: 100%;
  text-transform: uppercase;
  text-align: left;
  font-size: 10px;
  color: #546e7a;
  font-family: 'Metropolis', sans-serif;
  font-weight: 800;
  letter-spacing: 0.06em;
}
.errorMessage {
  position: relative;
  color: rgb(255, 51, 51);
  text-align: left;
  top: -10px;
  font-weight: 300;
}
.required {
  font-size: 20px;
  font-weight: 800;
  width: 100%;
  margin-left: 5px;
  color: rgb(255, 51, 51);
  text-align: left;
}
.supportingText {
  color: #a6a6a6;
}
.supportingTextGreen {
  color: #4caf50;
}
