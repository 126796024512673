.profile_settings_grid_styles {
  grid-template-columns: 2fr 5fr;
  grid-template-rows: 45px 80px 1fr;
}
.emptyContainer{
  min-height: 90vh;
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .profile_settings_grid_styles {
  display: flex;
  & form[class*='rjsf'] {
    margin-left: 3%;
    margin-bottom: 280px;
  }
  }
}

div[class*='FooterTop']{
    top: 92% !important;
    height: 74px;
}