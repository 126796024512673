.guestSuccessWrapper {
  width: 100%;
  min-height: 100vh;
  padding: 0;
  background: #e8ebed;
  & div[id="guestAppointmentSuccessfulCard"] {
    min-height: 500px;
    text-align: center;
    & div[id="guestPaymentSuccessRef"] {
      margin: 20px;
    }
    & [id*='officeName'] {
      padding-top: 0;
    }
  }
  & div[id*="guestAppointmentSuccessfulContainer"] {
    margin: 0 auto;
    height: 100% !important;
    & img[class*="guestAppointmentSuccessfulLogo"] {
      margin: 60px 0px 0px 0px;
    }
    & #guestAppointmentSuccessfulHeader {
      min-height: 200px;
    }
  }
  & div[id*="guestPaymentReceiptScreen"] {
    margin: 20px auto;
    display: flex;
    flex-direction: row;
    width: 90%;
    & div[id*="guestPaymentReceiptContainer"] {
      flex-direction: column;
      margin: 0 auto;
      width: 100%;
      & div[id*="guestPaymentReceiptTopContainer"] {
        flex-direction: row;
        padding-bottom: 70px;
        border-bottom: none;
        & img[id*="guestPaymentReceiptLogo"] {
          margin: 20px auto;
          width: 220px;
        }
        & div[id*="guestPaymentReceiptTopRightContainer"] {
          margin: 10px auto;
          & div[id*="guestPaymentReceiptReceipt"] {
            align-self: center !important;
          }
        }
      }
      & div[id*="guestPaymentReceiptCenterContainer"] {
        flex-direction: row;
        border-bottom: none;
        & div[id*="guestPaymentReceiptCenterRightContainer"] {
          width: 100%;
          & h3[id*="appointmentDate"] {
            font-size: 20px;
          }
        }
      }
      & div[id*="guestPaymentReceiptBottomContainer"] {
        flex-direction: row;
        margin: 200px auto 0px;
        border-top: 2px solid #eceff1;
        width: 75%;
        & div[id*="guestPaymentSuccessEndingIn"] {
          float: none !important;
        }
      }
    }
  }
}
