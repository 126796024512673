@import '../../styleguide/variables';

.messages {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto;
  padding: 80px 0px 40px 100px;
  min-height: calc(100vh - 126px);
  width: 1080px;
  & h1 {
    height: 38px;
    width: 139px;
    color: $gray-8;
    font-family: 'Metropolis', sans-serif;
    font-size: 32px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: -1px;
    margin: 0;
  }
  & h2 {
    height: 32px;
    color: $gray-9;
    font-family: 'Metropolis', sans-serif;
    font-size: 26px;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 32px;
    margin: 0;
  }
  & h4 {
    color: $main-color;
    font-size: 17px;
    font-weight: 400;
  }
  & h5 {
    color: $gray-5;
    font-family: 'Metropolis', sans-serif;
    font-size: 15px;
    font-weight: 300;
    line-height: 40px;
    margin: 0 auto;
    letter-spacing: 0.08px;
  }
  & h6 {
    height: 13px;
    color: $lighter_main_color;
    font-family: 'sans-serif';
    font-size: 11px;
    font-weight: 300;
    line-height: 18px;
    letter-spacing: 0.6px;
  }
  & #messagesRightPanel {
    width: 700px;
    min-height: 645px;
    display: flex;
    flex-direction: column;
    padding-top: 16px;
  }
  & [class*='firstConversationPrompt'] h1 {
    width: 326px;
    color: $gray-3;
    font-family: 'Metropolis', sans-serif;
    font-size: 26px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.25px;
    text-align: center;
    margin: 0 auto;
    text-transform: none;
  }
  & [class*='nextConversationPrompt'] h1 {
    width: 326px;
    color: $gray-3;
    font-family: 'Metropolis', sans-serif;
    font-size: 26px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.25px;
    text-align: center;
    margin: 0 auto;
    text-transform: none;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .messages {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-end;
    margin: 0 auto;
    padding: 0px;
    min-height: auto;
    width: 100%; 
  }
}

.messagesTitle {
  height: 38px;
  width: 139px;
  color: $gray-8;
  font-family: 'Metropolis', sans-serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: -1px;
  margin: 0;
}

.messagesBody {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding-bottom: 82px;
}
.modalcontent{
  backdrop-filter: blur(5px); 
  background-color: rgba(0, 0, 0, 0.7); 
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000; 
  display: flex;
  justify-content: center;
  align-items: center;
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
.messagesBody {
  display: flex;
  width: 96%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 5%;
}
}
.toggleButton {
  & #newMessageBtn {
    display: none;
  }
  & #newMessageCancelBtn {
    display: block;
  }
}

.ProviderChatOpenBtn{
    color: #fff;
    height: 38px;
    line-height: 38px;
    width: 163px;
    border: 1px solid #5bc5d0;
    background-color: #5bc5d0;
    border-radius: 6px;
    font-weight: 400;
    text-transform: uppercase;
    font-family: "Metropolis", sans-serif;
    box-shadow: none;
    font-size: 13px;
    &:hover {
      background-color: #4cc0cc;
    } 
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .ProviderChatOpenBtn{
   width: 46%;
   margin-top: -11%;
   margin-left: 3%;
  }
}