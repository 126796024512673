.form {
  & fieldset {
    border: 0 !important;
  }
}

.wrapper {
  font-weight: 300 !important;
  & div[class*="selectWrapper"] {
    overflow: visible;
  }
  & fieldset {
    border: none;
    min-width: unset;
    & > div[class*="form-group"] {
      margin-bottom: 37px;
    }
  }
  & legend {
    display: none;
  }
  & div[class*="has-error"] {
    & > div:nth-of-type(2) p {
      display: none;
    }
  }
  & ul[class*="error-detail"] {
    list-style-type: none;
    padding-left: 0px;
    margin-top: 5px;
    & li {
      text-transform: capitalize;
      color: #e22b42;
      font-size: 14px;
      line-height: 1.2em;
      font-family: "Metropolis", sans-serif;
    }
  }
  & div[class*="checkBoxContainer"] {
    margin-bottom: 10px;
  }
  & div[class*="labelContainer"] {
    white-space: pre-wrap;
    text-transform: none;
    line-height: 1.2;
  }
}

.leftSideWrapper {
  & > div[class*="providerInfoWrapper"] {
    height: fit-content;
  }
  flex: 1;
}

.title {
  font-family: "Metropolis", sans-serif;
  font-weight: 500;
  font-size: 29px;
  line-height: 35px;
  letter-spacing: -1px;
  color: #455a64;
  margin: 20px 0;
  text-align: center;
}

.contentWrapper {
  display: flex;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  flex: 2;
  justify-content: center;
  word-wrap: break-word;
  & ul[class*="error-detail"] {
    display: none;
  }
}

.description {
  text-align: center;
  font-family: "Metropolis", sans-serif;
  font-weight: normal;
  font-size: 19px;
  line-height: 23px;
  color: #78909c;
}

%button {
  margin-left: 78px;
  text-transform: uppercase;
  font-family: "Metropolis", sans-serif;
  font-size: 13px;
  font-weight: 500;
  box-shadow: none;
  background-image: none;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  color: #fff;
  background-color: #5bc5d0;
  &:hover {
    background-color: #43bdc9;
  }
  height: 38px;
  width: 147px;
  margin-top: 2rem;
  position: sticky;
  top: 226px;
}
.submitButton {
  @extend %button;
}
.submitButtonBottom {
  @extend %button;
  align-self: center;
  margin-left: 0px;
}
.disabled {
  color: white !important;
  border: 1px solid #cfd8dc;
  border-color: #cfd8dc;
  box-shadow: none;
  cursor: not-allowed;
  background-color: #cfd8dc !important;
}
.delegateForgotPassword {
  & [class*="title"] {
    color: #546e7a;
    font-size: 14px;
    margin-top: 5px;
    font-weight: 500;
    letter-spacing: 0.5px;
    justify-content: center;
  }
  & [class*="description"] {
    color: #90a4ae;
    padding: 0 70px;
    font-size: 15px;
    font-weight: 300;
    line-height: 21px;
    letter-spacing: 0.5px;
    justify-content: center;
  }
}
.errorMessage {
  color: red;
  position: absolute;
  margin-top: 62px;
  margin-left: 12px;
}
