.wrapper {
  display: flex;
  flex-direction: column;
  height: 240px;
  width: 430px;
  border: 1px solid #cfd8dc;
  border-radius: 10px;
  overflow-y: hidden;
  margin-right: 1rem;
  --webkit--box-shadow: 0;
  --moz-boxS-shadow: 0;
  box-shadow: 0;
  margin-bottom: 1rem;
  transition: all 0.2s;
  &:hover {
    --webkit-box-shadow: 0px 0px 26px 3px rgba(0, 0, 0, 0.2);
    --mozbox-shadow: 0px 0px 26px 3px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 26px 3px rgba(0, 0, 0, 0.2);
  }
}
.address {
  display: flex;
  flex-direction: column;
  padding-left: calc(1.5rem + 58px);
  padding-top: 16px;
  & p {
    height: 17px;
    color: #546e7a;
    font-size: 12px;
    margin-top: 0;
    margin-bottom: 0;
  }
  & > p:first-child {
    font-weight: bold;
  }
}
.actionsWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.actions {
  text-align: right;
  padding-top: 0.5rem;
  height: 20px;
  padding-right: 0.5rem;
  width: 50px;
  display: flex;
  justify-content: space-between;
  & a {
    transition: color 0.2s;
    height: 20px;
    width: 19px;
    color: #b0bec5;
    font-size: 20px;
    cursor: pointer;
    &:hover {
      color: #5bc5d0;
    }
  }
}
.indicator {
  height: 6px;
  width: 430px;
  background-color: #5bc5d0;
  border-radius: 10px;
}
.divider {
  height: 1px;
  width: 430px;
  background-color: #cfd8dc;
}
.providerSection {
  display: flex;
  padding-left: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.providerInfo {
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;
  padding-top: 5px;
  & p {
    height: 17px;
    color: #78909c;
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 0;
  }
  & h6 {
    color: #37474f;
    font-size: 20px;
    height: 24px;
    font-weight: 600;
  }
}
.lastApptWrapper {
  & h6 {
    flex: 1;
    height: 21px;
    color: #cfd8dc;
    font-size: 14px;
    font-weight: 600;
    text-align: right;
    text-transform: capitalize;
  }
  align-items: flex-end;
  display: flex;
  flex: 1;
  padding-bottom: 15px;
  padding-right: 1rem;
}
