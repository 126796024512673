@import '../variables';

.container {
  position: relative;
}

.input {
  &[disabled] {
    color: $gray-4;
  }
  &::placeholder {
    color: $gray-4;
  }
  background-color: transparent;
  border: 1px solid $gray-2;
  border-radius: 4px;
  box-shadow: none;
  box-sizing: border-box;
  color: #5bc5d0;
  display: block;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  width: 100%;
  padding: $spacing-base 60px $spacing-base $spacing-base;
}

.actions {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;

  svg {
    cursor: pointer;
    font-size: 18px;
    margin-right: $spacing-base;
  }
}
