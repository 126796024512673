.emptyWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  & p {
    color: #90A4AE;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    margin: 0 0 16px;
    text-align: center;
  }
}
.required-label {
  content: "*";
  color: red; /* Change the color to your desired color */
}