@import '../../styleguide/variables';

.wrapper {
  font-weight: 300 !important;
  & div[class*='selectWrapper'] {
    overflow: visible;
  }
  & fieldset {
    border: none;
    min-width: unset;
    & > div[class*='form-group'] {
      margin-bottom: 37px;
    }
  }
  & legend {
    display: none;
  }
  & div[class*='has-error'] {
    & > div:nth-of-type(2) p {
      display: none;
    }
  }
  & ul[class*='error-detail'] {
    display: none;
  }
  & div[class*='checkBoxContainer'] {
    margin-bottom: 10px;
  }
  & div[class*='labelContainer'] {
    white-space: pre-wrap;
    text-transform: none;
    line-height: 1.2;
  }
}
.leftSideWrapper {
  & > div[class*='providerInfoWrapper'] {
    height: fit-content;
  }
  & button[class*='submitButton'] {
    top:366px;
    margin: 1rem 0 0 4.6rem;
  }
  flex: 1;
}
.title {
  font-family: 'Metropolis', sans-serif;
  font-weight: 500;
  font-size: 29px;
  line-height: 35px;
  letter-spacing: -1px;
  color: $gray-9;
  margin: 20px 0;
  text-align: center;
}
.contentWrapper {
  display: flex;
}
.formWrapper {
  display: flex;
  flex-direction: column;
  flex: 2;
  justify-content: center;
  max-width: 77%;
  word-wrap: break-word;
  :global(form[class*='rjsf'] [class*='Select--single']) {
    width: 100%;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .title {
    font-family: 'Metropolis', sans-serif;
    font-weight: 500;
    font-size: 25px;
    line-height: 35px;
    letter-spacing: -1px;
    color: $gray-9;
    margin: 20px 0;
    text-align: center;
  }
  .contentWrapper {
    display: flex;
    flex-wrap: wrap;
    margin-left: 5%;
  }
  .formWrapper {
    display: flex;
    flex-direction: column;
    flex: 2;
    justify-content: center;
    max-width: 96%;
    word-wrap: break-word;
    margin-top: 14%;
    :global(form[class*='rjsf'] [class*='Select--single']) {
      width: 100%;
    }
  }
}
.label {
  width: 100%;
  text-transform: uppercase;
  text-align: left;
  font-size: 10px;
  color: $gray-7;
  font-family: 'Metropolis', sans-serif;
  font-weight: 800;
  letter-spacing: 0.06em;
}
.description {
  text-align: center;
  font-family: 'Metropolis', sans-serif;
  font-weight: normal;
  font-size: 19px;
  line-height: 23px;
  color: $gray-5;
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .description {
    text-align: center;
    font-family: 'Metropolis', sans-serif;
    font-weight: normal;
    font-size: 15px;
    line-height: 23px;
    color: $gray-5;
  }
  .submitButtonDiv {
    bottom: 0;
    width: 100%;
    position: sticky;
    background-color: #ffffff;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .submitButton {
    bottom: 10px;
    width: 100% !important;
    margin-top: 0 !important;
  }
}
.submitButton {
  text-transform: uppercase;
  font-family: 'Metropolis', sans-serif;
  font-size: 13px;
  font-weight: 500;
  box-shadow: none;
  background-image: none;
  border-radius: 6px;
  border: none;
  --webkit-font-smoothing: auto;
  cursor: pointer;
  color: $white;
  background-color: $main-color;
  &:hover {
    background-color: $main-color;
  }
  height: 38px;
  width: 147px;
  margin-top: 2rem;
  position: sticky;
  top: 440px;
}
.dangerouslySetInnerHTML {
  font-family: 'Metropolis', sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  color: $gray-8;
  margin: 0;
  margin-bottom: 20px;
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .dangerouslySetInnerHTML {
    font-family: 'Metropolis', sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 25px;
    color: $gray-8;
    margin: 0;
    margin-bottom: 20px;
  }
}
.supportingText {
  color: $gray-11;
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .leftSideWrapper {
    & > div[class*='providerInfoWrapper'] {
      display: none;
    }
    flex: 1;
    position: fixed;
    background: white;
    width: 100%;
    margin-top: -2px;
    &  button[id*='nextStepBtn'] {
      margin-top: 1rem !important;
      margin-left: 6rem;
    }
  }
}
