@import '../../styleguide/variables';

.makePaymentsCheck {
  position: absolute;
  top: 20px;
  right: 133px;
  height: 72px;
}
.successfulPaymentCheckmark {
  height: 45px;
  width: 56px;
  margin-top: 40px;
}
.successConnectCheckmark {
  margin-top: 20px;
  margin-bottom: 20px;
}
.noProviderMessageBubble {
  margin-top: 140px;
  margin-bottom: 10px;
}
.successfulApptRequestCheckmark {
  height: 45px;
  width: 56px;
  margin-top: 40px;
}
.checkInOverlayQRCode {
  align-self: center;
  max-width: 51%;
  margin-bottom: 27px;
}
.checkInOverlayQRCodeiOS {
  @extend .checkInOverlayQRCode;
}
.checkInOverlayQRCodeAndroid {
  @extend .checkInOverlayQRCode;
  display: none;
}
.paymentsClockIcon {
  position: absolute;
  bottom: 64px;
  right: 120px;
}
.patientPortalImage {
  margin-right: 12px;
  align-self: start;
}
.paymentsModalAvatar {
  position: absolute;
  top: -30px;
}
.monthlyModalAvatar {
  @extend .paymentsModalAvatar;
  transform: translateX(-55px);
}
.makePaymentsAvatar {
  position: relative;
  top: -14px;
  z-index: -1;
  margin: 0px !important;
}
.avatar {
  width: 58px;
  height: 58px;
  border-radius: 50%;
  text-align: center;
}
.profileSettingsAvatar {
  position: unset;
  height: 86px !important;
  width: 86px !important;
  margin-bottom: 16;
  background-color: $gray-8;
  & p {
    padding-top: 27px;
    font-size: 30px;
    font-weight: 500;
    line-height: 36px;
  }
}
.avatarImage {
  background-color: transparent;
}
.navAvatar {
  width: 35px !important;
  height: 35px !important;
  margin-left: 0 !important;
  cursor: pointer;
  align-self: center;
  font-size: 12px;
  transition: background-color 0.4s;
  & p {
    padding-top: 7px;
  }
  &:hover {
    background-color: $main-color;
  }
}
.appointmentPaymentAvatar {
  @extend .makePaymentsAvatar;
}
.previewImage {
  height: 60px;
  width: 100px;
  border: 1px solid $main-color;
  border-radius: 5px;
  box-shadow: 0 1px 2px 0 $gray-2;
  margin-right: 1rem;
}
.icon {
  height: 30px;
  margin-right: 7px;
}
.spinnerLoader {
  height: 50px;
  width: 50px;
  animation: spin 8s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.breezeErrorLogo {
  width: 236px;
  height: 58px;
  margin-bottom: 78px;
}

.breezeErrorFooterLogo {
  width: 70px;
  height: 16px;
  margin-bottom: 30px;
}
.guestAppointmentSuccessfulLogo {
  margin: 60px 0px 30px 0px;
}
.guestAppointmentSuccessfulCheck {
  margin: 25px auto;
  width: 70px;
}
.guestGetStartedHeaderImage {
  width: 180px;
  margin: 0 auto;
}
.guestGetStartedMain {
  margin: -60px auto 0px;
  max-width: 100%;
  display: block;
  width: 562px;
}
.surveyFeedbackCheck {
  width: 80px;
  margin: 0 auto 20px;
}
.guestPaymentReceiptLogo {
  width: 250px;
}
.guestNoPendingBalance {
  margin-bottom: 20px;
}
.mainBrandLogo {
  margin: 40px auto;
}
.cashPayModalHeader {
  margin-bottom: 20px;
  margin-left: 105px;
  width: 25%;
}
.delegateScreenLogo {
  margin: 0 auto;
}
.mainLogo {
  padding: 50px 0px;
}
.careTeamProviderImage{
  width: 48px !important;
  height: 48px !important;
}
.historyAppointmentImage{
  width: 32px !important;
  height: 32px !important;
  margin: 0 !important;
}
.noNextAppointment{
  width: 60px;
  height: 60px;
}