.required {
  color: #E22B41;
  font-size: 12px;
  font-weight: 800;
  line-height: 14px;
}

.root {
  color: #546E7A;
  display: block;
  font-size: 12px;
  font-weight: 800;
  letter-spacing: 0.6px;
  line-height: 14px;
  text-transform: uppercase;
}
