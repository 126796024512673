$main-color: #5bc5d0;
$main-color_darker: darken(#5bc5d0, 10%);
$main-color_lighter: lighten(#5bc5d0, 10%);
$lighter_main_color: #9fe5ed;
$white: #ffffff;
$gray-0: #f9fafa;
$gray-1: #eceff1;
$gray-2: #cfd8dc;
$gray-3: #b0bec5;
$gray-4: #90a4ae;
$gray-5: #78909c;
$gray-6: #607d8b;
$gray-7: #546e7a;
$gray-8: #455a64;
$gray-9: #37474f;
$gray-10: #263238;
$gray-11: #666;

$modal_backdrop: rgba(38,50,56,0.93);
$box_shadow_nav: rgba(0, 0, 0, 0.3);
$box_shadow_gray: rgba(55, 71, 79, 0.3);

$light_gray_4: #dddddd;
$light_gray_5: #d7d7d9;
$highlight: #5bd064;
$destructive: #e22b41;
$lighter_destructive: lighten($destructive, 20%);
$alert: #f5a623;
$black: #000;
$lighter_black: lighten($black, 20%);

$Metropolis: 'Metropolis', sans-serif;

$spacing-thin: 4px;
$spacing-base: 8px;
$spacing-bold: 16px;