@import '../../styleguide/variables';

.paymentPlan {
  align-items: center;
  height: 70px;
  background-color: $gray-8 !important;
  & input::-webkit-input-placeholder {
    color: $gray-4 !important;
    text-align: right;
    font-size: 20px !important;
    font-weight: 400 !important;
    font-family: 'gotham-rounded';
  }
  padding: 9px 15px 10px 9px;
  & input {
    color: $lighter_main_color !important;
    font-family: 'gotham-rounded';
  }
  & div {
    min-width: 164px;
    & h4 {
      letter-spacing: 0.1px;
      font-size: 13px;
      font-weight: 600;
    }
  }
}
.question {
  min-width: 176px;
  & h4 {
    margin: 15px 0px 10px;
    font-size: 12px;
    line-height: normal;
    font-weight: 400 !important;
    color: $white;
    font-family: 'Metropolis';
  }
}
.error {
  box-sizing: border-box;
  background-color: $lighter_destructive;
  height: 70px;
  font-size: 12.4px;
  width: 340px;
  border-radius: 6px;
  padding: 22px 15px 10px 15px;
  text-align: center;
  margin-top: 10px;
  color: $white;
}
