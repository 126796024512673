@import '../../styleguide/variables';

.avatarEditor {
  & [class*='rangeslider-horizontal'],
  [class*='rangeslider__handle'] {
    width: 14px;
    height: 14px;
    border-radius: 10px;
    background-color: $main-color;
    border: none;
  }
  & [class*='rangeslider-horizontal'] {
    height: 2px;
    width: 337px;
  }
  & [class*='rangeslider-horizontal'],
  [class*='rangeslider__handle']:after {
    content: unset;
  }
  & [class*='rangeslider-horizontal'],
  [class*='rangeslider__fill'] {
    background-color: $light_gray_5;
  }
  & [class*='rangeslider__handle'] {
    box-shadow: none;
  }
  & canvas {
    border-radius: 10px;
  }
  & h4 {
    color: $gray-4;
    font-family: 'Metropolis';
    font-size: 15px;
    line-height: 21px;
    text-align: center;
    width: 337px;
    margin-top: 7px;
    margin-bottom: 18px;
  }
}
.buttons {
  display: flex;
  width: 337px;
  justify-content: space-between;
}
.savePhoto {
  display: flex;
  width: 160px;
  justify-content: center;
  align-items: center;
  height: 38px;
  text-transform: uppercase;
  font-family: 'Metropolis', sans-serif;
  font-size: 13px;
  font-weight: 500;
  box-shadow: none;
  background-image: none;
  border-radius: 6px;
  --webkit-font-smoothing: auto;
  cursor: pointer;
  margin: 10px 0;
  color: $white;
  background-color: $main-color;
  &:hover {
    background-color: $main-color_darker;
    border-color: $main-color_darker;
  }
  border: 1px solid $main-color;
}
.input {
  display: flex;
  width: 160px;
  justify-content: center;
  align-items: center;
  height: 38px;
  text-transform: uppercase;
  font-family: 'Metropolis', sans-serif;
  font-size: 13px;
  font-weight: 500;
  background-image: none;
  border-radius: 6px;
  --webkit-font-smoothing: auto;
  cursor: pointer;
  color: $main-color;
  border-color: $main-color;
  box-shadow: 1px 1px 4px rgba(31, 155, 222, 0.4);
  border: 1px solid rgba(31, 155, 222, 0.4);
  background-color: $white;
  margin: 10px 0;
  outline: none;
}
.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  &button:hover {
    background-color: $main-color;
    border-color: $main-color;
  }
  & [class*='rangeslider'] {
    pointer-events: none;
  }
}
.noImage {
  background-color: $gray-9;
  width: 337px;
  height: 335px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  & div {
    background-color: $gray-8;
    width: 277px;
    height: 255px;
    border-radius: 50%;
    align-self: center;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 78px;
    font-weight: 500;
    line-height: 94px;
    color: $white;
    font-family: 'Metropolis';
  }
}
