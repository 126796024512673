html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Metropolis', sans-serif;
  font-size: 13px;
  line-height: 1.846;
  min-height: 100%;
}
body.landscape {
  width: 100vw;
  height: 100vw;
  transform-origin: 0 0;
}

body.rotation-90 {
  /* transform: rotate(90deg) translateY(-100%); */
}
body.rotation90 {
  /* transform: rotate(-90deg) translateX(-100%); */
}

body > #root {
  min-height: 100%;
}
button {
  background-color: transparent;
  border-style: unset;
}
textarea:focus,
input:focus {
  outline: none;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #5bc5d0;
  -webkit-box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 0.041) inset;
  transition: background-color 5000s ease-in-out 0s;
}
#productBrowser {
  width: 1080px;
  margin: 50px auto 0px !important;
  min-height: calc(100vh - 166px) !important;
  padding: 100px 0 0;
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  z-index: -1;
}
html#ecwid_html body#ecwid_body .ec-size .ec-store .form-control--primary .form-control__button {
  background-color: #5bc5d0;
  color: #000;
}

#productBrowser input[type='radio']:checked:before {
  content: '';
  display: block;
  border-radius: 50%;
  transition: 240ms;
  z-index: 1;
  width: 5px;
  height: 5px;
  cursor: pointer;
  background-color: #ffffff;
  border: 5px solid #5bc5d0;
  box-sizing: content-box;
  position: relative;
  left: -1.8px;
  top: 0px;
}
.gwt-RadioButton input[type='radio'] {
  cursor: pointer !important;
}
#productBrowser .form-control__button,
.ecwid-btn--primary {
  background-color: #5bc5d0 !important;
  color: #fff !important;
}
#productBrowser .product-details__product-title,
.page-title__name {
  font-family: 'Metropolis', sans-serif !important;
  font-weight: 500 !important;
  font-size: 32px !important;
  color: #455a64 !important;
}
div.ecwid-minicart-clickArea {
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='49' viewBox='0 0 40 49'%3E%3Cpath fill='%235bc5d0' d='M37 13v27c0 3.31-2.69 6-6 6H9c-3.31 0-6-2.69-6-6V13h34m2-2H1v29c0 4.418 3.582 8 8 8h22c4.418 0 8-3.582 8-8V11z'/%3E%3Ccircle fill='%235bc5d0' cx='12' cy='17' r='2'/%3E%3Ccircle fill='%235bc5d0' cx='28' cy='17' r='2'/%3E%3Cpath fill='%235bc5d0' d='M29 17h-2v-7c0-3.86-3.14-7-7-7s-7 3.14-7 7v7h-2v-7c0-4.962 4.037-9 9-9s9 4.038 9 9v7z'/%3E%3C/svg%3E")
    no-repeat !important;
}
div.ecwid-minicart-counter,
div.ecwid-minicart-link *,
.product-details-module__btn-more.ec-link {
  color: #5bc5d0 !important;
}
div.ecwid-minicart-caption {
  font-weight: 300 !important;
  color: #666 !important;
}
div.ecwid-productBrowser-cart-totalAmount {
  font-size: 20px !important;
  color: #666 !important;
}
#productBrowser div.ecwid-productBrowser-head {
  color: #666 !important;
}
#productBrowser div.ecwid-productBrowser-productNameLink a {
  color: #666 !important;
}
#productBrowser td div {
  color: #666 !important;
}
#productBrowser input[type='number'] {
  color: #666 !important;
}
#productBrowser .ecwid-btn.ecwid-btn--secondary {
  color: #666 !important;
}
#productBrowser .ecwid-ProductBrowser-auth-anonim a {
  color: #5bc5d0 !important;
}
.ecwid-btn.ecwid-btn--secondary:hover {
  background-color: #5bc5d0 !important;
}
.ecwid-btn.ecwid-btn--secondary.ecwid-btn--secondary-inverse {
  background-color: #5bc5d0 !important;
  color: #fff !important;
}
.grid-product__price-amount {
  color: #666 !important;
}
.grid-product__title-inner {
  color: #5bc5d0 !important;
  font-family: 'Metropolis', sans-serif !important;
  font-size: 18px !important;
  font-weight: 300 !important;
  line-height: 23px !important;
}
.gwt-Anchor.ecwid-productBrowser-auth-accountLink,
.gwt-TabBarItem-selected .ecwid-categories-category,
.grid-product__wrap:hover .grid-product__title-inner {
  color: #5bc5d0 !important;
}
.ecwid-categories-category {
  color: #666 !important;
}
html#ecwid_html body#ecwid_body .ecwid .ecwid-btn--secondary:focus,
html#ecwid_html body#ecwid_body .ecwid .ecwid-btn--secondary:focus:hover {
  border-color: #5bc5d0 !important;
}
#productBrowser .grid-product__image-wrap,
#productBrowser .grid-product__bg,
#productBrowser .grid-product__shadow {
  border-radius: 16px !important;
}
.ec-footer,
.ec-footer li,
.ec-grid {
  padding: 0 !important;
}
.ec-link:hover {
  color: #5bc5d0 !important;
}
.details-gallery__images.details-gallery__images--aspect-ratio-auto {
  border-radius: 26px !important;
}
.details-gallery__thumb-img::after {
  box-shadow: inset 0 0 0 2px #5bc5d0 !important;
  border-radius: 10px;
}
.details-gallery__thumb-img {
  border-radius: 10px;
}
#productBrowser button {
  border-radius: 6px !important;
  text-transform: uppercase !important;
}
.details-product-price__value,
.product-details-module__title.details-product-purchase__place span,
.product-details-module__title,
#productDescription p {
  color: #666 !important;
}
#productBrowser.animate {
  animation: fadeIn 0.5s forwards;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
div.ecwid-ProductBrowser-auth-logged {
  margin-right: 0 !important;
}
.ecwid-DateRangePopup-PresetsPanel .gwt-Anchor {
  color: #5bc5d0 !important;
}
div.datePickerDayIsToday,
.datePickerDay[aria-selected='false']:hover {
  background-color: #5bc5d0 !important;
}
.ecwid-favorite-menu-item {
  color: #5bc5d0 !important;
}

[class*='scrollableModalContent']::-webkit-scrollbar {
  width: 6px;
}
[class*='scrollableModalContent']::-webkit-scrollbar-track {
  display: none;
}
[class*='scrollableModalContent']::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 15px;
}
[class*='dataWrapper']::-webkit-scrollbar {
  width: 6px;
}
[class*='dataWrapper']::-webkit-scrollbar-track {
  display: none;
}
[class*='dataWrapper']::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 15px;
}
#tooltip {
  position: absolute;
  display: none;
  bottom : 110%;
  padding: 10px;
  background-color: #333;
  border-radius: 3px;
  font-size: 10px;
  color: #eee;
}
#tooltip:after {
  position: absolute;
  content: "";
  top: 100%;
  left: 10%;
  border-top: 5px solid #333;
  border-right: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid transparent;
}
#telehealthBtn:hover > #tooltip {
  display: block;
}