@import '../../styleguide/variables';

.wrapper {
  height: 230px;
  display: flex;
  flex-wrap: wrap;
  & div {
    box-sizing: border-box;
  }
}
.historyWrapper {
  height: 271px;
  position: relative;
}
.container {
  width: 340px;
  overflow: hidden;
  box-shadow: 0 2px 4px 0 $box_shadow_gray;
  border-radius: 10px;
  margin: 0 auto;
  text-align: center;
  cursor: pointer;
  margin-bottom: 10px;
  margin-top: 24px;
  margin-right: 20px;
  transition: all 0.2s;
  outline: 0;
  &:hover {
    --webkit-box-shadow: 0px 0px 26px 3px $lighter_black;
    --moz-box-shadow: 0px 0px 26px 3px $lighter_black;
    box-shadow: 0px 0px 26px 3px $lighter_black;
    transition: all 0.2s;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .wrapper {
    margin-left: 18%;
    height: 230px;
    display: flex;
    flex-wrap: wrap;
    & div {
      box-sizing: border-box;
    }
  }
}
.upper {
  background-color: $main-color;
  height: 50%;
  padding-top: 17px;
}
.lower {
  padding-top: 15px;
  position: relative;
}
.perMonth {
  font-weight: 400;
  font-size: 20px;
}
.date {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 41px;
  border-top: 1px solid $gray-1;
  font-weight: 500;
  color: $gray-3;
  margin: 24px 10px 0px 10px;
  text-transform: capitalize;
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .date {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 41px;
    border-top: 1px solid $gray-1;
    font-weight: 500;
    color: $gray-3;
    margin: 24px 10px 24px 10px;
    text-transform: capitalize;
  }
}
.monthly {
  background-color: $gray-8;
}
.payment {
  color: $white;
  font-weight: 500;
  font-family: 'Metropolis';
}
.message {
  color: $main-color;
  font-size: 20px;
  font-weight: 500;
  line-height: 21px;
  margin-top: 36px;
  padding: 0 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.paymentPlanSubtitle {
  margin: 0px;
  height: 0px;
  color: $gray-6;
  font-size: 12px;
  text-align: center;
}
.processing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 3px;
  top: 3px;
  right: 40px;
  position: absolute;
  border-radius: 4px 4px 0 0;
  border: 1px solid $gray-3;
  border-bottom: none;
  height: 21px;
  width: 76px;
  text-align: center;
  color: $gray-4;
  text-transform: uppercase;
  font-size: 9px;
}
.avatarContainer {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -30px;
}
.avatarShift {
  transform: translateX(-115%);
}
