@import '../../styleguide/variables';

.selectedProvider {
  padding-top: 14px;
  padding-left: 15px;
  text-align: center;
  & [class*='providerCard'] {
    width: 338px;
    margin: 0;
    display: flex;
    pointer-events: none;
    & [class*='providerInfo'] {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  & h3 {
    height: 20px;
    width: 342px;
    margin-top: 21px;
    color: $gray-2;
    font-family: 'Metropolis', sans-serif;
    font-size: 16px;
    line-height: 20px;
    font-weight: 200;
  }
}

.messagesSidePanel {
  min-width: 370px;
  padding: 2px;
  margin-right: 6px;
  height: 584px;
  & div[id*='providerMessageList'] {
    overflow-x: hidden;
    overflow-y: auto;
    height: 570px;
    width: 350px;
    padding: 14px;
    padding-right: 8px;
  }
  & h5 {
    line-height: 20px !important;
  }
  & [class*='firstLabel'] {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }
  & [class*='secondLabel'] {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }
  & [class*='providerCard'][role*='button'] {
    opacity: 0.7;
    margin: 0px 1px 14px;
    width: 338px;
    height: 80px;
  }
  & [role*='button'][class*='showMessages'] {
    opacity: 1;
    border: 1px solid $main-color_lighter;
    box-shadow: 0px 0px 26px 3px rgba(0, 0, 0, 0.15);
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .messagesSidePanel {
    min-width: 100%;
    height: 605px;
  & div[id*='providerMessageList'] {
    overflow-x: hidden;
    overflow-y: auto;
    height: 605px;        
    width: 93%;
  }
  & [class*='providerCard'][role*='button'] {
    opacity: 0.7;
    width: 100%;
    height: 80px;
  }
  & [role*='button'][class*='showMessages'] {
    opacity: 1;
    border: 3px solid $main-color_lighter;
    box-shadow: 0px 0px 26px 3px rgba(0, 0, 0, 0.15);
  }
  }
}

.short {
  height: 488px;
}

.loaderWrapper {
  height: 600px;
  width: 372px;
  display: grid;
  align-items: center;
  position: absolute;
  z-index: 999;
}

.hidden {
  display: none;
}

.fadedMessageList {
  opacity: 0.5;
}
