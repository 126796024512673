.radioInput {
  cursor: pointer;
  margin-left: 8px;
  &[disabled] {
    cursor:not-allowed;
  };
}
.radioContainer {
  cursor: pointer;
  & input[type='radio']:checked:before {
    z-index: 1;
    background-color: #ffffff;
    right: 6px;
    border: 8px solid #5bc5d0;
    box-sizing: content-box;
    content: '';
    display: block;
    top: 14px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    position: absolute;
  }
  & input[type='radio']:checked:disabled:before{
    z-index: 1;
    background-color: #ffffff;
    right: 6px;
    border: 8px solid #c0c7ca;
    box-sizing: content-box;
    content: '';
    display: block;
    top: 14px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    position: absolute;
  }
  & input[type='radio']:not(:checked):after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    left: -3px;
    top: -3px;
    position: relative;
    background-color: #fff;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #c9d2d7;
  }
  & input[type='radio']:checked + div {
    color: #51bdca;
  }
  & input[type='radio']:checked + div div h6 {
    color: #607d8b;
  }
  & input[type='radio']:checked + div svg > g > g {
    fill: #51bdca;
  }
  & input[type='radio']:checked + div svg g > g#PayPal path:first-of-type {
    fill: #51bdca;
  }
  & input[type='radio']:checked + div svg g > g#PayPal path:nth-of-type(3) {
    fill: #36a5b2;
  }
  & i {
    height: 32px;
    width: 32px;
  }
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  font-size: 15px;
  color: #597583;
  font-weight: 500;
  font-family: 'Metropolis';
  align-items: center;
  padding: 8px 8px 10px 0px;
  position: relative;
  height: 32px;
}
.radio {
  position: relative;
}
.icon {
  height: 30px;
  margin-right: 7px;
}
.fullWidthLabelContainer {
  & div {
    text-overflow: ellipsis;
    overflow: hidden;
  }
  display: flex;
  flex: auto;
  width: 286px;
  white-space: nowrap;
  align-items: center;
  & i {
    font-size: 32px;
    margin-right: 8px;
  }
  & img {
    height: 24px;
    width: 24px;
    margin-right: 10px;
  }
}
.labelContainer {
  & div {
    text-overflow: ellipsis;
    overflow: hidden;
  }
  display: flex;
  flex: auto;
  width: 201px;
  white-space: nowrap;
  align-items: center;
  & i {
    font-size: 32px;
    margin-right: 8px;
  }
  & img {
    height: 24px;
    width: 24px;
    margin-right: 10px;
  }
}
svg {
  height: 30px;
  margin-right: 7px;
  display: flex;
  align-items: center;
}
.addAppointmentRadio {
  width: 326px;
  margin-left: 3px;
}
.addAppointmentLocationRadio {
  @extend .addAppointmentRadio;
}
.prePayment {
  height: 18px;
  flex-shrink: 0;
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid #5bc5d0;
  border-radius: 4px;
  font-size: 9px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  color: #5bc5d0;
  margin-left: auto;
  padding-top: 1px;
}
.radioButtons {
  opacity: 1;
}
.disabled {
  font-style: italic;
  color: #ddd;
  pointer-events: none;
}
.defaultLabel {
  font-size: 11px;
  font-weight: 600;
  font-family: 'Metropolis';
  color: #90a4ae;
  background-color: #e7ecee;
  border-radius: 15px;
  padding: 0px 8px;
  letter-spacing: 0.4px;
  margin-right: 5px;
}
.disabledLabel {
  font-size: 11px;
  font-weight: 600;
  font-family: 'Metropolis';
  color: #e22b41;
  background-color: rgba(226, 43, 65, 0.2);
  border-radius: 15px;
  padding: 0px 8px;
  letter-spacing: 0.4px;
}
.addAppointmentDisabled {
  opacity: 0.2;
  transition: all 0.2s;
}
.cash {
  & img {
    width: 32px;
    height: 32px;
  }
}
.descriptionLines {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  color: #597583;
}
.descriptionRadio {
  height: auto;
  & input[type='radio']:checked:before {
    top: 41%;
  }
  & [class*='labelContainer'] {
    max-width: unset !important;
  }
  & [class*='fullWidthLabelContainer'] {
    max-width: unset !important;
  }
}
.descriptionRadioExtended {
  & input[type='radio']:checked:before {
    top: 52.5px;
  }
}
.firstLabelDescription {
  color: #5bc5d0;
  font-size: 17px;
}
.hidden {
  display: none;
}

