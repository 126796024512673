.oopsWrapper {
  max-width: 600px;
  margin-top: 100px;
  & div {
    float: right;
  }
  & div [id="oopsContainer"] {
    float: right;
    & img [id="oopsImage"] {
      width: 100px;
    }
  }
  & h2 {
    margin: 10px auto;
    font-family: "gotham-rounded";
    font-size: 24px;
    font-weight: 500;
    color: #455a64;
    text-align: center;
  }
  & h5 {
    margin: 0px auto;
    color: #78909c;
    font-family: "Metropolis";
    font-size: 14.25px;
    font-weight: 300;
    text-align: center;
  }
  & img {
    width: 360px;
    margin: 0px auto 30px;
  }
}
