.delegateCardWrapper {
  height: 309px;
  width: 250px;
  border-radius: 14px;
  box-shadow: 0 2px 4px 0 rgba(55, 71, 79, 0.3);
  text-align: center;
  background-color: #ffff;
}
.hasNoPractices {
  background-color: #eceff1;
}
.delegateCardTop {
  height: 100px;
  background-color: #eceff1;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
  & [id*="delegateTop"] {
    /* autoprefixer: ignore next */
    grid-gap: 3px;
  }
  & [id*="delegateName"] {
    padding: 5px;
    height: 30px;
    color: #5bc5d0;
    font-family: "Metropolis";
    font-size: 25px;
    font-weight: 600;
    line-height: 30px;
    margin: 0 auto;
    text-overflow: ellipsis;
    max-width: 230px;
    white-space: nowrap;
    overflow: hidden;
  }
  & [id*="delegateDob"] {
    height: 15px;
    width: 107px;
    color: #607d8b;
    font-family: "Metropolis";
    font-size: 15px;
    font-weight: bold;
    line-height: 18px;
    margin: 0 auto;
  }
}
.currentUserTop {
  background-color: #37474f;
}
.expandedContent {
  display: grid;
  align-content: center;
  height: 180px;
  & [class*="title"] {
    display: none;
  }
  & [id*="delegatePractice"] {
    grid-template-rows: auto;
    margin: 0;
  }
  & [id*="delegatePracticeName"] {
    margin: 0 auto;
    width: 136px;
    color: #37474f;
    font-family: "Metropolis";
    font-size: 17px;
    font-weight: 300;
    line-height: 24px;
    text-align: center;
    letter-spacing: normal;
  }
  & [id*="delegatePracticeAddress"] {
    margin: 0;
    width: 194px;
    color: #546e7a;
    font-family: "Metropolis";
    font-size: 12px;
    letter-spacing: -0.29px;
    font-weight: normal;
    line-height: 15px;
    text-align: center;
    margin: 0 auto;
  }
  & [class*="emptySection"] {
    align-self: center;
    justify-self: center;
    margin-top: 7px;
    margin-bottom: 7px;
  }
  & [class*="emptyBox"] {
    height: 1px;
    padding: 0;
    border: none;
    border-bottom: 1px solid #eceff1;
    width: 124px;
    margin: 5px auto 5px auto;
  }
}
.delegateAvatar {
  height: 58px;
  width: 58px;
  border-radius: 50%;
  margin: -28px auto -10px;
  cursor: pointer;
  & img {
    margin: 0px !important;
  }
}
