@import '../../styleguide/variables';

.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: $white;
  opacity: 0.8;
  z-index: 1000;
  user-select: none;
}
.fixed {
  position: fixed;
}
.root {
  animation: progress-circular-rotate 1.4s linear infinite;
  color: $main-color;
}
.circle {
  stroke: $main-color;
  animation: progress-circular-dash 1.4s ease-in-out infinite;
  stroke-dasharray: 80px, 200px;
}
.svg {
  height: unset;
  margin-right: unset;
}
@keyframes progress-circular-rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes progress-circular-dash {
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0px;
  }
  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -125px;
  }
}

.centered {
  margin: 0 auto;
}
