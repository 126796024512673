@import '../../styleguide/variables';

.addressInfoCheckInPhoneType {
  @extend .appointmentCheckIn;
}
.addressInfoCheckInState {
  @extend .appointmentCheckIn;
}
.detailsInfoCheckInGender {
  @extend .appointmentCheckIn;
}
.detailsInfoCheckInPrimaryRace {
  @extend .appointmentCheckIn;
}
.detailsInfoCheckInSecondaryRace {
  @extend .appointmentCheckIn;
}
.documentInfoCheckInDocumentType {
  @extend .appointmentCheckIn;
}
.documentInfoCheckInProvider {
  @extend .appointmentCheckIn;
}
.documentInfoCheckInPlan {
  @extend .appointmentCheckIn;
}
.documentInfoCheckInRelationship {
  @extend .appointmentCheckIn;
}
.paymentFrequencyDisabled {
  pointer-events: none;
  & span[class*='Select-value-label'] {
    color: $light_gray_4 !important;
  }
}
.appointmentCheckIn {
  & [class*='Select-control'] {
    height: unset !important;
  }
  & [class*='Select-input'] {
    height: unset !important;
  }
  & [class*='Select-placeholder'] {
    padding-left: 0;
    height: 20px;
    color: $gray-3;
    font-family: '';
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
  }
  & div[class*='Select-value'] {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  & span[class*='Select-value-label'] {
    border: none;
    font-size: 16px;
    box-shadow: none;
    font-family: 'Metropolis';
    line-height: 1.846;
    font-weight: 400px;
    --webkit-font-smoothing: antialiased;
  }
}
.disabled {
  & span[class*='Select-value-label'] {
    color: $gray-2 !important;
  }
}
.emergencyContactState {
  width: 98.7px;
  display: flex;
  flex-direction: column;
  & label {
    margin: 0;
    line-height: 16px;
  }
}
:global(div[id*='profileSettings'] [class*='Select-control']) {
  height: unset !important;
}
:global(div[id*='profileSettings'] div[class*='select-']) {
  height: 51px;
  display: flex;
  margin-bottom: 15px;
  flex-direction: column;
}
:global(div[id*='profileSettings'] [class*='Select-input']) {
  height: unset !important;
}
:global(div[id*='profileSettings'] [class*='Select--single']) {
  width: 100%;
}
:global(div[id*='createVisitSummary'] [class*='Select--single']) {
  width: 100%;
}
:global(div[id*='profileSettings'] [class*='Select-placeholder']) {
  padding-left: 0;
  height: 25px;
  padding-top: 5px;
  color: $gray-3;
  font-family: 'Metropolis';
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
}
:global(div[id*='profileSettings'] div[class*='Select-value']) {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
:global(div[id*='profileSettings'] span[class*='Select-value-label']) {
  border: none;
  font-size: 16px;
  box-shadow: none;
  font-family: 'Metropolis';
  line-height: 1.846;
  font-weight: 400;
  --webkit-font-smoothing: antialiased;
}
:global(div[id*='profileSettings'] [class*='Select-input'] > input) {
  margin-left: -10px;
  padding: 0;
  border: none;
  box-shadow: none;
  font-family: 'Metropolis';
  line-height: 1.846;
  font-weight: 400;
  --webkit-font-smoothing: antialiased;
}
.label {
  text-transform: uppercase;
  text-align: left;
  font-size: 12px !important;
  color: $gray-7;
  font-family: 'Metropolis', sans-serif;
  font-weight: 800 !important;
  letter-spacing: 0.06em;
  --webkit-font-smoothing: antialiased;
  margin-bottom: 4px;
}
.select {
  & [class*='Select-arrow-zone'] {
    padding-top: 4px;
  }
  & [class*='Select--single'] > [class*='Select-control'] [class*='Select-value'] {
    padding: 0;
  }
  & [class*='Select-control'] {
    background-color: $white !important;
    height: 34px;
    border: 0;
    overflow: hidden;
    cursor: pointer;
    border-bottom: 1px solid $light_gray_4 !important;
    border-radius: 0;
    &:hover {
      box-shadow: none !important;
    }
    outline: none;
    box-shadow: none !important;
    & div[class*='Select-placeholder'] {
      color: $gray-3;
      padding-left: 0px;
      font-family: 'Metropolis';
      font-size: 16px;
      font-weight: 300px;
    }
  }
  & input[name*='referralSource'] + div[class*='Select-value-label'] {
    font-weight: 300;
  }
  & [class*='Select-value-label'] {
    font-size: 16px;
    color: $main-color !important;
  }
  & [class*='Select-input'] > input {
    font-size: 16px;
    color: $main-color !important;
  }
  & [class*='is-focused'] {
    &:not([class*='is-open']) {
      & > [class*='Select-control'] {
        background-color: transparent;
        border: none;
        box-shadow: none;
      }
    }
  }
  & [class*='Select-menu-outer'] {
    box-shadow: 0 1px 4px $box_shadow_gray;
    background-color: $white;
    border-radius: 6px;
    border: 0;
    margin-top: 7px;
    z-index: 2;
    --webkit-overflow-scrolling: touch;
    & [class*='Select-option'] {
      font-size: 16px;
      line-height: 17px;
      background: none;
      font-weight: 400;
      color: $gray-8;
      box-sizing: content-box;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      & [class*='is-selected'],
      & [class*='is-focused'] {
        background-color: $gray-0;
        &:last-child {
          border-radius: 0 0 6px 6px;
        }
        &:first-child {
          border-radius: 6px 6px 0 0;
        }
      }
      &:hover:not([class*='is-disabled']) {
        background-color: $gray-1;
        outline: none;
      }
      & [class*='is-disabled'] {
        opacity: 0.5;
      }
      & [class*='Select-highlighted'] {
        background-color: transparent;
        font-weight: bold;
      }
    }
  }
}
.errorMessage {
  position: relative;
  color: $lighter_destructive;
  text-align: left;
  top: 0px;
  font-weight: 300;
}
.required {
  font-size: 20px;
  margin-left: 5px;
  color: $lighter_destructive;
  position: absolute;
  line-height: 20px;
}
.selectWrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0 !important;
  & [class*='Select'] > [class*='Select--single'] {
    flex: 1;
  }
}
.supportingText {
  color: $gray-11;
}
.supportingTextGreen {
  color: $highlight;
}
.signupDropdownGender {
  width: 340px;
  min-height: 51px;
  margin: 0 0 18px 0;
  font-weight: lighter;
  & div[class*='Select-placeholder'] {
    padding-left: 0;
    font-weight: 400;
  }
}
