@import '../../styleguide/variables';

.contentContainer {
  display: flex;
  margin-top: 45px;
}
.column {
  display: flex;
  flex-direction: column;
  width: 360px;
  align-items: flex-start;
  height: 513px;
  margin-right: 0.5rem;
}
.formColumn {
  @extend .column;
  width: 725px;
  height: 542px;
  & div[id*='formHistoryHeader'] {
    max-height: 100px;
    min-height: 38px;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .column{
    height: 240px;
  }
  .formColumn {
    height: 90%;
    & div[id*='formHistoryHeader'] {
      max-height: 100px;
      min-height: 38px;
    }
  }

}
.button {
  background-color: $gray-4;
  color: $white;
  height: 48px;
  width: 213px;
  margin-top: 22px;
  margin-right: 252px;
  border-radius: 6px;
  text-transform: uppercase;
  font-family: 'Metropolis', sans-serif;
  font-size: 13px;
  font-weight: 500;
  float: right;
  cursor: not-allowed;
}
.centerContent {
  padding-left: 30px;
  display: flex;
  margin-top: 10px;
  min-height: 0;
  min-width: 0;
  & div[class*='info'] {
    min-height: 50px;
  }
}
.enabled {
  background-color: $highlight;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    background-color: $highlight;
  }
}
.cardContainer {
  height: 468px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 8px 14px 14px 14px;
  margin-top: 5px;
  transform: translate(-13px, -1px);
}
.info {
  color: $gray-4;
  font-size: 13px;
  line-height: 19px;
  flex-basis: 320px;
  margin-top: 15px;
  margin-left: 12px;
}
.formContainer {
  @extend .cardContainer;
  padding: 0 26px 0 13px;
  display: flex;
  flex-direction: column;
  height: unset;
  margin-top: 8px;
  min-width: 460px;
  border-right: 1px solid $gray-1;
  & div[class*=checkBoxContainer] {
    display: flex;
    flex-direction: row;
    text-transform: capitalize;
    justify-content: start;
    border-bottom: 1px solid lightgray;
    font-size: 15px;
    color: #90a4ae;
    font-weight: 500;
    font-family: "Metropolis";
    align-items: center;
    padding: 9px 1px 10px 0px;
    position: relative;
    height: 32px;
    width: 100%;
}
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
.formContainer {
min-width: 365px;
}
 
}
.heading {
  display: flex;
}
.unsingedCount {
  position: relative;
  right: 74%;
  border: 1px solid $destructive;
  border-radius: 25px;
  width: 22px;
  background: $destructive;
  color: $white;
  height: 22px;
  text-align: center;
  margin-top: 7px;
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .centerContent {
    padding-left: 10px;
  }
  .info {
    font-size: 10px;
  }
  .contentContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;
  }
  .button {
    background-color: $gray-4;
    color: $white;
    height: 48px;
    width: 213px;
    margin-left: 10%;
    float: none;
    margin-top: 22px;
    margin-right: 252px;
    border-radius: 6px;
    text-transform: uppercase;
    font-family: 'Metropolis', sans-serif;
    font-size: 13px;
    font-weight: 500;
    cursor: not-allowed;
  }
}