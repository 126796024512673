.checkMark {
  background-color: #fff;
  width: 100%;
  z-index: 100;
  margin: 30px 0 10px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.circleLoader {
  bottom: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-left-color: #5cb85c;
  margin: auto;
  animation: loaderSpin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: 7em;
  height: 7em;
}
.checkmark {
  display: none;
  &:after {
    opacity: 1;
    height: 3.5em;
    width: 1.75em;
    transform-origin: left top;
    border-right: 3px solid #5cb85c;
    border-top: 3px solid #5cb85c;
    content: '';
    left: 1.75em;
    top: 3.5em;
    position: absolute;
  }
}
.draw {
  &:after {
    animation-duration: 800ms;
    animation-timing-function: ease;
    animation-name: checkmark;
    transform: scaleX(-1) rotate(135deg);
  }
}
.cross {
  display: none;
  &:before {
    box-sizing: border-box;
    line-height: 50;
    transition: all 2s ease-in;
    transform: rotate(-45deg);
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -5px;
    margin-left: -25px;
    display: inline-block;
    height: 10px;
    width: 50x;
    animation: 3s pulse;
    background-color: #e82929;
  }
  &:after {
    box-sizing: border-box;
    line-height: 50;
    transform: rotate(-135deg);
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -5px;
    margin-left: -25px;
    display: inline-block;
    height: 10px;
    width: 50px;
    animation: 3s pulse;
    background-color: #e82929;
    transition: all 1s ease-out;
  }
}
@keyframes loaderSpin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 1.75em;
    opacity: 1;
  }
  40% {
    height: 3.5em;
    width: 1.75em;
    opacity: 1;
  }
  100% {
    height: 3.5em;
    width: 1.75em;
    opacity: 1;
  }
}
@-webkit-keyframes pulse {
  0% {
    webkitboxshadow: 0 0 0 0 rgba(229, 119, 119, 0.4);
  }
  70% {
    webkitboxshadow: 0 0 0 10px rgba(229, 119, 119, 0);
  }
  100% {
    webkitboxshadow: 0 0 0 0 rgba(229, 119, 119, 0);
  }
}
@keyframes pulse {
  0% {
    mozboxshadow: 0 0 0 0 rgba(229, 119, 119, 0.4);
    boxshadow: 0 0 0 0 rgba(229, 119, 119, 0.4);
  }
  70% {
    mozboxshadow: 0 0 0 10px rgba(229, 119, 119, 0);
    boxshadow: 0 0 0 10px rgba(229, 119, 119, 0);
  }
  100% {
    mozboxshadow: 0 0 0 0 rgba(229, 119, 119, 0);
    boxshadow: 0 0 0 0 rgba(229, 119, 119, 0);
  }
}
