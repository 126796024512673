.checkbox {
  cursor: pointer;
}
.supportingText {
  color: #a6a6a6;
  font-weight: 300 !important;
  font-family: 'Metropolis', sans-serif;
  font-size: 13px;
  line-height: 1.846;
}
.errorMessage {
  position: relative;
  color: rgb(255, 51, 51);
  text-align: left;
  top: -10px;
  font-weight: 300;
}
.required {
  font-size: 20px;
  font-weight: 800;
  width: 100%;
  margin-left: 5px;
  color: rgb(255, 51, 51);
  text-align: left;
}
.checkBoxContainer {
  & input[type='checkbox']:checked + div div {
    color: #5bc5d0;
  }
  & input[type='checkbox']:checked + div div[class*='supportingText'] {
    color: #4caf50;
  }
  cursor: pointer;
  & input[type='checkbox'] {
    margin-left: 15px;
    position: relative;
    border: none;
    margin-top: 0;
    margin-bottom: -4px;
    appearance: none;
    cursor: pointer;
  }
  & input[type='checkbox']:after {
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    margin-top: -2px;
    margin-right: 5px;
    border: 2px solid #c9d2d7;
    border-radius: 2px;
    transition: 240ms;
  }
  & input[type='checkbox']:checked:before {
    content: '';
    position: absolute;
    top: 0;
    left: 7px;
    display: table;
    width: 6px;
    height: 12px;
    border: 2px solid #fff;
    border-top-width: 0;
    border-left-width: 0;
    transform: rotate(45deg);
  }
  & input[type='checkbox']:checked:after {
    border-color: #5bc5d0;
    background-color: #5bc5d0;
  }
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  text-transform: capitalize;
  font-size: 15px;
  color: #597583;
  font-weight: 500;
  font-family: 'Metropolis';
  align-items: center;
  padding: 9px 1px 10px 0px;
  position: relative;
  height: 32px;
}
.jsonSchemaContainer {
  justify-content: unset;
  flex-direction: row;
  border-bottom: none;
}
.radio {
  position: relative;
}
.icon {
  height: 30px;
  margin-right: 7px;
}
.labelContainer {
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  & div {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.formHistory {
  & div div {
    width: 285px;
  }
  & input[type='checkbox'] {
    order: 1;
  }
  .pending{
    order: 0;
  }
}
.withSupportingText {
  justify-content: space-between;
  width: 100%;
}
.svg {
  height: 30px;
  margin-right: 7px;
  display: flex;
  align-items: center;
}
.addAppointmentRadio {
  width: 326px;
  margin-left: 3px;
}
.addAppointmentLocationRadio {
  @extend .addAppointmentRadio;
}
.disabled {
  & input[type='checkbox']:checked + div div {
    color: #455a64;
  }
  cursor: default;
  & input[type='checkbox'] {
    cursor: default;
  }
  & input[type='checkbox']:checked:after {
    border-color: #cfd8dc;
    background-color: #cfd8dc;
  }
  color: #cfd8dc;
}
.prePayment {
  height: 18px;
  width: 107px;
  border: 1px solid #5bc5d0;
  border-radius: 4px;
  font-size: 9px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  color: #5bc5d0;
  margin-left: auto;
  margin-right: 13px;
  padding-top: 1px;
}
.termsAndConditions {
  display: inline-flex;
  flex-direction: row;
  border: none;
  & input {
    margin-left: 0px !important;
  }
  & div {
    font-size: 15px;
  }
}
.pending {
  order: -1;
  margin-left: auto;
  color: #ffa500;
  font-weight: 300;
  font-family: 'Metropolis', sans-serif;
  font-size: 12px;
  line-height: 1.846;
}
.withPending {
  order: -2;
}
.guestRegisterTermsAndConditions {
  display: inline-flex;
  flex-direction: row;
  border: none;
  & div {
    font-size: 12px;
  }
}
.visitSummaryEmailUnsecured {
  font-size: 15px;
  margin-top: -15px;
  border-bottom: 0 !important;
  & + div[class*='supportingText'] {
    margin-top: 0px;
    margin-bottom: 75px;
    font-size: 10px !important;
    color: red;
    font-weight: 300;
    border-bottom: 1px solid #c8d2d7;
    &:after {
      display: block !important;
      content: '';
      border-top: 1px solid #c8d2d7 !important;
      position: relative;
      top: 64px;
      right: 80px;
      width: 130%;
    }
  }
}
.visitSummaryEmailUnsecuredLabels {
  text-transform: none;
}
