%placeholderBase {
  padding-left: 0px;
  height: 20px;
  color: #b0bec5;
  font-family: 'Metropolis';
  font-size: 16px;
  line-height: 20px;
}
%signupInputFirstNameBase {
  width: 340px;
  min-height: 51px;
  margin: 0px 0px 18px 0px;
}
.datePickerWrapper {
  display: flex;
  flex-direction: row;
  width: 90%;
}

.formGroup {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  & #subjectInput {
    height: unset;
  }
  & input {
    box-shadow: none;
    border: none;
    border-bottom: 1px solid #c8d2d7;
    font-family: 'Metropolis';
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    line-height: 1.846;
    color: #5bc5d0;
    font-weight: 400;
    &[disabled] {
      color: #cfd8dc;
    }
    &::-webkit-input-placeholder {
      @extend %placeholderBase;
    }
    &::-moz-placeholder {
      @extend %placeholderBase;
    }
    &:-ms-input-placeholder {
      @extend %placeholderBase;
    }
    &:-moz-placeholder {
      @extend %placeholderBase;
    }
  }
}
.signatureTime {
  width: 100px;
  float: right;
  text-align: right;
  color: #546e7a;
  font-size: 12px;
  font-family: 'Metropolis', sans-serif;
  font-weight: 800;
  letter-spacing: 0.06em;
  -webkit-font-smoothing: antialiased;
}
.placeholder {
  @extend %placeholderBase;
}
.label {
  color: #546e7a;
  font-size: 12px;
  text-align: left;
  font-family: 'Metropolis', sans-serif;
  font-weight: 800 !important;
  text-transform: uppercase;
  letter-spacing: 0.06em;
  -webkit-font-smoothing: antialiased;
}
.withOptional {
  justify-content: space-between;
  align-items: center;
}
.errorMessage {
  position: relative;
  color: red;
  top: -17px;
  text-align: left;
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .errorMessage{
    top: -6px;
  }
}
.required {
  font-size: 20px;
  margin-left: 5px;
  color: rgb(255, 51, 51);
  position: absolute;
  line-height: 20px;
}
.inputWrapper {
  display: flex;
  overflow: hidden;
  & input[id*='updateSignupEmail'] {
    width: 400px;
  }
}
.disabled {
  & input {
    color: #cfd8dc;
  }
}
.inputWrapperWithRequired {
  justify-content: flex-end;
}
.supportingText {
  color: #a6a6a6;
}
.supportingTextGreen {
  color: #4caf50;
}
////////// #region SIGNUP ///////////
/////////////////////////////////////
////////// SIGNUP STYLES ////////////
/////////////////////////////////////
.signupInputFirstName {
  @extend %signupInputFirstNameBase;
}
.signupInputLastName {
  @extend %signupInputFirstNameBase;
}
.signupInputEmail {
  @extend %signupInputFirstNameBase;
  & input {
    color: #b0bec5;
  }
}
.signupInputEmailEditable {
  @extend %signupInputFirstNameBase;
  & input {
    color: #5bc5d0;
  }
}
.signupInputEmailEditableConfirm {
  @extend %signupInputFirstNameBase;
  & input {
    color: #5bc5d0;
  }
}
.signupInputDOB {
  @extend %signupInputFirstNameBase;
  & input::placeholder {
    color: #b0bec5;
  }
}
.signupInputPhoneNumber {
  @extend %signupInputFirstNameBase;
}
.signupInputCreatePassword {
  @extend %signupInputFirstNameBase;
}
.signupInputRepeatPassword {
  @extend %signupInputFirstNameBase;
  margin: 0px 0px 18px 0px;
}
// #endregion ///////////////////////

//// #region CANCEL APPOINTMENT ////
////////////////////////////////////
///// CANCEL APPOINTMENT STYLES ////
/////////////////////////////////////
.otherReasonInput {
  pointer-events: none;
  & input {
    border-bottom: 1px solid #eceff1;
  }
  & label {
    color: #cfd8dc;
  }
}
// #end-region ///////////////////////

//////// #region CHECK-IN //////////
////////////////////////////////////
////////// CHECK-IN STYLES /////////
////////////////////////////////////
.emergencyContactCity {
  width: 214px;
  padding-right: 1rem;
}
.emergencyContactFirstName {
  margin-top: 15px;
}
// #end-region ///////////////////////
.radio {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #c8d2d7;
  margin-left: auto;
  margin-right: 15px;
  cursor: pointer;
  font-size: 15px;
  color: #597583;
  padding: 9px 9px 10px 0px;
  position: relative;
  height: 32px;
  & > input {
    cursor: pointer;
  }
  & > input[type='radio']:checked:before {
    z-index: 1;
    background-color: #ffffff;
    top: 11px;
    right: 5px;
    border: 8px solid #5bc5d0;
    box-sizing: content-box;
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
  }
  & > input[type='radio']:after {
    width: 16px;
    height: 16px;
    border-radius: 15px;
    top: -2px;
    left: -4px;
    position: relative;
    background-color: #fff;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #c9d2d7;
  }
  & input[type='radio']:checked + div {
    color: #51bdca;
  }
}
.radioGroup {
  width: 100%;
}
.loginOTP{
  padding-top: 12px;
  height: 51px;
  & label{
    padding-bottom: 11px;
  }
}