.creditContainer {
  margin-top: 5px;
  height: 45px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  &#subjectInput {
    height: unset;
  }
  & input {
    box-shadow: none;
    border-bottom: 1px solid #dddddd;
    font-family: 'Metropolis';
    font-size: 16px;
    --webkit-font-smoothing: antialiased;
    line-height: 1.846px;
    color: #5bc5d0;
    width: 100%;
    font-weight: 400;
    border: none;
  }
}
.cardType {
  border: 1px solid #5bd064;
  padding: 2px 6px;
  color: #5bd064;
  font-size: 9px;
  font-weight: 600;
  border-radius: 4px;
  text-transform: uppercase;
  height: 15px;
  display: flex;
  white-space: nowrap;
}
.creditCardError {
  height: 30px;
  width: 100%;
  background-color: red;
  color: #fff;
  border-radius: 6px;
  margin: 5px 0 11px 0;
  padding: 3px 0 0 10px;
  font-weight: 500;
  box-sizing: border-box;
}
.label {
  text-transform: uppercase;
  text-align: left;
  font-size: 10px;
  color: #546e7a;
  font-family: 'Metropolis', sans-serif;
  font-weight: 800;
  letter-spacing: 0.06em;
  --webkit-fontSmoothing: antialiased;
}
.inputWrapper {
  display: flex;
  overflow: hidden;
}
.validation {
  @extend .creditContainer;
  flex-direction: row;
  & input {
    width: 100%;
    margin-top: 5px;
  }
}
.expiration {
  width:58%;
  &>div{
    display: flex;
    flex-wrap: nowrap;
    & div[class*='Field_wrapper'] {
      width: 50%;
      margin-right: 10px;
      & div[class*='Select-control']{
        padding: 1.5px 0;
        height: unset;
      }
    }
  }
}
.valcode {
  width:42%;
  margin-right: 15px;
  & input {
    border-bottom: 1px solid #dddddd;
  }
}
.newCardFormContainer {
  margin-top: 20px;
  margin-bottom: 130px;
}
.newCardBtn {
  border: none;
  color: #59bdc9;
  cursor: pointer;
  float: right;
  margin-top: 10px;
  font-size: 13px;
  text-transform: capitalize;
  &:hover {
    text-decoration: underline;
  }
}
.creditCardNumber,
.creditCardName {
  @extend .creditContainer;
  border-bottom: 1px solid #dddddd;
  & input {
    border-bottom: 0;
    margin-top: 8px;
  }
}

.paymentOptions {
  & div[class*=labelContainer] { font-style: normal !important; }
  & span[class*=disabledLabel] { font-style: normal !important; }
  & span[class*=defaultLabel] { font-style: normal !important; }
}

.hideContainer {
  display: none;
}
.numberContainer {
  display: flex;
}
#profileSettingsCreditCardForm [class*='newCardFormContainer'] {
  margin-top: 0;
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .newCardFormContainer {
    margin-bottom: 0px;
  }
}
#profileSettingsCreditCardForm [class*='valcode'] {
  margin-right: 35px;
}
