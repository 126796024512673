.horizontal {
  display: flex;
  flex-direction: row;
}

.vertical {
  display: flex;
  flex-direction: column;
}

.grid {
  display: grid;
}

%paginateButtonBase {
  background-size: cover !important;
  height: 14px;
  width: 14px;
  align-self: center;
  cursor: pointer;
}

.previousButton {
  background: url("https://assets.gobreeze.com/select-arrow-left.png");
  @extend %paginateButtonBase;
  justify-self: start;
  grid-column: 1;
  margin-left: 8px;
}
.nextButton {
  background: url("https://assets.gobreeze.com/select-arrow-right.png");
  @extend %paginateButtonBase;
  justify-self: end;
  grid-column: 3;
  margin-right: 8px;
}

.hidden {
  background: none;
}
