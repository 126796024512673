@import '../../styleguide/variables';

.root {
    position: relative;
    overflow: hidden;
    height: 4px;
}
.colorPrimary {
    background-color: $main-color;
}
.colorSecondary {
    background-color: $white;
}

.bar {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    transition: transform 0.2s linear;
    transform-origin: left;
}
.barColorPrimary {
    background-color: $main-color;
}
.barColorSecondary {
    background-color: $white;
}
.bar1Indeterminate {
    width: auto;
    animation: indeterminate1 1.8s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
};
.bar2Indeterminate {
    width: auto;
    animation: indeterminate2 1.8s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    animation-delay: 1.15s;
};
@keyframes indeterminate1 {
//  |-----|---x-||-----||-----|
    0% {
        left: -35%;
        right: 100%;
    };
    //  |-----|-----||-----||xxxx-|
    60% {
        left: 100%;
        right: -90%;
    };
    100% {
        left: 100%;
        right: -90%;
    };
};
@keyframes indeterminate2 {
//  |xxxxx|xxxxx||-----||-----|
    0% {
        left: -200%;
        right: 100%;
    };
//  |-----|-----||-----||-x----|
    60% {
        left: 107%;
        right: -8%;
    };
    100% {
        left: 107%;
        right: -8%;
    };
};