@import '../../styleguide/variables';

.secondary {
  color: #5bc5d0;
  box-shadow: 1px 1px 4px rgba(31, 155, 222, 0.4);
  border: 1px solid #5bc5d0;
  width: 100%;
  display: block;
  background-color: #ffffff;
  padding: 8px;
  cursor: pointer;
  outline: none;
}

.container {
  width: 250px;
  border-radius: 6px;
  background-color: $gray-1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}
.header {
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  background-color: $main-color;
  height: 34px;
  align-items: center;
  color: $white;
  font-family: 'Metropolis';
  font-size: 11px;
  font-weight: 500;
}
.partial {
  background-color: $alert;
}
.plan {
  background-color: $gray-8;
}
.subHeader {
  display: flex;
  justify-content: center;
  color: $gray-4;
  font-size: 17px;
  font-weight: 500;
  margin-top: 18px;
}
.amount {
  display: flex;
  justify-content: center;
  color: $gray-8;
  height: 30px;
  font-size: 39px;
  line-height: 1.3;
  font-weight: 500;
  font-family: 'Metropolis';
  margin-top: 10px;
  margin-bottom: 35px;
  align-items: baseline;
  & div {
    font-size: 20px;
  }
}
.paymentPlanDetails {
  display: flex;
  justify-content: center;
  color: $gray-7;
  font-size: 11px;
  margin: 13px 0;
}
.balance {
  display: flex;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  justify-content: space-between;
  padding: 10px 0px;
  margin: 10px;
  & h3 {
    color: $gray-9;
    font-size: 15px;
    font-weight: 500;
    font-family: 'Metropolis';
    margin: 0;
    text-transform: capitalize;
  }
  & h3:first-of-type {
    font-weight: 400;
  }
}
.copay {
  @extend .balance;
  border-top: none;
  margin-top: 0;
}
.border-top {
  border-top: 1px solid lightgray;
}
.previousBalance {
  @extend .balance;
  margin-bottom: 0;
}
.pendingBalance {
  color: $gray-11;
  font-size: 13px;
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.balanceDetails {
  display: flex;
  justify-content: center;
}
.pay {
  color: $white;
  cursor: pointer;
  width: 100%;
  border-radius: 6px;
  height: 48px;
  text-transform: uppercase;
  background-color: $highlight;
  margin-top: 20px;
  font-size: 13px;
  font-family: 'Metropolis';
  &:hover {
    background-color: $highlight;
  }
  outline: none;
  transition: all 0.2s;
}
.patientStatement {
  @extend .secondary;
  height: 48px;
  border-radius: 6px;
  box-shadow: none;
  margin: 10px 0;
  text-transform: uppercase;
  display: none;
}
.disable {
  @extend .pay;
  cursor: not-allowed;
  background-color: $gray-2;
  &:hover {
    background-color: $gray-2;
  }
}
