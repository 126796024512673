@import '../styleguide/variables';

h2 {
  font-family: 'Metropolis', sans-serif;
  font-weight: 100;
  font-size: 42px;
  color: $gray-3;
  margin: 0;
  line-height: 50px;
  letter-spacing: -2px;
}
h3 {
  font-family: 'Metropolis', sans-serif;
  font-weight: 500;
  font-size: 32px;
  color: $gray-8;
  margin: 0;
  line-height: 38px;
}
h4 {
  font-family: 'Metropolis', sans-serif;
  font-size: 21px;
  font-weight: 500;
  color: $gray-6;
  margin: 0;
  line-height: 23px;
}
h6 {
  font-family: 'Metropolis', sans-serif;
  color: $gray-5;
  font-size: 19px;
  text-transform: initial;
  font-weight: 500;
  margin: 0;
}
h1 {
  font-family: 'Metropolis', sans-serif;
  font-size: 42px;
  font-weight: 100;
  color: $gray-3;
  text-transform: capitalize;
  margin-bottom: 0;
}
* {
  outline: none;
}
root {
  height: 100%;
}
[id*='providerList']::-webkit-scrollbar {
  width: 0;
  background: transparent /* make scrollbar transparent */;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  display: none;
  margin: 0;
}
[class*='messageConversation']::-webkit-scrollbar {
  width: 0;
  background: transparent /* make scrollbar transparent */;
}
// [id*='providerMessageList']::-webkit-scrollbar {
//   width: 0;
//   background: transparent /* make scrollbar transparent */;
// }
// [id*='providerMessageList'] [class*='short']::-webkit-scrollbar {
//   width: 0;
//   background: transparent /* make scrollbar transparent */;
// }
[id*='providerMessageList']::-webkit-scrollbar {
  width: 8px;
}
[id*='providerMessageList']::-webkit-scrollbar-thumb {
  background: #e5e5e5;
  border-radius: 4px;
}
[class*='messagesSidePanel']::-webkit-scrollbar {
  width: 0;
  background: transparent /* make scrollbar transparent */;
}
