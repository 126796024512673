@import '../variables';

.container {
  background-color: transparent;
  border-radius: 6px;
  border: 1px solid $gray-2;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
}

.header {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  padding: $spacing-bold;
  border-bottom: 1px solid $gray-2;

  .titleContainer {
    width: 70%;

    .title {
      color: $gray-9;
      font-family: 'Metropolis', sans-serif;
      font-size: 13px;
      font-weight: bold;
      line-height: 16px;
      margin: 0;
      text-transform: uppercase;
    }
  }
  
  .stateContainer {
    width: 30%;
  }
  
  .queryContainer {
    margin: $spacing-base 0 0;
    width: 100%;
  }
}

.content {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  min-height: 162px;
  box-sizing: border-box;

  .results {
    padding: 0 $spacing-bold;
    max-height: 312px;
    min-height: 162px;
    overflow: scroll;
    width: 100%;
    box-sizing: border-box;
  }
  
  .empty {
    padding: $spacing-bold;
    text-align: center;
    box-sizing: border-box;

    svg {
      font-size: 60px;
      margin: 0;
    }
  
    p {
      width: 100%;
      margin: $spacing-bold 0 0;
    }
  }
}

.footer {
  border-top: 1px solid $gray-2;
  display: flex;
  justify-content: space-between;
  padding: $spacing-bold;

  button {
    margin-right: $spacing-bold;
    &:last-child {
      margin-right: 0;
    }
  }
}
