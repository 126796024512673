%guestEntryWrapperBase {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  animation: fadein 0.5s forwards;
}
div[id*='registerInformation'] {
  h4 {
    text-align: center;
    font-weight: 400;
  }
}
%guestContentPageBase {
  margin: 0 auto;
  padding-top: 0;
  width: 100%;
}
.guestEntryWrapper {
  @extend %guestEntryWrapperBase;
}
.userSection {
  cursor: pointer;
  color: #5bc5d0;
  font-size: 6px;
  padding-right: 0px;
  font-weight: 400;
  padding-left: 20px;
  font-family: 'Metropolis';
  text-transform: capitalize;
  background-color: transparent;
  transition: color 0.5s;
  &:hover {
    color: #455a64;
    color: #455a64;
  }
  &:after {
    border-style: solid;
    border-width: 0.15em 0.15em 0 0;
    content: '';
    display: inline-block;
    height: 0.35em;
    left: 0.15em;
    position: relative;
    width: 0.35em;
    top: -3px;
    margin-left: 5px;
    margin-right: 5px;
    transform: rotate(135deg);
    background-color: transparent;
  }
}

.guestContentPage {
  @extend %guestContentPageBase;
  & button[id*='loginLanguageDropdown'] {
    margin-top: 50px;
    color: #546e7a;
    font-family: 'Metropolis';
    font-size: 13px;
    font-weight: 600;
    line-height: 13px;
    text-align: center;
  }
  & div[class='inputDropdown_selectWrapper'] {
    justify-content: flex-start;
  }
  & div[class='Select-control'] {
    width: 360px;
  }
  & div[class='Select-placeholder'] {
    width: 20px;
  }

  & div[class*='dropdown'] {
    position: relative;
    margin: 0 auto;
  }
  & div[class*='inputDropdown'] {
    color: #5bc5d0 !important;
  }
  & div[class*='Select-placeholder'] {
    padding-right: 310px;
    color: #b0bec5 !important;
  }
  & div[id*='comfirmationTopBar'] {
    height: 52px;
    box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.3);
    margin: 0 55px;
    margin-bottom: 10px;
    & img[id*='mainLogo'] {
      margin: auto 0;
    }
  }
  & div[id*='confirmationInvalidGroup'] {
    align-items: center;
    margin-top: 80px;
    & img[id*='breezeEnvelopeLogo'] {
      margin-bottom: 40px;
      height: 230px;
      width: 192px;
    }
    & div[id*='confirmationInvalidHeader'] {
      & h4 {
        font-size: 24px;
        font-weight: 500;
        letter-spacing: -1.5px;
        color: #4b5a64;
      }
    }
    & div[id*='confirmationInvalidTextTop'] {
      & h5 {
        height: 48px;
        width: 460px;
        text-align: center;
        line-height: 17px;
        margin-top: 25px;
      }
    }
    & button[id*='confirmationInvalidResendButton'] {
      font-weight: 400;
    }
    & h5 {
      color: #78909c;
      font-size: 14px;
      font-family: 'Metropolis';
      font-weight: 300;
      margin: auto;
    }
  }
}

.guestEntryWrapperMobile {
  @extend %guestEntryWrapperBase;
  min-height: 100vh;
}

%guestContentPageBase{
  & div[id*='guestPayHeader'] {
    padding: 1rem 0 6rem;
    & div[id='guestPayPracticeNameLogo']{
      width:90%;
      margin: 0 auto;
      &>h1[id*='guestPayPracticeNameLogo']{
        font-size: 53px;
        font-weight: 300;
        color: white;
        text-align: center;
        width: 100%;
        height: auto;
        text-wrap: wrap;
      }
    }
  }
}

.guestMobileContentPage {
  @extend %guestContentPageBase;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  padding: 0;
  & div[id*='guestPayHeader'] {
    padding: 1rem 0 6rem;
  }
  & div[id*='guestPayFormGroupContainer'] {
    width: 100%;
    border-radius: 0px;
    box-shadow: none;
    & div[class*='formWrapper'] {
      padding: 0px !important;
      & > div:first-child {
        padding-right: 1rem;
      }
      & form[id*='guestPayGroups'] {
        & button[class*='ButtonContainer'] {
          width: 100% !important;
        }
      }
    }
  }
 
  @media screen and (max-width: 460px) {
    div[id*='guestPayFormGroupContainer'] {
      width: 96%;
      box-shadow: 0 2px 4px 0 rgb(55 71 79 / 30%);
      border-radius: 2%;
  }

  div[id='guestPayPracticeNameLogo']{
    &>h1[id*='guestPayPracticeNameLogo']{
      font-size: 33px !important;
      line-height: 1.3 !important;
      margin-bottom: 10px;
    }
  }
  
}
 
  & div[id*='guestPayNoCodeFormGroupContainer'] {
    width: 100%;
    border-radius: 0px;
    box-shadow: none;
    & div[class*='formWrapper'] {
      padding: 0px !important;
      & > div:first-child {
        padding-right: 1rem;
      }
      & form[id*='guestPayNoCodeGroups'] {
        & button[class*='ButtonContainer'] {
          width: 100% !important;
        }
      }
    }
  }
  @media screen and (max-width: 460px) {
    div[id*='guestPayNoCodeFormGroupContainer'] {
      width: 90%;
      box-shadow: 0 2px 4px 0 rgb(55 71 79 / 30%);
      border-radius: 2%;
    } 
    & Button [id*='guestPayNoCodeContinueBtn']{
      width:100%;
    }
  }
  
  & div[id*='signupContainer'] {
    height: 100vh;
  }
}
%delegateEntryBase {
  background-color: #eceff1;
}
.delegateVerificationWrapper {
  @extend %delegateEntryBase;
}
.delegateLoginWrapper {
  @extend %delegateEntryBase;
}
.delegateCreateAccountWrapper {
  @extend %delegateEntryBase;
}
.delegateForgotPasswordWrapper {
  @extend %delegateEntryBase;
}

@media screen and (max-width: 460px) {
  div[id*='guestPayFormGroupContainer'] {
    width: 350px;
      & form[id*='guestPayGroups'] {

        & button[class*='guestPayContinueBtn'] {
          width: 310px !important;
        }
       
      }
    }
    .guestContentPage{
      & div[class='Select-control'] {
        width: 310px;
      }
    }
    div[id*='guestPayNoCodeFormGroupContainer'] {
      width: 350px;
          & form[id*='guestPayNoCodeGroups'] {
            width: 310px !important;
        }
        button[class*='guestPayNoCodeContinueBtn'] {
          width: 310px !important;
        }
      }
}

  @media screen and (max-width: 360px) {
    div[id*='guestPayFormGroupContainer'] {
      width: 270px;
        & form[id*='guestPayGroups'] {
          width: 210px !important;
          & button[class*='ButtonContainer'] {
            width: 210px !important;
          }
          & button[class*='guestPayContinueBtn'] {
            width: 210px !important;
          }
         
        }
      }
      div[id*='guestPayNoCodeFormGroupContainer'] {
        width: 270px !important;
        & form[id*='guestPayNoCodeGroups'] {
          width: 210px !important;
      }
      button[class*='guestPayNoCodeContinueBtn'] {
        width: 210px !important;
      }
    }
      .guestContentPage{
        & div[class='Select-control'] {
          width: 210px;
        }
      }
    }
