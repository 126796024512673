.wrapper {
  padding-top: 20px;
  text-align: right;
  margin-right: 16.5px;
  top: -23px;
  position: relative;
}
.topButtonsWrapper {
  text-align: right;
  height: 25px;
  position: relative;
  & button {
    font-size: 11px;
    color: #869ca7;
    text-transform: capitalize;
    font-weight: bold;
    padding: 0 0 22px 0;
  }
}
.topButtonsPlaceHolder {
  height: 23px;
}

.headingCheckout {
  @extend .heading;
  background-color: #5bd064 !important;
}
%indicatorOrange {
  background-color: #f5a623;
}
%indicatorRed {
  background-color: #de2539;
}
.heading {
  background-color: #eceff1;

  height: 110px;
  width: 340px;
}
.headingRequesting {
  @extend .heading;
  background-color: #f5a623;
}
.indicator {
  width: 100%;
  height: 6px;
  &[class*='missed'] {
    font-size: 20px;
    @extend %indicatorRed;
  }
  &[class*='requested_upcoming'] {
    @extend %indicatorOrange;
  }
  &[class*='cancelled'] {
    @extend %indicatorRed;
  }
  &[class*='requested'] {
    @extend %indicatorOrange;
  }
}
.apptBox {
  height: 429px;
  width: 340px;
  border-radius: 10px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(55, 71, 79, 0.3);
  text-align: center;
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .wrapper{
    margin-left: 10%;
  }
  .heading {
  width: 100%;
  }
  .apptBox {
    height: 429px;
    width: 320px;
    border-radius: 10px;
    overflow: hidden;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(55, 71, 79, 0.3);
    text-align: center;
  }
}
.largeHeight {
  height: fit-content;
}
.mediumHeight {
  height: fit-content;
}
.smallHeight {
  height: 340px;
}
.indicatorIcon {
  display: none;
  position: absolute;
  top: 47px;
  font-size: 22px;
  left: calc(50% + 11px);
  background-color: white;
  border-radius: 50%;
}
.remove_circle {
  color: #de2539;
  display: inline;
}
.schedule {
  color: #f49c20;
  display: inline;
}
.do_not_disturb_alt {
  color: white;
  background-color: #de2539;
  display: inline;
}
.date {
  height: 30px;
  width: 100%;
  color: #37474f;
  font-size: 23px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 0.5rem;
}
.dateRequested {
  height: 30px;
  width: 100%;
  color: #ffffff;
  font-size: 23px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 0.5rem;
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
.date{
  width: 100%;
  font-size: 18px;
}
.dateRequested{
  width: 100%;
  font-size: 18px;

}
}
.time {
  height: 15px;
  width: 100%;
  color: #607d8b;
  font-size: 15px;
  font-family: 'Metropolis';
  font-weight: bold;
  text-align: center;
}
.timeRequested {
  height: 15px;
  width: 100%;
  color: #ffffff;
  font-size: 15px;
  font-family: 'Metropolis';
  font-weight: bold;
  text-align: center;
}
.timeWhite {
  @extend .time;
  color: #ffffff;
}
.section {
  width: calc(100% - 2rem);
  margin-left: 1rem;
  margin-right: 1rem;
  border-bottom: 1px solid #eceff1;
  position: relative;
  top: -39px;
  padding-bottom: 10px;
  padding-top: 10px;
}
.visitTypeSection {
  @extend .section;
  display: flex;
  justify-content: space-between;
}
.visitReasonSection {
  @extend .section;
  display: flex;
  flex-direction: column;
  border-bottom: 0;
  padding-top: 12px;
  padding-bottom: 0;
}
.visitReasonText {
  border: 0;
  color: #37474f;
  background-color: #f9fafa;
  padding: 13px;
  resize: none;
  height: 75px;
  font-family: 'Metropolis';
  font-size: 14px;
  padding-bottom: 0;
}
.visitReasonLabel {
  height: 20px;
  color: #37474f;
  font-family: 'Metropolis';
  font-size: 16px;
  line-height: 20px;
  flex: none;
  margin-right: 16px;
  align-self: flex-start;
  margin-bottom: 13px;
  margin-top: 4px;
}
.visitTypeLabel {
  height: 20px;
  color: #37474f;
  font-family: 'Metropolis';
  font-size: 16px;
  line-height: 20px;
  flex: none;
  margin-right: 16px;
}
.visitType {
  height: 17px;
  color: #37474f;
  font-family: 'Metropolis';
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.avatar {
  width: 58px;
  height: 58px;
  border-radius: 50%;
  text-align: center;
  position: relative;
}
.initials {
  color: #ffffff;
  font-family: 'Metropolis';
  font-size: 19px;
  letter-spacing: -1px;
  font-weight: 500;
  background-color: #546e7a;
  left: calc(50% - 29px);
  margin-bottom: 8px;
}
.initialsLabel {
  margin-top: 0;
  padding-top: 13px;
}
.providerName {
  width: 100%;
  color: #5bc5d0;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  &.missed {
    color: #90a4ae;
  }
}
.useForRequestCancelAppointmentLabel {
  width: 100%;
  color: #5bc5d0;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  &.missed {
    color: #90a4ae;
  }
}
.specialty {
  height: 20px;
  color: #78909c;
  font-size: 14px;
  text-align: center;
  position: relative;
  top: -6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.officeName {
  height: 15px;
  color: #546e7a;
  font-size: 12px;
  text-align: center;
  padding-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.address {
  height: 15px;
  color: #546e7a;
  font-size: 12px;
  text-align: center;
}
.buttons {
  width: calc(100% - 2rem);
  margin-left: 1rem;
  margin-right: 1rem;
  position: relative;
  top: -30px;
  padding-top: 10px;
  & button {
    height: 48px !important;
    margin-top: 0px !important;
  }
  & div {
    justify-content: space-between;
    display: flex;
    & button {
      flex: 1;
      height: 48px !important;
      margin-top: 0px !important;
      margin-left: 5px;
    }
    & button:first-child {
      margin-right: 5px;
      margin-left: 0;
    }
  }
}
.buttonsWithVisitType {
  top: -29px;
  & a[id*='btnCheckInAtOfficeAppointment'] {
    height: 30px;
    line-height: 30px;
    margin-top: 0;
  }
  & [id*='btnCheckOutNowAppointment'] {
    color: #5bc5d0;
    border-color: #5bc5d0;
    box-shadow: 1px 1px 4px rgba(31, 155, 222, 0.4);
    border: 1px solid rgba(31, 155, 222, 0.4);
    width: 100%;
    display: block;
    background-color: #ffffff;
    padding: 8px;
    margin: 10px 0;
    cursor: pointer;
    outline: none;
  }
  & [id*='btnApprovedVertexAppointment'] {
    color: rgb(72, 202, 72);
    border-color: 24d63c;
    box-shadow: 1px 1px 4px rgba(31, 155, 222, 0.4);
    width: 100%;
    display: block;
    background-color: white;
    padding: 8px;
    margin: 10px 0;
    cursor: pointer;
    outline: none;
  }
}
.wrapperOverlay {
  height: 429px;
  width: 340px;
  border-radius: 10px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(55, 71, 79, 0.3);
  text-align: center;
  position: absolute;
  z-index: 1;
  top: 44px;
  animation: fadeIn 0.7s;
  & div svg {
    width: 22px !important;
    height: 22px;
    color: #d4d3d3;
    margin: 3px;
    cursor: pointer;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
.wrapperOverlay {
  height: 429px;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(55, 71, 79, 0.3);
  text-align: center;
  position: absolute;
  z-index: 1;
  top: 44px;
  animation: fadeIn 0.7s;
  & div svg {
    width: 22px !important;
    height: 22px;
    color: #d4d3d3;
    margin: 3px;
    cursor: pointer;
  }
}
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.disabled {
  cursor: not-allowed;
}
.noborder-bottom {
  border-bottom: none;
}

.noVisibility {
  visibility: hidden;
}
.pendingRequestMessage {
  color: #f5a623;
  font-weight: 600;
  margin-bottom: 40px;
  margin-top: 5px;
}
.commentsection {
  width: calc(100% - 2.5rem);
  margin-left: 0.8rem;
  border-bottom: 1px solid #eceff1;
  position: relative;
  top: -64px;
  padding: 8px;
  background-color: #eceff1;
  color: #546e7a;
  font-size: 12px;
  display: flex;
  margin-top: 0.5rem;
}
.popup {
  position: absolute;
  bottom: 30%;
  color: #546e7a;
  background-color: white;
  border: 1px solid #5bc5d0;
  font-weight: 700;
  justify-content: center;
  border-radius: 5px;
 box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
 text-align: center;
}
.commentsection:hover .popup {
  display: block;
  animation: cubic-bezier(0.68,-0.55,0.27,1.55) 2000ms forwards;

}
button{
  cursor: pointer;
  color: #5bd064;
  font-weight: 800;
}
.apptBox_for_vertex{
  // height: 500px;
  width: 340px;
  border-radius: 10px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(55, 71, 79, 0.3);
  text-align: center;
}
.apptBox_for_vertex_COmpleted{
  height: 510px;
  width: 340px;
  border-radius: 10px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(55, 71, 79, 0.3);
  text-align: center;
}
.commentTitle{
    margin-left: 0.8rem;
    position: relative;
    top: -42px;
    padding: 0px;
    color: #546e7a;
    font-size: 12px;
    display: flex;
}
.buttons_for_vertex{
  width: calc(100% - 2rem);
  margin-left: 1rem;
  margin-right: 1rem;
  position: relative;
  top: -60px;
  padding-top: 10px;
}
.appointmentHeading {
  background-color: #eceff1;
  margin: 0;
  padding: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: block;
  width: 97%;
  text-align: center;
}
.commentContainer {
  max-width: 340px;
  overflow-y: auto;
}

.cross_Button {
  left: 78px;
  position: relative;
  background-color: transparent;
  border: 2px solid red;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;
  top: -4px;
  }

  .cross_Button::before,
  .cross_Button::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60%;
    height: 2px;
    background-color: red;
    transform-origin: center;
  }
  .cross_Button::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  .cross_Button::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }