%linkIconBase {
  display: grid;
  /* autoprefixer: ignore next */
  justify-items: center;
  -ms-grid-column-align: center;
  & span {
    display: block;
    color: #78909c;
    margin-top: 5px;
    font-size: 16px;
    font-weight: 300;
    font-family: "Metropolis";
  }
  transition: 0.5s ease-in-out;
  & img :hover {
    transform: scale(1.2);
    transition: 0.5s ease-in-out;
  }
}
%nonLinkBase {
  grid-column: 1 / span 3;
  align-items: center;
  justify-content: center;
  justify-items: center;
  text-align: center;
}
.unsupportedBrowserContainer {
  width: 400px;
  height: 100vh;
  display: flex;
  align-items: center;
  margin: 0 auto;
  & div[id="unsupportedBrowserContainer"] {
    display: grid;
    grid-row-gap: 10px;
    & * {
      padding: 3px;
    }
    /* autoprefixer: ignore next */
    justify-content: center;
    /* autoprefixer: ignore next */
    align-items: center;
    margin: auto 0;
    & a {
      display: inline-block;
      grid-row: 5;
      text-decoration: none;
      cursor: pointer;
    }
    & div[id="unsupportedBrowserHeader"] {
      @extend %nonLinkBase;
      grid-row: 2;
      & h6 {
        color: #455a64;
        font-size: 24px;
        font-weight: 500;
        font-family: "Metropolis";
        align-self: center;
        justify-self: center;
      }
    }
    & div[id="unsupportedBrowserSubheaderGroup"] {
      @extend %nonLinkBase;
      font-size: 16px;
      grid-row: 3;
      line-height: 17px;

      & h6 {
        color: #78909c;
        font-size: 14px;
        font-weight: 300;
        font-family: "Metropolis";
        align-self: center;
        justify-self: center;
      }
    }
    & img[id="unsupportedBrowserSorryImage"] {
      @extend %nonLinkBase;
      grid-row: 1;
      justify-self: center;
    }
    & a[id="unsupportedBrowserEdgeIcon"] {
      @extend %linkIconBase;
      grid-column: 1;
    }
    & a[id="unsupportedBrowserChromeIcon"] {
      @extend %linkIconBase;
      grid-column: 2;
    }
    & a[id="unsupportedBrowserFirefoxIcon"] {
      @extend %linkIconBase;
      grid-column: 3;
    }
    & a[id="unsupportedBrowserSafariIcon"] {
      @extend %linkIconBase;
      grid-column: 4;
    }
  }
}
