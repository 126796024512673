%button {
  appearance: none;
  background-image: none;
  border: 1px solid #5BC5D0;
  border-radius: 6px;
  box-shadow: none;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  height: initial;
  line-height: 14px;
  overflow: hidden;
  padding: 12px;
  text-align: center;
  text-indent: 0;
  text-overflow: ellipsis;
  text-shadow: none;
  text-transform: uppercase;
  white-space: nowrap;
  width: auto;

  &.low {
    padding: 7px 12px;
  }

  &.short {
    width: 180px;
  }

  &.long {
    width: 100%;
  }
}

$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  %button {
    appearance: none;
    background-image: none;
    border: 1px solid #5BC5D0;
    border-radius: 6px;
    box-shadow: none;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    height: initial;
    line-height: 14px;
    overflow: hidden;
    padding: 12px;
    text-align: center;
    text-indent: 0;
    text-overflow: ellipsis;
    text-shadow: none;
    text-transform: uppercase;
    white-space: nowrap;
    width: auto;
  
    &.low {
      padding: 7px 7px;
    }
  
    &.short {
      width: 180px;
    }
  
    &.long {
      width: 100%;
    }
  }
}
.button-primary {
  @extend %button;

  background-color: #5BC5D0;
  border: none;
  color: #FFF;

  &.destructive {
    background-color: #E22B41;
    border-color: #E22B41;
  }

  &.highlight {
    background-color: #5BD064;
    border-color: #5BD064;
  }

  &:disabled {
    background-color: #CFD8DC;
    border-color: #CFD8DC;
  }
}

.button-secondary {
  @extend %button;

  color: #5BC5D0;
  background-color: #FFF;

  &.destructive {
    color: #E22B41;
    border-color: #E22B41;
  }

  &.highlight {
    color: #5BD064;
    border-color: #5BD064;
  }

  &:disabled {
    color: #CFD8DC;
    border-color: #CFD8DC;
  }
}
