.label {
  width: 100%;
  text-transform: uppercase;
  text-align: left;
  font-size: 10px;
  color: #546e7a;
  font-family: "Metropolis", sans-serif;
  font-weight: 800;
  letter-spacing: 0.06em;
}
.errorMessage {
  position: relative;
  color: rgb(255, 51, 51);
  text-align: left;
  top: 0px;
  font-weight: 300;
}
.required {
  font-size: 20px;
  font-weight: 800;
  width: 100%;
  margin-left: 5px;
  color: rgb(255, 51, 51);
  text-align: left;
}
.isSelected { color: #5bc5d0; }
.radioLabel {
  line-height: 27px;
  text-transform: none;
  font-size: 16px;
  color: #546e7a;
  font-family: "Metropolis", sans-serif;
  font-weight: normal;
  display: flex;
  align-items: center;
}
.radioButtons { border-bottom: 1px solid #DDDDDD; }
.radioButtonsBoolean {
  display: flex;
  & > div:first-of-type { flex: 7; }
  & > label { flex: 1; }
  border-bottom: none;
}
.radioContainer {
  & input { cursor: pointer }
  & input[type="radio"]:checked:before {
    z-index: 1;
    background-color: #ffffff;
    top: 11px;
    right: 5px;
    border: 8px solid #5bc5d0;
    box-sizing: content-box;
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
  }
  & input[type="radio"]:after {
    width: 17px;
    height: 17px;
    border-radius: 15px;
    top: -3.5px;
    left: -3px;
    position: relative;
    background-color: #fff;
    content: "";
    display: inline-block;
    visibility: visible;
    border: 2px solid #c9d2d7;
  }
  & input[type="radio"]:checked + div { color: #51bdca; }
  & input[type="radio"]:checked + div svg > g > g { fill: #51bdca; }
  & input[type="radio"]:checked + div svg g > g#PayPal path:first-of-type { fill: #51bdca; }
  & input[type="radio"]:checked + div svg g > g#PayPal path:nth-of-type(3) { fill: #36a5b2; }
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  text-transform: capitalize;
  font-size: 15px;
  color: #597583;
  font-weight: 500;
  font-family: "Metropolis";
  align-items: center;
  padding: 9px 9px 10px 0px;
  position: relative;
  height: 25px;
}
.supportingText { color: #a6a6a6; }
.supportingTextGreen { color: #4CAF50; }
