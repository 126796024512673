.guestPayWrapper {
  width: 100% !important;
  padding: 0 !important;
  & div[id*="makePaymentsContent"] {
    padding: 30px;
    & div[id="makePaymentsCenterContent"] {
      margin: 40px auto 0px;
      width: 100%;
      & div[class*="makePaymentsType"] {
        width: 100%;
      }
      & div[class*="partialPayment"] {
        width: 100%;
        & input[class*="partialPayment"] {
          width: 50%;
        }
      }
    }
    & div[id="makePaymentsRightContent"] {
      margin: 0 auto;
      width: 100%;
      & div[class*="container"] {
        width: 100%;
      }
    }
  }
  & div[id="makePaymentsAddressContainer"] {
    width: 75%;
    & div[id="makePaymentsPractice"] {
      & h3 {
        margin-bottom: 0;
      }
    }
  }
  & div[id="makePaymentsAddress"] {
    width: 100%;
  }
  & div[class*="avatar"] {
    margin-top: 20px;
  }
}
