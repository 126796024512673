@import '../../styleguide/variables';
.careTeamAnchor,
.conditionsAnchor,
.medicationsAnchor,
.allergiesAnchor,
.labsAnchor {
  width: 36px;
  height: 36px;
  position: relative;
  & .tooltip {
    display: none;
    width: max-content;
    padding: 0 5px;
    background-color: #fff;
    color: #000;
    text-align: center;
    position: absolute;
    top: 10px;
    left: 38px;
    border-radius: 4px;
    border: 1px solid #eee;
    box-shadow: 1.5px 1.5px #cfd8dc;
    z-index: 1;
    font-family: 'Metropolis', sans-serif;
  }
  &:hover .tooltip {
    display: inline-block;
  }
}
.block {
  display: block;
}
.patientPortalMobileAppLogo {
  & > img {
    width: 120px;
  }
}
.footerLogo {
  & > img {
    display: flex;
    width: 120px;
  }
}
.loginLink {
  font-size: 11px;
  line-height: 13px;
  text-decoration: none;
}
.noUnderline {
  text-decoration: none;
}
[id*='sidebarItemIcon'] {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8px;
  gap: 4px;
  height: 56px;
  box-sizing: border-box;
  & p {
    margin: 0;
    font-weight: 600;
    font-size: 11px;
    line-height: 11px;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: #aab9c1;
    text-decoration: none;
  }
  & img {
    width: 24px;
    height: 24px;
  }
  &[class*='active'] {
    border-left: 8px solid $main-color;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: $gray-0;
    & p {
      color: $main-color;
    }
  }
  transition: all 0.25s;
  &:hover {
    border-left: 8px solid $main-color;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: $gray-0;
    & p {
      color: $main-color;
    }
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .footerLogo {
    & > img {
      display: none;
    }
  }
  [id*='sidebarItemIcon']{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 8px;
    gap: 3px;
    height: 47px;
    box-sizing: border-box;
    & p {
      margin: 0;
      font-weight: 600;
      font-size: 9px;
      line-height: 11px;
      text-align: center;
      letter-spacing: 0.01em;
      text-transform: capitalize;
      color: #AAB9C1;
      text-decoration: none;
    }
    & img{
      width: 20px;
      height: 20px;
    }
    &[class*='active']{
      border-left: 8px solid $main-color;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      background-color: $gray-0;
      & p {
        color: $main-color;
      }
    }
    transition: all 0.25s;
    &:hover{
      border-left: 8px solid $main-color;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      background-color: $gray-0;
      & p {
        color: $main-color;
      }
    }
  }
}
[id*='quickAction_']{
  background-color: $white;
  height: 48px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  gap: 14px;
  align-items: center;
  box-sizing: border-box;
  box-shadow: none;
  width: fit-content;
  border-radius: 6px;
  border: 1px solid $main-color;
  & img {
    width: 20px;
    height: 20px;
    color: #aab9c1;
  }
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #777d80;
  text-decoration: none;
}
$breakpoint-tablet: 550px;
@media (max-height: $breakpoint-tablet) {
  [id*='quickAction_'] {
    background-color: $white;
    height: 48px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    gap: 14px;
    align-items: center;
    box-sizing: border-box;
    box-shadow: none;
    width: fit-content;
    border-radius: 6px;
    border: 1px solid $main-color;
    & img {
      width: 20px;
      height: 20px;
      color: #aab9c1;
    }
    font-weight: 550;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: #777d80;
    text-decoration: none;
  }
}

$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
 [id*='quickAction_']{
  background-color: $white;
  height: 40px;
  display: flex;
  padding: 0 7px;
  justify-content: space-between;
  gap: 0px;
  align-items: center;
  box-sizing: border-box;
  box-shadow: none;
  width: 40px;
  border-radius: 6px;
  border: 1px solid $main-color;
  margin-bottom: 11px;
  & img{
    width: 25px;
    height: 25px;
    color: #AAB9C1;
  }
  font-weight: 550;
  font-size: 0px;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #777D80;
  text-decoration: none;
}
}
.careTeamBtnMessage,
.careTeamBtnAddAppointment {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  padding: 7px 10px;
  height: 34px;
  border-radius: 5px;
  box-sizing: border-box;
  font-family: 'Metropolis';
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #FFFFFF;
  text-decoration: none;
}
.careTeamBtnMessage {
  background: #70ad47;
}
.careTeamBtnAddAppointment {
  background-color: #ed7d31;
}
