@import '../../styleguide/variables';

.wrapper {
  display: flex;
  flex-wrap: wrap;
  & div {
    box-sizing: border-box;
  }
}
.historyWrapper {
  height: 125px;
  position: relative;
}
.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  box-shadow: 0 2px 4px 0 $box_shadow_gray;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  padding: 13px;
  margin-bottom: 10px;
  margin-top: 24px;
  margin-right: 20px;
  transition: all 0.2s;
  &:hover {
    --webkit-box-shadow: 0px 0px 26px 3px $lighter_black;
    --moz-box-shadow: 0px 0px 26px 3px $lighter_black;
    box-shadow: 0px 0px 26px 3px $lighter_black;
    transition: all 0.2s;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
.container {
  margin-left: 4%;
}

}
.date {
  color: $gray-3;
  font-weight: 500;
  margin-right: 8px;
  text-transform: capitalize;
}
.perMonth {
  font-weight: 400;
  font-size: 12px;
  color: $gray-3;
}
.progressContainer {
  margin: 0;
}
.payment {
  font-family: 'Metropolis';
  color: $gray-3;
  font-size: 12px;
}
.total {
  color: $main-color;
  font-size: 17px;
  margin-right: 6px;
  line-height: 0;
  font-weight: 500;
  font-family: 'Metropolis';
  margin: 12px 0;
  letter-spacing: 0;
}
.message {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Metropolis';
  text-align: left;
  position: relative;
  top: -6px;
  line-height: 0;
  color: $gray-5;
  margin: 18px 0px 18px 0px;
}
.processing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 2px;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid $gray-3;
  margin-left: 8px;
  height: 17px;
  width: 69px;
  text-align: center;
  color: $gray-4;
  text-transform: uppercase;
  font-size: 9px;
  align-self: center;
}
.blue {
  color: $main-color;
  font-size: 17px;
  font-weight: 600;
}
.avatar {
  margin: 0;
}
.leftSide {
  display: flex;
  align-items: center;
}
.rightSide {
  display: flex;
  align-items: center;
}
.leftVertical {
  display: flex;
  flex-direction: column;
  margin-left: 9px;
}
.paymentProcessing {
  display: flex;
  align-items: baseline;
  position: relative;
  top: 6px;
}
