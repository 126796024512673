@import '../../styleguide/variables';

.addAppointmentContainer {
  display: flex;
  flex-direction: column;
}
.addAppointmentPracticeContainer {
  min-width: 310px;
  margin-left: auto;
}
.addAppointmentPracticeLabel {
  font-size: 15px;
  color: #597583;
  font-weight: 500;
  font-family: 'Metropolis';
}
.practiceNotSelectedError {
  display: inline;
  position: fixed;
  align-self: center;
  background-color: #e22b41;
  padding: 6px 18px;
  font-size: 15px;
  font-weight: bold;
  color: white;
  border-radius: 12px;
}
.contentContainer {
  margin-top: 45px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #cfd8dc;
  & [class*='locationContainer'] {
    margin-top: 15px;
    & > div {
      height: 386px;
      margin-top: 4px;
    }
  }
  & [class*='labelContainer'] {
    max-width: 160px;
  }
  & [class*='fullWidthLabelContainer'] {
    max-width: 286px;
  }
  & [class*='horizontal'] {
    flex-wrap: unset;
    justify-content: space-between;
  }
  & [id*='providerPracticeSelectionDropdowninputDivWrapper'] {
    margin: 0 0 17px 0;
    & [class*='control'] {
      height: 52px;
      border: 1px solid #e4e8eb !important;
      border-radius: 3px;
      &:active {
        outline: none;
      }
      & [class*='value'] {
        padding: 8px 0 0 0 !important;
      }
    }
    & [class*='value'] {
      padding: 4px 0 0 0;
    }
    & [class*='value-label'] {
      margin: 0 0 0 18px;
      color: #90a4ae !important;
      font-size: 14px;
    }
  }
}
.column {
  display: flex;
  flex-direction: column;
  width: 360px;
  align-items: flex-start;
  height: 547px;
  margin-right: 1.5rem;
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .column {
    display: flex;
    flex-direction: column;
    width: 96%;
    align-items: center;
    height: auto;
    margin-right: 0;
    & > div[id*='emptyBox'] {
      margin-left: 10%;
    }

  }
}
.button {
  background-color: #cfd8dc;
  color: white;
  height: 48px;
  width: 342px;
  border-radius: 6px;
  text-transform: uppercase;
  font-family: 'Metropolis', sans-serif;
  font-size: 13px;
  font-weight: 500;
  margin-left: calc(1.5rem + 5px);
  align-self: center;
  margin-top: 15px;
  cursor: not-allowed;
}
.enabled {
  background-color: #5bd064;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    background-color: #47ca51;
  }
}
.filterInput {
  box-sizing: border-box;
  padding: 0 18px;
  width: 337px;
  border-radius: 3px;
  box-shadow: none;
  height: 52px;
  border: 1px solid #e4e8eb;
  margin-bottom: 17px;
  font-size: 14px;
  font-weight: 300;
  color: #90a4ae;
  text-transform: capitalize;
}
.locationContainer {
  margin-top: 12px;
  & input::placeholder {
    color: #90a4ae;
    font-size: 14px;
    font-weight: 300;
  }
}
.cardContainer {
  height: 468px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 13px 13px 13px;
  margin-top: 13px;
  transform: translate(-13px, -1px);
  &:nth-child(3) {
    height: 0;
    margin-top: 5px;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .cardContainer {
    height: 382px;
    overflow-y: auto;
    overflow-x: hidden;
    width: 90%;
    padding: 0 10px;
    margin-top: 10px;
    transform: none;
    & div[class*='emptyBox']{
      box-sizing: border-box;
      width: 365px;
      height: 250px;
      background-color: rgba(236, 239, 241, 0.2);
      border: 1px dashed #cfd8dc;
      border-radius: 10px;
      margin-left: 10%;
      text-align: center;
      float: left;
      margin-bottom: 10px;
      margin-top: 22px;
      padding-top: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      & h3{
        margin-top: 0;
      }
    }
  }

}
.visitType {
  color: $main-color_darker;
}

$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  /* Default styles */
  .addAppointmentContainer {
    display: flex;
    flex-direction: column;
  }

  .addAppointmentPracticeContainer {
    min-width: 280px;
    margin-left: auto;
  }

  .addAppointmentPracticeLabel {
    font-size: 15px;
    color: #597583;
    font-weight: 500;
    font-family: 'Metropolis';
  }

  .practiceNotSelectedError {
    display: inline;
    position: fixed;
    align-self: center;
    background-color: #e22b41;
    padding: 6px 18px;
    font-size: 15px;
    font-weight: bold;
    color: white;
    border-radius: 12px;
  }

  .contentContainer {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: left;
    border-bottom: 1px solid #cfd8dc;
    & div[class*='column'] {
      display: flex;
      flex-direction: column;
      width: 96%;
      align-items: center;
      height: 470px;
      margin-right: 0;
      & > div[id*='emptyBox'] {
        margin-left: 10%;
      }
  
    }
  }

  .locationContainer > div {
    height: auto;
    margin-top: 0;
  }

  .labelContainer {
    max-width: 160px;
  }

  .fullWidthLabelContainer {
    max-width: 286px;
  }

  .horizontal {
    flex-wrap: unset;
    justify-content: space-between;
  }

  [id*='providerPracticeSelectionDropdowninputDivWrapper'] {
    margin: 0 0 17px 0;
  }

  [id*='providerPracticeSelectionDropdowninputDivWrapper'] .value-label {
    font-size: 13px;
  }


  .button {
    background-color: #cfd8dc;
    color: white;
    height: 48px;
    width: 85%;
    margin-bottom: 20%;
    border-radius: 6px;
    text-transform: uppercase;
    font-family: 'Metropolis', sans-serif;
    font-size: 13px;
    font-weight: 500;
    align-self: center;
    margin-top: 15px;
    cursor: not-allowed;
  }

  .enabled {
    background-color: #5bd064;
    cursor: pointer;
    transition: 0.2s;
  }

  .enabled:hover {
    background-color: #47ca51;
  }

  .filterInput {
    box-sizing: border-box;
    padding: 0 18px;
    width: 100%;
    border-radius: 3px;
    box-shadow: none;
    height: 52px;
    border: 1px solid #e4e8eb;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 300;
    color: #90a4ae;
    text-transform: capitalize;
  }

  .locationContainer {
    margin-top: 10px;
  }

  .locationContainer input::placeholder {
    font-size: 14px;
  }

  .cardContainer:nth-child(3) {
    height: auto;
    margin-top: 5px;
  }

  .visitType {
    color: $main-color_darker;
  }
}