@import '../../styleguide/variables';

.wrapper {
  margin-top: -130px;
  display: flex;
  justify-content: flex-end;
  padding-right: 1rem;
  padding-left: 1rem;
  position: sticky;
  bottom: 20px;
  margin-right: 0;
  animation: fadeIn 0.4s;
  z-index: 0;
  transition: all 0.4s;
  margin-bottom: 10px;
  right: 0;
  opacity: 1;
}
.message {
  display: flex;
  z-index: 0;
  border-radius: 10px;
  padding-left: 1rem;
  padding-right: 1rem;
  --webkit-box-shadow: 0px 0px 26px 3px $lighter_black;
  --moz-box-shadow: 0px 0px 26px 3px $lighter_black;
  box-shadow: 0px 0px 26px 3px$lighter_black;
  align-items: center;
  transition: all 0.4s;
  &:hover {
    transform: scale(1.01);
    --webkit-box-shadow: 0px 0px 26px 13px $lighter_black;
    --moz-box-shadow: 0px 0px 26px 13px $lighter_black;
    box-shadow: 0px 0px 26px 13px $lighter_black;
  }
  & p {
    margin: 1em auto;
  }
  & i {
    margin-left: 0.75rem;
    cursor: pointer;
  }
}
.error {
  background-color: $destructive;
  color: $white;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.success {
  background-color: $highlight;
  color: $white;
  height: 66px;
  width: 375px;
  font-size: 15px;
  & svg {
    font-size: 25px;
  }
  & p {
    margin-right: auto;
    margin-left: 1em;
  }
}
.transform {
  opacity: 0;
}
.center {
  position: fixed;
  top: 157px;
  left: 50%;
  right: unset;
  height: 66px;
  transform: translateX(-50%);
}
