@import '../../styleguide/variables';

.modal-open {
  overflow-y: unset;
}
.container {
  border-radius: 10px;
}
.slideIn {
  margin: calc(100vh / 2 - 150px) auto;
  background: $white;
  width: 320px;
}
.closeIcon {
  top: calc(100vh / 2 - 180px);
  position: absolute;
  color: $gray-2;
  cursor: pointer;
}
.header {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 15px 0;
  background: $highlight;
  & h4 {
    color: $white;
    text-align: center;
    font-weight: 400;
  }
}
.message {
  padding: 10px;
  & p {
    color: $gray-5;
    text-align: center;
    font-size: 16px;
    font-weight: 300;
    &:after {
      display: inline-block;
      content: '';
      width: 200px;
      height: 0;
      border-top: 1px solid $gray-1;
    }
  }
}
.actionSection {
  padding-bottom: 15px;
}
.actionButton {
  text-align: center;
  width: 250px;
  border: 1px solid $highlight;
  border-radius: 8px;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 16px;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  cursor: pointer;
  color: $highlight;
  font-family: 'Metropolis';
}
