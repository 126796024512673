
@import '../../styleguide/variables';
.modal-open {
  overflow-y: hidden !important;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 12;
  background-color: $modal_backdrop;
  transition: all 0.4s;
  opacity: 0;
  animation: fadeIn 0.3s;
}
.fadeIn {
  opacity: 1;
}
.scrollableModalContent {
  max-height: 40vh;
  overflow: auto;
  background-color: $white;
}
.innerContent {
  max-height: 920px;
  overflow: auto;
  & p {
    color: #ffffff;
    text-align: center;
    font-size: 18px;
  }
}
.createVisitSummaryModal {
  & button[id*='exportCreateVisitSummary'] {
    width: 100%
  }
  & button[id='submitCreateVisitSummary']{
    display: flex;
    width: 50% !important;
    margin-left: auto !important;
    justify-content: center;
    align-items: center;
  }
  & button[id='viewCreateVisitSummary'], & button[id='downloadCreateVisitSummary']{
    width: 30% !important;
    margin: 0 2px;
  }
  & div[class*='leftSideWrapper'] {
    padding: 0 2rem !important;
    margin-left: 0 !important;
    & div[id='visitSummaryListContainer']{
      margin: 1rem auto;
      width: 95%;
      height: 150px;
      border: 1px solid #cfd8dc;
      padding: 1rem;
      border-radius: 8px;
      overflow: auto;
    }
    & div[class*='supportingText']{
      margin-bottom: 20px !important;
      &:after {
        display: none !important;
        content: '';
        border-top: 1px solid #c8d2d7 !important;
        position: relative;
        top: 64px;
        right: 80px;
        width: 100%;
      }
    }
  }
  & div[class*='headingWrapper'] {
    background: $main-color;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 0;
    z-index: 1;
    & div h6 {
      color: $white;
    }
  }
  & div[id*='createVisitSummaryFormatWrapper'] {
    height: unset;
  }
  & div[id*='radios'] {
    width: 98%;
  }
  & div[id='visitSummaryGroupBtns']{
    justify-content: flex-end;
    margin: 1rem 0;
  }
  & Button[id='View_CC_TBI_CreateVisitSummary']{
    width: 100%;
    display: flex;
    margin-left: auto !important;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: #5bc5d0;
    cursor: pointer;
    margin-bottom: 10px;
    height: 40px !important;
    &[disabled] {
      color: #cfd8dc;
      font-family: "Metropolis";
      border: 1px solid #cfd8dc;
      border-color: #cfd8dc;
      box-shadow: none;
      font-size: 13px;
      font-weight: 500;
      background-color: white;
    }
  }
}
.container {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  max-width: 700px;
  width: 500px;
  max-height: calc(100vh - 60px);
  height: fit-content;
  background-color: $white;
  z-index: 100;
  border-radius: 13px;
  box-shadow: 0 2px 4px 0 $box_shadow_gray;
  transition: transform 0.7s, opacity 0.4s;
  transform: translate(-50%, -70%);
  opacity: 0;
  animation: fadeIn 0.6s, slideIn 0.6s;
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .container {
  max-width: 85%;
  }
  .createVisitSummaryModal {
    & button[id='viewCreateVisitSummary'], & button[id='downloadCreateVisitSummary']{
      width: 35% !important;
    }
    & div[class*='leftSideWrapper'] {
      padding: 0 1rem !important;
    }
  }
}
.makePaymentsCancelPayment {
  width: 350px;
  background: none;
}
.deleteMsgModal {
  width: 350px;
}
.makePaymentsSuccess {
  width: 326px;
  background: none;
}
.cancelConfirmationSuccess {
  width: 326px;
  background: none;
}
.checkInSuccess {
  @extend .makePaymentsSuccess;
}
.pendingPayment {
  background: none;
}
.requestApptSuccessModal {
  width: 326px;
  background-color: transparent;
}
.cancelApptSuccessModal {
  width: 326px;
  background-color: transparent;
}
.makePaymentsSuccessNext {
  @extend .makePaymentsSuccess;
  background: none;
}
.appointmentPaymentDone {
  @extend .makePaymentsSuccess;
}
.checkoutDoneModal {
  @extend .makePaymentsSuccess;
}
.reconcileAllergiesModal,.reconcileMedicationmodal{
  @extend .makePaymentsSuccess
}
.modalBase {
  width: 326px;
  border-radius: 6px;
}
.delegateVerificationErrorModal {
  @extend .modalBase;
}
.delegateLoginErrorModal {
  @extend .modalBase;
  & [id*='customMessage'] {
    padding-top: 60px;
  }
}
.delegateCreateAccountErrorModal {
  @extend .modalBase;
}
.delegateCreateAccountSuccessModal {
  @extend .modalBase;
}
.delegateForgotPasswordErrorModal {
  @extend .modalBase;
}
.creditCardDeleteErrorModal {
  @extend .modalBase;
  border-radius: 13px;
  & [id*='creditCardDeleteErrorModalContent'] {
    display: flex;
    justify-content: center;
    color: $gray-6;
    font-weight: 600;
    text-align: center;
    font-size: 16px;
    padding: 30px 15px 15px;
  }
}
.apolloErrorModal {
  height: 25%;
  width: 25%;
}
.guestPayErrorModal {
  @extend .modalBase;
}
.guestPayNoCodeErrorModal {
  @extend .modalBase;
}
.cashPayModal {
  @extend .modalBase;
}
.selectOptionModal {
  width: 326px;
  border-radius: 10px;
}
.registerErrorModal {
  width: 326px;
  border-radius: 10px;
}
.registerPreviousAccount {
  width: 326px;
  border-radius: 10px;
}
.slideIn {
  transform: translate(-50%, -50%);
  transition: transform 0.7s, opacity 0.4s;
  opacity: 1;
}
.closeIcon {
  position: absolute;
  color: $gray-2;
  top: -30px;
  cursor: pointer;
  outline: 0;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes slideIn {
  0% {
    transform: translate(-50%, -70%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}
.cancelEnabled {
  & [id*='cancelAppointmentCancelButton'] {
    color: $destructive;
    border: 1px solid $destructive;
    pointer-events: visible;
    cursor: pointer;
  }
  & [id*='cancelAppointmentProceedPaymentButton'] {
    background-color: $highlight;
    color: $white;
    pointer-events: visible;
    cursor: pointer;
  }
}
.otherReasonEnabled {
  & div[class*='otherReasonInput'] > label {
    color: $gray-7;
  }
  & [class*='otherReasonInput'] > div[class*='inputWrapper'] > input {
    pointer-events: visible;
    border-bottom: 1px solid $light_gray_4;
  }
}
.customMessage {
  background: $gray-8;
  padding: 10px 30px;
  border-radius: 10px 10px 0px 0px;
  & p {
    color: $white;
    font-size: 18px;
    text-align: center;
    margin: 0;
    line-height: 23px;
  }
  & h4 {
    font-size: 73px;
    font-weight: 500;
    font-family: 'gotham-rounded';
    margin: 20px auto;
    padding: 8px 0;
    color: $main-color;
    text-align: center;
  }
}
.manageProfilePracticeExpandedMergePopupExpanded {
  width: 280px;
  box-shadow: none;
  border: 1px solid $gray-7;
  border-radius: 6px;
  padding: 20px;
}
.mergeDelegateProfileMaybeConfirmationModal {
  @extend .customMessage;
  border-radius: 10px;
  padding: 0;
  width: 350px;
  background-color: $gray-8;
  & div[class*='scrollableModalContent'] {
    overflow: unset;
  }
}
.disconnectDelegateProfileMaybeConfirmationModal {
  @extend .mergeDelegateProfileMaybeConfirmationModal;
}
.manageProfilesErrorModal {
  width: 350px;
}
.clientSideModalContainer {
  width: 350px;
  background-color: $white;
}
.clientSideModalHeaders {
  background-color: $gray-8;
  border-radius: 12px 12px 0px 0px;
}
.clientSideModalButtonContainer {
  margin: 15px auto;
  background-color: $white;
  & button {
    margin: 0 10px;
    padding: 0px 15px;
    font-weight: 300;
  }
}
.twofaDashboardModal,
.completeRegistrationModal {
  overflow: hidden;
}
.signInOTPModal,
.verifyOTPChangeEmailModal,
.verifyOTPUpdatePhoneModal {
  padding: 24px;
}
.errorMessage {
  & h6 {
    color: #e22b41;
    font-weight: 400;
    font-size: 13px;
    margin: auto;
    text-align: left;
  }
}