@import '../../styleguide/variables';

.radioInput {
  cursor: pointer;
}
.paymentPlanContainer {
  & input::-webkit-input-placeholder {
    color: $gray-3;
    font-size: 14px;
    font-weight: 300;
  }
}
.radioContainer {
  & input[type='radio']:checked:before {
    z-index: 1;
    background-color: $white;
    top: 14px;
    right: 5px;
    border: 8px solid $main-color;
    box-sizing: content-box;
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    position: absolute;
  }
  & input[type='radio']:checked + div {
    color: $main-color;
  }
  & input[type='radio']:after {
    width: 17px;
    height: 17px;
    border-radius: 15px;
    top: -3.5px;
    left: -3px;
    position: relative;
    background-color: $white;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid $gray-2;
  }
  display: flex;
  flex-direction: row-reverse;
  border-bottom: 1px solid lightgray;
  justify-content: space-between;
  text-transform: capitalize;
  font-size: 15px;
  color: $gray-6;
  font-weight: 500;
  font-family: 'Metropolis';
  align-items: center;
  padding: 9px 9px 10px 0px;
  position: relative;
  margin-top: 20px;
  height: 32px;
  cursor: pointer;
}
.existingPlan {
  border-bottom: 1px solid lightgray;
}
.radio {
  position: relative;
}
.labelContainer {
  display: flex;
  align-items: center;
  & div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 300;
  }
}
.planName {
  color: $gray-4;
  font-style: italic;
  font-size: 12px;
}
.planDetails {
  display: flex;
  justify-content: space-between;
  color: $gray-5;
  font-size: 11px;
  padding: 9px 16px 9px 0px;
}
.existingPlanContainer {
  @extend .radioContainer;
  padding-bottom: 0;
  padding-top: 12px;
  border-bottom: 0;
  margin-top: 0;
  height: 25px;
}
.addToPlanContainer {
  @extend .radioContainer;
  padding-bottom: 12px;
  padding-top: 12px;
  margin-top: 0;
  height: 25px;
}
.left {
  display: flex;
}
.leftLabelContainer {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  width: 30px;
}
.right {
  flex-basis: 200;
  & span {
    font-weight: bold;
  }
}
.choosePayment {
  color: $gray-8;
  font-size: 20px;
  margin-top: 20px;
}
.detailHeader {
  font-size: 9px;
  color: $gray-7;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 0.06em;
  font-family: 'Metropolis';
  margin-top: 5px;
}
.planNameInput {
  width: 80%;
  border: 0;
  color: $main-color;
  font-size: 16px;
  font-family: 'Metropolis';
  margin-top: 5px;
  padding-bottom: 7px;
}
.optional {
  border: 1px solid $gray-2;
  padding: 2px 6px;
  color: $gray-2;
  font-size: 9px;
  font-weight: 600;
  border-radius: 4px;
  text-transform: uppercase;
  height: 15px;
}
.inputContainer {
  border-bottom: 1px solid $gray-2;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.detailContainer {
  display: flex;
  justify-content: space-between;
}
.dateContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.monthContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 7px;
}
.monthsNumber {
  border: 0;
  border-bottom: 1px solid $gray-2;
  color: $main-color;
  font-size: 16px;
  font-family: 'Metropolis';
  padding: 7px 0px;
  width: 100%;
  margin-bottom: 10px;
}
.disabled {
  color: $gray-2;
}
.paymentDescription {
  color: $gray-7;
  line-height: 1.25;
  font-size: 11.5px;
  margin-top: 10px;
}
.error {
  color: red;
}
.existingPlanNotice {
  color: $gray-7;
  font-size: 11px;
  line-height: 1.25;
  margin: 30px 0 20px 0;
}
.frequencyDisabled {
  & div {
    pointer-events: none;
    & span.Select-value-label {
      color: $gray-2 !important;
    }
  }
}
.lastPaymentAdjustment {
  color: $gray-7;
  line-height: 1.25;
  font-size: 11.5px;
  margin-top: 10px;
}
.unselectablePlan {
  & div[class*='labelContainer'],
  input[class*='radioInput'] {
    cursor: not-allowed;
  }
  & div[class*='labelContainer'],
  & div[class*='planDetails'],
  & div[class*='planName'] {
    color: $gray-2;
  }
}
