.progressWrapper {
  display: flex;
  justify-content: center;
  position: relative;
}
%progressBase {
  height: 58px;
  width: 58px;
  position: absolute;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 1px 0 #cfd8dc;
}
.progressContainer {
  @extend %progressBase;
}
.paymentSectionProgress {
  height: 58px;
  width: 58px;
  position: absolute;
  background-color: #fff;
  bottom: -14px;
  right: calc(50% + 115px);
  border-radius: 50%;
  transform: translateX(320%);
  box-shadow: 0 0 1px 0 #cfd8dc;
}
.paymentHistorySectionProgress {
  position: relative;
  background-color: #fff;
}
.paymentSectionCollapsed {
  position: relative;
  margin: 0px;
  box-shadow: none;
  & div {
    margin-right: 0px;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .paymentSectionCollapsed {
    margin-left: 4%;
    position: relative;
    box-shadow: none;
    & div {
      margin-right: 0px;
    }
  }
}
.modalProgress {
  @extend %progressBase;
  top: -30px;
  left: 0px;
  transform: translateX(368px);
}
