.formWrapper {
  padding-top: 1rem;
  display: flex;
  & > div:first-child {
    flex: 3;
    padding-right: 1rem;
  }
  & > div:last-child {
    flex: 2;
    margin-left: 1rem;
  }
  & [id="emergencyContactAddressLineTwo"] {
    width: 330px;
  }
  & [id="emergencyContactZipCode"] {
    width: 330px;
  }
}
.heading {
  display: flex;
  align-items: center;
  & i {
    font-weight: bold;
    height: 9px;
    width: 10px;
    color: #5bc5d0;
  }
}
%leftSideWrapperBase {
  display: flex;
  flex-direction: column;
  & div[class*="select"] {
    margin-bottom: 15px;
  }
  & div.Select-option.is-selected {
    margin-bottom: 0;
  }
  & div.is-searchable {
    & div.Select-placeholder {
      color: #a7b6be;
      padding-left: 0;
      font-family: 'Metropolis';
      font-size: 16px;
      font-weight: 300;
    }
    & span.Select-value-label {
      font-weight: 300;
    }
    & div.Select-input {
      padding-left: 0;
      font-weight: 300;
      height: 30px;
      & > input {
        padding-top: 0;
      }
    }
    & div.Select-control {
      height: 30px;
    }
  }
}
.leftSideWrapper {
  @extend %leftSideWrapperBase;
}
.subHeading {
  padding-bottom: 1rem;
}
.divider {
  box-sizing: border-box;
  margin-bottom: 1rem;
  width: 1px;
  border: 1px solid rgba(236, 239, 241, 0.55);
}
.multiInput {
  display: flex;
  & > div:first-child {
    width: 70%;
    padding-right: 1rem;
  }
  & > div:last-child {
    width: 30%;
  }
}
%addMoreBase {
  height: 13px;
  color: #5bc5d0;
  font-family: 'Metropolis';
  font-size: 11;
  font-weight: 600;
  line-height: 13px;
  text-align: center;
  cursor: pointer;
}
.addMore {
  @extend %addMoreBase;
}
.remove {
  @extend %addMoreBase;
  color: #e22b41;
  padding-right: 1rem;
}
.profileSettingsFormWrapper {
  @extend %leftSideWrapperBase;
  padding-right: 110px;
}

// #region Make Payments - One-Time Payment
//////////////////////////////////////////
// Make Payments - One-Time Payment //////
/////////////////////////////////////////
.makePaymentsOneTimePayment {
  & [class*="formWrapper"] {
    padding: 0;
  }
  & [class*="leftSideWrapper"] {
    padding: 0 !important;
    margin-left: 0 !important;
  }
  & [class*="heading"] {
    display: none;
  }
  & [class*="DateInput_input"] {
    display: inline-block !important;
  }
  & div[class="DayPicker"] {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    border: 1px solid #cfd8dc;
    border-radius: 6px;
    overflow: hidden;
  }
  & div[class*="activeCalendarWrapper"] {
    display: flex;
    flex-direction: column;
  }
  & [class*="buttonsWrapper"] {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    background-color: #fff;
    border: 1px solid #cfd8dc;
    border-top: none;
    border-radius: 0, 0, 6px, 6px;
    margin-left: 0;
    margin-top: 0;
    & button:first-child {
      background-color: transparent;
    }
  }
  & [class*="SingleDatePicker_picker"] {
    /* autoprefixer: ignore next */
    display: contents;
    z-index: 999;
  }
  & [class*="multiInput"] {
    flex-wrap: wrap;
  }
  & [class*="SingleDatePickerInput_calendarIcon"] {
    display: none;
  }
  & [class*="pickADate"] {
    padding: 50px 0px 0px 20px;
  }
  & [class*="SingleDatePickerInput"] {
    margin-bottom: 20px;
    & div.DateInput {
      &:after {
        content: "";
        display: block;
        margin-top: 5px;
        width: 340px;
        border-bottom: 1px solid #cfd8dc;
      }
    }
    & input[class*="DateInput_input"] {
      width: 340px;
      text-align: left;
      border: none;
      font-size: 16px;
      color: #5bc5d0;
      font-weight: 300;
      font-family: 'Metropolis';
      padding: 0;
      border-bottom: 1px solid #cfd8dc;
    }
  }
}
// #endregion ///////////////////////

////////// #region LOGIN /////////////
/////////////////////////////////////
/////// CREATE PASSWORD STYLES //////
/////////////////////////////////////
.newPasswordForm {
  display: flex;
  padding: 11px 35px 5px 20px;
  flex-direction: column;
  & [class*="divider"] {
    display: none;
  }
  & [class*="leftSideWrapper"] {
    padding: 0 !important;
  }
  & div[id*='passwordContainer']{
    & button[id='eyeIcon'], button[id='eyeIcon2']{
      margin-top: 15px;
      border-bottom: 1px solid #c8d2d7;
      border-radius: 0;
      width: 10%;
      &>img{
        filter: grayscale(100%) !important;
        padding-top: 0;
        width: 18px;
      }
    }
    &>div{
      width: 90%
    }
    & button[id='eyeIcon2']{
      margin-top: 14.7px;
    }
  }
}
// #endregion ///////////////////////
