@import '../variables';

.help {
  display: flex;
  margin: 0 0 78px;
}

.primary {
  border-right: 1px solid rgba(236, 239, 241, 0.55);
  box-sizing: border-box;
  padding: 0 16px 0 0;
  width: 60%;
}

.secondary {
  box-sizing: border-box;
  color: $gray-4;
  font-size: 13px;
  font-weight: normal;
  line-height: 20px;
  padding: 0 0 0 16px;
  width: 35%;
  word-break: break-word;
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .help {
    display: flex;
    margin: 0 0 38px;
  }
  
  .primary {
    border-right: 1px solid rgba(236, 239, 241, 0.55);
    box-sizing: border-box;
    padding: 0 7px 0 0;
    width: 100%;
  }
  
  .secondary {
    display: none;
  }
}