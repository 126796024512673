.avatar {
  width: 58px;
  height: 58px;
  border-radius: 50%;
  text-align: center;
}

.radioContainer {
  margin-left: auto;
  margin-right: 15px;
  cursor: pointer;
  & input {
    cursor: pointer;
  }
  & input[type='radio']:checked:before {
    z-index: 1;
    background-color: #ffffff;
    top: 10px;
    right: 5px;
    border: 8px solid #5bc5d0;
    box-sizing: content-box;
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
  }
  & input[type='radio']:after {
    width: 16px;
    height: 16px;
    border-radius: 15px;
    top: -2px;
    left: -2px;
    position: relative;
    background-color: #fff;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #c9d2d7;
  }
  & input[type='radio']:checked + div {
    color: #51bdca;
  }
  font-size: 15px;
  color: #597583;
  align-items: center;
  padding: 9px 9px 10px 0px;
  position: relative;
  height: 32px;
}

.wrapper {
  display: flex;
  justify-content: center;
  & img {
    margin: 0 10px;
    width: 58px;
    height: 58px;
  }
}

.providerCard {
  background-color: #fff;
  border: 1px solid #fdfdfd;
  border-radius: 10px;
  box-shadow: 0 2px 7px 0 rgba(55, 71, 79, 0.4);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 298px;
  min-height: 80px;
  text-align: left;
  margin: 7.5px 1px;
  &:hover {
    border: 1px solid #5ec4cf;
    box-shadow: 0px 0px 26px 3px rgba(0, 0, 0, 0.15);
    transition: all 0.35s;
  }
  transition: border-color 0.35s ease;
  position: relative;
}

.addAppointmentProviderCard {
  @extend .providerCard;
  width: 338px;
  & [class*='readMarker'] {
    display: none;
  }
  & [class*='shifted'] {
    display: none;
  }
  & [class*='avatar'] {
    margin: 0 10px;
  }
}
.formHistoryProvider {
  @extend .addAppointmentProviderCard;
  & div:nth-child(2) {
    width: 205px;
    & p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
.formHistoryProvider{
  width: 320px;
}
}
.addAppointmentDisabled {
  opacity: 0.2;
  transition: all 0.2s;
}
.addAppointmentSelected {
  opacity: 1;
  transition: all 0.2s;
  border: 1px solid #5ec4cf;
}

.firstLabel {
  color: #5bc5d0;
  font-size: 17px;
  margin: 0;
  height: 20px;
  line-height: 20px;
  width: 198px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.secondLabel {
  color: #78909c;
  font-size: 14px;
  font-weight: 300;
  height: 20px;
  line-height: 20px;
  margin: 0;
  width: 198px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.providerSpecialty {
  color: #78909c;
  font-size: 14px;
  height: 20px;
  line-height: 20px;
  margin: 0;
}

.providerAvatar {
  margin: 0 10px;
  font-weight: 400;
  & img {
    width: 58px;
    height: 58px;
  }
}

.providerInfo {
  display: flex;
  flex-direction: column;
}

.shifted {
  transform: translate(0px, 0px);
}

.shopProviderCard {
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 0;
  width: 338px;
  & .items {
    margin: 0 auto;
    margin-right: 12px;
    font-family: 'Metropolis', sans-serif;
    font-size: 13px;
    line-height: 16px;
    color: #90a4ae;
    font-weight: 300;
  }
  & [class*='readMarker'] {
    display: none;
  }
}

.messageProviderCard {
  margin: 7.5px 1px;
}

.showMessages {
  opacity: 1;
}

.readMarker {
  display: inline-block;
  min-height: 15px;
  min-width: 15px;
  border-radius: 50%;
  border: 2px solid white;
  background-color: #e22b41;
  transform: translate(-29px, -21px);
}
.withCount {
  position: absolute;
  left: 47px;
  bottom: 52px;
  background: red;
  color: white;
  border: 2px solid white;
  border-radius: 25px;
  height: 16px;
  width: 17px;
  text-align: center;
  font-size: 9px;
  padding-top: 1px;
}

.surveyCardContainer {
  flex-direction: column;
  @extend .addAppointmentProviderCard;
  text-align: center;
  padding: 0px 15px 15px 15px;
  & > div:nth-child(2) {
    position: relative;
    bottom: 30px;
    margin-bottom: -20px;
  }
}
.isMobileSurveyCardContainer {
  display: grid;
  width: 100%;
  text-align: inherit;
  padding: 0;
  overflow: hidden;
  & div[class*='surveyCardThankYou'] {
    display: none;
  }
  & div[class*='surveyCardTime'] {
    display: none;
  }
  & p[class*='secondLabel'] {
    // display: none;
  }
  & div[class*='wrapper'] {
    justify-content: start;
    margin-left: 10px;
  }
  & div[class*='providerInfo'] {
    justify-self: end;
    padding-left: 55px;
    margin-top: -45px;
    & p {
      font-size: 20px;
    }
  }
  & div[class*='surveyCardCustomHeadersContainer'] {
    padding: 0px 15px 15px 95px;
    & div[class*='surveyCardDate'] {
      display: block;
      & h3 {
        font-size: 22px;
      }
    }
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .providerAvatar {
    margin: 0 7px;
    font-weight: 400;
    & img {
      width: 56px;
      height: 56px;
    }
  }
  .firstLabel {
    color: #5bc5d0;
    font-size: 17px;
    margin: 0;
    height: 20px;
    font-weight: 400;
    line-height: 20px;
    width: 170px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .secondLabel {
    color: #78909c;
    font-size: 12px;
    font-weight: 300;
    height: 20px;
    line-height: 20px;
    margin: 0;
    width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .isMobileSurveyCardContainer {
    display: flex;
    width: 100%;
    text-align: inherit;
    margin-left: 1%;
    padding: 0;
    overflow: hidden;
    & div[class*='surveyCardThankYou'] {
      display: none;
    }
    & div[class*='surveyCardTime'] {
      display: none;
    }
    // & p[class*='secondLabel'] {
    //   // display: ;
    // }
    & div[class*='wrapper'] {
      justify-content: start;
      margin-left: 7px;
    }
    & div[class*='providerInfo'] {
      padding-left: 0px;
      margin-top: -5px;
      & p {
        font-size: 18px;
      }
    }
    & div[class*='surveyCardCustomHeadersContainer'] {
      padding: 0px 15px 15px 95px;
      & div[class*='surveyCardDate'] {
        display: block;
        & h3 {
          font-size: 22px;
        }
      }
    }
  }
}